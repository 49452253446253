import { React } from "react";
import "../App.css";
import profile from "../assets/profilepic.jpg";
import { Link } from "react-router-dom";
import Slider from "../Model/Slider";
import { Users } from "../tempFiles/tempFiles";

const UserCard = () => {

  return (
    <div className="w-full">
      <div className="flex justify-between px-2 lg:px-0 pb-6">
        <div className="text-xl font-medium lg:px-6">Live Streams</div>
        <Link to="/live_streams">
          <p className="text-stone-500 hover:text-stone-900 font-medium">
            See all
          </p>
        </Link>
      </div>

      <Slider classProp={["translate-x-10", "-translate-x-9"]}>
      {Users.map((user)=> (
                    <div
                    key={user.name}
                    className='touch m-1 bg-third p-4 w-[160px] flex flex-col items-center justify-center h-[170px] cursor-pointer hover:scale-200 ease-in-out duration-300'>
                      <div className='bg-fourth shadow-lg flex flex-col justify-center gap-1 text-center items-center w-full h-full'>
                        <span className='text-center items-center flex justify-center'>
                           <img src={profile} width={60} height={60} className=" float-center rounded-full"alt="" />
                        </span>
                       <span>{user.name}</span>
            
                       <span className='text-stone-600 text-sm hover:text-gray-400 duration-500 flex justify-center'>{user.action}
                       <ion-icon
                            name="ellipse"
                            class={user.action==="live"?"live_user_actions":"offline_user_actions"}>
                        </ion-icon>
                       </span>
                       </div>
                    </div>
                  ))
                }
      </Slider>
    </div>
  );
};

export default UserCard;
