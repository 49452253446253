import { useState, useCallback } from "react";
import { SliderWithText } from "./inputfield";
import Button from "../Button";
export default function Privacy() {
    const [ toggle, setToggle ] = useState([true, false])
    const toggleSlide = useCallback((index) => {
        setToggle((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    }, []);
    return(
        <>
            <div className="flex flex-col gap-6 py-6 pb-5">
                <h4 className="text-xl font-medium">privacy</h4>
                <p className="text-sm text-stone-500">Modify your privacy settings here.</p>
                <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={0}
                    label={"Show your profile on search engines"}
                    desc={""}
                />
                <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={1}
                    label={"Show courses you're taking on your profile page"}
                    desc={""}
                />
            </div>
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
                <Button>Save Changes</Button>
            </div>
        </>
    )
}