import NavSideBarComb from "./navSidebarComb";
import { Courses } from "../tempFiles/tempFiles";
import { MdOutlineDelete } from "react-icons/md";
import CoursesCard from "./CoursesCard";

export default function SavedCourses({tog}) {
    return(
        
            <NavSideBarComb>
                <div
                    className={`transition-all duration-500 transform flex flex-col flex-wrap lg:${
                      tog ? "w-11/12" : "w-11/12 ml-3"
                    } mt-12 lg:pt-16 lg:ml-12`}
                >
                    <div className="flex flex-col lg:flex-row gap-6 pb-6">
                        <div className="flex flex-col gap-3 lg:w-1/4">
                            <div className="flex justify-between text-sm">
                                <div className="font-medium">Saved Courses</div>
                                <div>Remove All</div>
                            </div>
                            <hr />
                            <div className="text-stone-500 text-sm">{Courses.length} Courses</div>
                            <button className="flex gap-1 items-center justify-center font-medium bg-redbutton2 rounded-sm py-2 hover:bg-stone-600 text-white">
                                <MdOutlineDelete />
                                <p>Remove Saved Courses</p>
                            </button>
                        </div>
                        <div className="lg:w-3/4">
                            <h4 className="font-medium">Saved Courses</h4>
                            <div className="flex flex-col gap-2">
                            {Courses.map((course) => (
                                <CoursesCard key={course.id} course={course} customClassName="md:flex lg:flex xl:flex w-full gap-2" />
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </NavSideBarComb>
    )
}