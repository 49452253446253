import React, { useState } from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { FaEdit, FaTrash } from "react-icons/fa";
import Tabel from "./Tabel";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";
const data2 = [
  ["John Doe", 30, "Engineer"],
  ["Jane Smith", 28, "Designer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  ["Bob Johnson", 35, "Developer"],
  // Add more rows here...
];
const header2 = ["Name", "Age", "Occupation"];
const actionIcons = [
  {
    icon: <FaEdit />,
    onClick: (rowIndex) => {
      // Handle edit action for the row at the given index
      console.log("Edit row:", rowIndex);
    },
  },
  {
    icon: <FaTrash />,
    onClick: (rowIndex) => {
      // Handle delete action for the row at the given index
      console.log("Delete row:", rowIndex);
    },
  },
];

const Earnings = () => {
  const [isMenuOpen, setMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className=" bg-[#F7F7F7] w-full min-h-[700px]">
       <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

<DashSidebar isMenuOpen={isMenuOpen} /> 
      <div
        className={`relative ml-5 mr-10  ${
          isMenuOpen ? "lg:ml-64" : "lg:ml-5"
        }`}
      >
        <div className=" flex flex-col gap-8">
          <div className=" p-4 flex gap-3 items-center text-[20px] font-[500] text-[#333333 pt-[80px]]">
            <AiOutlineAppstore size={29} />
            <h2> Instructor Dashbaord</h2>
          </div>
          <div className=" bg-[#F7F7F7] ml-3  grid lg:grid-cols-3 grid-cols-1 gap-7  p-4 mb-10">
            <div className=" bg-[#333333] p-8 text-center text-white text-[14px] font-[400] rounded-[5px]">
              <div>
                Sales earnings this month (April), after edututs+ fees, & before
                taxes:
              </div>
              <div className="text-center mt-5 text-[30px] font-[600]">
                $1146.78
              </div>
            </div>
            <div className=" bg-[#333333] p-8 text-center text-white text-[14px] font-[400] rounded-[5px]">
              <div>
                Sales earnings this month (April), after edututs+ fees, & before
                taxes:
              </div>
              <div className="text-center mt-5 text-[30px] font-[600]">
                $1146.78
              </div>
            </div>
            <div className=" bg-[#333333] p-8 text-center text-white text-[14px] font-[400] rounded-[5px]">
              <div>
                Sales earnings this month (April), after edututs+ fees, & before
                taxes:
              </div>
              <div className="text-center mt-5 text-[30px] font-[600]">
                $1146.78
              </div>
            </div>
          </div>
          <div className=" bg-[#F7F7F7] ml-8 grid grid-cols-1 lg:grid-cols-3 gap-7 mx-6  ">
            <div className="h-[320px] bg-white border-[1px]  border-[#efefef]  flex flex-col gap-1">
              <div className="border-b-[1px] border-[#efefef]  ">
                <p className=" my-3 text-[16px] font-[500]">
                  Top Countries
                </p>
              </div>
              <div>
                <ul className=" flex flex-col text-gray-500  text-[14px] font-[500]">
                  <li className="border-b-[1px] p-3  border-[#efefef] flex justify-between ">
                    <div>United State</div> <span>$45</span>
                  </li>
                  <li className="border-b-[1px] p-3  border-[#efefef] flex justify-between ">
                    <div>United State</div> <span>$45</span>
                  </li>
                  <li className="border-b-[1px] p-3  border-[#efefef] flex justify-between ">
                    <div>United State</div> <span>$45</span>
                  </li>
                  <li className="border-b-[1px] p-3  border-[#efefef] flex justify-between ">
                    <div>United State</div> <span>$45</span>
                  </li>
                  <li className="border-b-[1px] p-3  border-[#efefef] flex justify-between ">
                    <div>United State</div> <span>$45</span>
                  </li>
                </ul>
              </div>
            </div>
            
             
            <div className=" col-span-1 lg:col-span-2">
            <Tabel data={data2} header={header2} actionIcons={actionIcons} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Earnings;
