import React from 'react';
import '../App.css';
import Avatar from '../assets/couse.png';
import { BiCart } from 'react-icons/bi';

const CoursesCard = ({ course, customClassName }) => {
  return (
    <div className={`w-full px-3 pb-3 flex flex-col gap-2 shadow-md cursor-pointer ease-in-out duration-300 bg-white ${customClassName}`}>
      <span className='w-full'>
        <img className='w-full' src={Avatar} width={280} height={100} alt="" />
      </span>
      <div className='w-full'>
        <div className='flex justify-between'>
          <span className='text-gray-500 hover:text-gray-400 duration-500 flex text-sm'>{course.action}</span>
          <ion-icon name="ellipsis-vertical"></ion-icon>
        </div>
        <div className='flex w-full flex-col gap-1'>
        <div className='flex-wrap'> {/* Add flex-wrap property here */}
            <span className='text-sm w-full'>&nbsp;{course.name}</span>
          </div>
          <span className='text-gray-500 hover:text-gray-400 duration-500 flex text-sm'>{course.development}</span>
          <div className='flex justify-between flex-wrap'>
            <span className='hover:text-gray-400 duration-500 text-sm flex '>By&nbsp;{course.courseOwner}</span>
            <div className='flex items-center gap-1'>
              <div className=''>
                <BiCart />
              </div>
              <span className='hover:text-gray-400 duration-500 float-right text-lg'>{course.price}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursesCard;
