import React, { useState } from 'react';
import CommonFormat from '../CreateCourse/commonFormat';
import { BiMessage } from 'react-icons/bi';
import { BiSearch } from 'react-icons/bi';
import { TextInput } from '../Settings/inputfield';
import profile from '../../assets/profilepic.jpg';
import { BsThreeDots } from 'react-icons/bs';
import { MessageData } from '../../tempFiles/tempFiles';
import { BiSend } from 'react-icons/bi';

export default function Messages() {
  const [activeUserIndex, setActiveUserIndex ] = useState(null);

  const renderMessage = (message, isSent, key) => {
    return (
      <div key={key} className={`p-3 ${isSent ? "bg-red-500 self-end" : "bg-red-100 self-start"} rounded-lg mt-2`}>
        <p className={isSent ? "text-white" : "text-black"}>{message}</p>
      </div>
    );
  };
  

  const handleMessage = (index) => {
    setActiveUserIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };  

  return (
    <CommonFormat>
      <div className='pb-10 w-full'>
          <div className='flex gap-1 pl-4 items-center pb-10'>
            <BiMessage size={20} />
            <h1 className='text-xl font-medium'>Messages</h1>
          </div>
          <div className='px-3 w-full'>
            <div className='flex flex-col lg:flex-row w-full bg-white'>
              <div className='w-full md:w-2/6'>
                <div className='border py-3 w-full'>
                  <div className='flex border mx-3 items-center pl-3'>
                    <BiSearch size={20} />
                    <TextInput name={'search'} placeholder={'Search Messages...'} value={''} />
                  </div>
                </div>
                <div className='h-96 w-full border overflow-auto overflow-y-hidden hover:overflow-y-visible'>
                  {MessageData.map((user, index) => (
                    <div
                    onClick={() => handleMessage(index)}
                    key={index}
                    className={`flex w-full hover:bg-red-100 border-l-4 border-white hover:border-red-500 cursor-pointer justify-between py-6 px-2 ${
                      index === activeUserIndex ? 'bg-red-100 border-l-red-500' : 'bg-white'
                    }`}
                  >
                    <div className='flex w-full gap-4'>
                      <img src={profile} alt='profile' className='w-10 h-10 rounded-full' />
                      <div className='flex w-full gap-2 flex-col'>
                        <div className='flex justify-between'>
                          <span>{user.name}</span>
                          <span className='text-stone-500 text-sm pl-4'>7 hours ago</span>
                        </div>
                        <p className='text-sm flex flex-wrap text-gray-500'>
                          {user.messages[user.messages.length - 1][1]}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  ))}
                </div>
              </div>
              {activeUserIndex != null && 
              <div className='w-full lg:w-4/6'>
                <div className='flex pt-3 py-2 border justify-between pr-3'>
                  <div className='flex gap-3 pl-3 items-center'>
                    <img src={profile} alt='profile' className='w-10 h-10 rounded-full' />
                    <div className='flex flex-col gap-1'>
                      <p>{MessageData[activeUserIndex].name}</p>
                      <p className='bg-green-500 px-1 text-sm text-white self-center rounded-md'>online</p>
                    </div>
                  </div>
                  <BsThreeDots />
                </div>
                <div className='border h-96 flex flex-col justify-between'>
                  <div className='p-3 flex flex-col gap-3 overflow-auto overflow-y-hidden hover:overflow-y-visible'>
                  {MessageData[activeUserIndex].messages.map((msg, index) => (
                    <div key={index} className="flex flex-col gap-1">
                      {renderMessage(msg[1], msg[0] === "sent", index)}
                    </div>
                  ))}

                  </div>
                  <div className='p-2 flex items-center gap-2'>
                    <TextInput
                      name={'message'}
                      placeholder={'Type your message...'}
                      value={''}
                      // Implement onChange and value handling to capture the user's message input
                    />
                    {/* Add a send icon (you can use your preferred send icon) */}
                    <button className='bg-red-500 text-white px-4 py-2 rounded-lg'><BiSend /></button>
                  </div>
                </div>
              </div>}
            </div>
          </div>
      </div>
    </CommonFormat>
  );
}
