import React from 'react';
import CollapsiblePanel from './collapsed';

const Accordion = () => {
    return (
        <div className="float-right w-full mt-4 accordion" id="accordion0">
            <CollapsiblePanel
                title="Your own shop"
                content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS."
            />
            <CollapsiblePanel
                title="Online courses"
                content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
            />
            <CollapsiblePanel
                title="Email marketing"
                content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
            />
            <CollapsiblePanel
                title="Messaging"
                content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS."
            />
            <CollapsiblePanel
                title="Zero charges comissions 10 sales"
                content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
            />
            <CollapsiblePanel
                title="7-days-a-week support"
                content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
            />






        </div>
    );
};

export default Accordion;