import React from "react";
import {
  BiHomeAlt,
  BiAddToQueue,
  BiAlbum,
  BiBaseball,
  BiInfoCircle,
  BiVerticalCenter,
  BiWalletAlt,
} from "react-icons/bi";
import { Link } from "react-router-dom";

const DashSidebar = ({ isMenuOpen }) => {
    const SidebarButton = ({ icon, label }) => (
        <button className="flex w-full  items-center  pt-2 pb-2 mb-4 space-x-6 focus:outline-none text-black  hover:text-red-700 hover:bg-red-100">
          {icon({ size: 30, className: "pl-3" })}
          <p className="text-base leading-4 ">{label}</p>
        </button>
      );
  return (
    <div
    className={`fixed w-[240px] my-[61px]  z-[9] overflow-auto bg-white transition-all duration-500 transform  ${
      isMenuOpen ? "block" : "hidden"
    }`}
  >
    <div className="flex justify-start items-start font-medium h-screen overflow-auto overflow-y-hidden hover:overflow-y-visible      bg-white flex-col shadow-2xl ">
      <Link to={"/dashboard"} className="w-full">
        
        <SidebarButton icon={BiHomeAlt} label={"Dashboard"} />
      </Link>
      <Link to={"/courses"} className="w-full">
        <SidebarButton icon={BiAddToQueue} label={"Courses"} />
      </Link>
      <SidebarButton icon={BiVerticalCenter} label={"Analysis"} />
      <Link to="/create_new_course" className="w-full">
        <SidebarButton icon={BiAddToQueue} label={"Create Course"} />
      </Link>
      <Link to="/instructor_messages" className="w-full">
        <SidebarButton icon={BiInfoCircle} label={"Messages"} />
      </Link>
      <Link to="/instructor_notifications" className="w-full">
        <SidebarButton icon={BiBaseball} label={"Notifications"} />
      </Link>
      <Link to={"/mycereficate"} className="w-full">
        <SidebarButton icon={BiInfoCircle} label={"My Certificates"} />
      </Link>
      <Link to={"/reviews"} className="w-full">
        <SidebarButton icon={BiAlbum} label={"Reviews"} />
      </Link>
      <Link to={"/earning"} className="w-full">
        <SidebarButton icon={BiAddToQueue} label={"Earning"} />
      </Link>
      <Link to={"/payout"} className="w-full">
      <SidebarButton icon={BiBaseball} label={"Payout"} />
      </Link>
     
      
      
      <Link to={"/statments"} className="w-full">
        <SidebarButton icon={BiAlbum} label={"Statements"} />
      </Link>
      <Link to="/instructor_verification" className="w-full">
        <SidebarButton icon={BiBaseball} label={"Verification"} />
      </Link>
      <Link to="/setting" className="w-full">
        <SidebarButton icon={BiWalletAlt} label={"Setting"} />
      </Link>
      <SidebarButton icon={BiAlbum} label={"Send Feedback"} />
    </div>
  </div>
  )
}

export default DashSidebar
