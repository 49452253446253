import image from '../../assets/profilepic.jpg';
export const user = {
    'name': 'Anbes Teferi',
    'image': image,
    'profidentity': 'Maths and Physics',
    'socials':{
        'facebook': '#',
        'twitter': '#',
        'linkedin': '#',
        'youtube': '#',
    },
    'enrolled': '80k',
    'course': '24',
}

export const instructors = [
  {
    name: 'Anbes Teferi',
    image: image,
    profidentity: 'Maths and Physics',
    socials: {
      facebook: '#',
      twitter: '#',
      linkedin: '#',
      youtube: '#',
    },
    enrolled: '80k',
    course: '24',
  },
  {
      name: 'Leul',
      image: image,
      profidentity: 'Project Management',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
    {
      name: 'Misikr',
      image: image,
      profidentity: 'Web Dev',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
    {
      name: 'Melak',
      image: image,
      profidentity: 'Web Dev',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
    {
      name: 'Leulseged',
      image: image,
      profidentity: 'Web Dev',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
  ];