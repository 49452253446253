import Div from "./commonCard";
import React from "react";
import { CiStreamOn } from "react-icons/ci";

function Livestream({tog}) {
  return (
    <>
      <Div tog={tog}>
        <div className="flex w-full flex-col gap-2">
          <p className="flex self-start text-lg text-stone-700 font-medium">Live Streaming</p>
          <div className="border-t-2 border-stone-300"></div>
          <div class="flex justify-center items-center w-16 h-16 bg-red-100 rounded-full mx-auto">
            <CiStreamOn size={26}/>
          </div>
        </div>
        <p className="text-stone-700">Set up your channel and stream live to your students</p>
        <div className="text-stone-100 bg-redbutton rounded-sm w-28 flex justify-center items-center h-8 hover:cursor-pointer hover:bg-stone-800">Get Started</div>
        <p className="text-stone-700 text-sm">info: This feature is only for 'Instructors'</p>
      </Div>
    </>
  )
}

export default Livestream;
