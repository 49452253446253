import React from 'react'
import { MdOutlineShowChart } from "react-icons/md";
import Button from "../Button";
import Tabel from "./Tabel";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";
import { useState } from 'react';
const data2 = [
    ["John Doe", 30, "Engineer"],
    ["Jane Smith", 28, "Designer"],
  ];
  const header2 = ["Name", "Age", "Occupation"];

const Payouts = () => {

    const [isMenuOpen, setMenuOpen] = useState(true);

    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  return ( 
    <div className=" bg-[#F7F7F7] w-full desktop-l:h-screen ">
    <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

<DashSidebar isMenuOpen={isMenuOpen} /> 
    <div
      className={`relative ml-5 mr-10  ${
        isMenuOpen ? "lg:ml-64" : "lg:ml-5"
      }`}
    >   <div className="pl-4 pt-10  w-full bg-stone-100 relative z-0">
    <div>
      <div className="flex gap-1 w-full pt-[80px]">
        <div className="pt-1 mb-10">
          <MdOutlineShowChart size={18} />
        </div>
        <p className="font-semibold text-xl">Create New Course</p>
      </div>

      <div className="   grid  lg:grid-cols-3 grid-cols-1 lg:gap-7 mx-6 py-5  ">
        <div className="flex gap-7 flex-col w-full">
        <div className=" bg-[#F7F7F7]      flex flex-col gap-1">
          <div className=" min-w-full  min-h-full  border-[1px]  border-[#efefef] bg-white">
            <div className="border-b-[1px]  border-[#efefef]" >
              <p className="px-5 py-4 text-[16px] text-[#333333] font-[500]">Next Payout</p>
            </div>
            <div className="">
              <div className="border-b-[1px]  border-[#efefef] px-5 py-4">
              <p className=" text-[24px] text-[#b2b2b2] font-[600] ">$4568.50</p> <span className=" text-[12px] font-[700] text-[#686f7a] ">Via Payoneer</span>
              </div>
            </div>
            <div className="px-4 py-4">
              <p className=" text-[12px] font-[400] text-[#686f7a]">
                Your payout will be processed on <span className="text-[12px] font-[700] text-[#686f7a]">April 15, 2023</span>
              </p>
            </div>
          </div>

          <div></div>
        </div>

        <div className=" bg-[#F7F7F7]   border-[rgb(239,239,239)]  flex flex-col gap-1">
          <div className=" w-full min-h-full  border-[1px]  border-[#efefef] bg-white">
            <div className="border-b-[1px]  border-[#efefef]" >
              <p className="px-5 py-4 text-[16px] text-[#333333] font-[500]">Next Payout</p>
            </div>
            <div className="">
              <div className="border-b-[1px]  border-[#efefef] px-5 py-4">
              <p className=" text-[24px] text-[#b2b2b2] font-[600]  " >Pa<span className="inline-block text-red-500">y</span>oneer</p> <span className=" text-[12px] font-[700] text-[#686f7a] ">Via Payoneer</span>
              </div>
            </div>
            <div className=" py-4 px-7 ">
              
              <Button  >
                  <p className=" py-2 rounded-md  text-white text-[14px] font-[500]">Set Account</p>
              </Button>
              
            </div>
          </div>

          <div></div>
        </div>
        </div>
        <div className="     col-span-2">
          <div className=" mt-7 lg:mt-0">
          <Tabel
            data={data2}
            header={header2}
            
          />
          </div>
        </div>
      </div>
    </div>
  </div>
        </div>
        </div>
    
  )
}

export default Payouts