import React, {useContext} from "react";
import NavSideBarComb from "./navSidebarComb";
import UserCard from "./UserCard";
import { TextInput } from "./Settings/inputfield";
import { BiSearch } from "react-icons/bi";
import CoursesCard from "./CoursesCard";
import { Courses } from "../tempFiles/tempFiles";
import AppContext from "../Context/AppContext";

export default function Explore() {
  const {tog} = useContext(AppContext);

  return (
    <NavSideBarComb>
      
        <div
          className={`transition-all duration-500 transform flex flex-col flex-wrap  ${
            tog ? "w-11/12" : "w-11/12 ml-3"
          } mt-5 lg:mt-12 lg:pt-16 ml-2`}
        >
          <div className="flex bg-white w-full rounded-md items-center pl-3 border">
            <BiSearch size={20} />
            <TextInput
              name={'search'}
              placeholder={'Search for Tuts Videos, Tutors, Tests and more...'}
              value={''}
            />
          </div>
          <div className="w-full pt-10">
            <UserCard />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full">
            {/* Use the grid layout with 4 columns on larger screens */}
            {Courses.map((course) => (
              <CoursesCard key={course.id} course={course} customClassName="w-full" />
            ))}
          </div>
        </div>
    </NavSideBarComb>
  );
}
