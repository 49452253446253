export const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#fcfffe",
      ':hover': {
        backgroundColor: "#fcfffe"
      },
      scrollbarWidth: 'thin',
      scrollbarColor: "#ccc transparent",
      
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#d9dedb" : "#fcfffe", // Change background color for selected option
        color: "black",
        ":hover": {
         /*  backgroundColor: "#d9dedb", */
          backgroundColor: state.data && state.data.isDisabled ? 'white' : '#d9dedb',

        },
        cursor: "pointer",
      }),
  };
  export const specificCustomStyles = {
    ...customStyles,
    option: (provided, state) => ({
        ...customStyles.option(provided, state), // Apply the original option styles
        padding: '8px 12px',
        textAlign: state.data && state.data.isDisabled ? 'center' : 'left',
        cursor: state.data && state.data.isDisabled ? 'default' : 'pointer',
        color: state.data && state.data.isDisabled ? 'black' : state.isSelected ? 'black' : 'black',
        fontWeight: state.data && state.data.isDisabled ? 'medium' : state.isSelected ? 'bold' : 'normal',
    }),
};