import { TextInput } from "../Settings/inputfield"
import Select from 'react-select';
import { FileUpload } from "../Settings/inputfield";
import { BiSolidPlusSquare } from "react-icons/bi";
import { UploadContainer } from "./UploadContainer";
export default function Assignment({closeModal}) {
    const NumberInput = ({placeHolder, value, name}) => {
        return(
            <>
            <input type="number" placeholder={placeHolder} value={value} name={name} className="py-1 w-full border" />
            </>
        )
    }
    return(
        <div className="flex flex-col gap-3 h-full w-full">
            <div className="flex flex-col overflow-y-hidden hover:overflow-y-scroll     gap-3 w-full h-full">
                <div className="flex justify-between px-3">
                    <h4 className="font-medium">Add Assingment</h4>
                    <span className="font-bold text-stone-400 hover:text-stone-500 cursor-pointer" onClick={closeModal}>X</span>
            </div> 
            <hr/>
            <label className="font-medium">Assingment Title*</label>
            <TextInput name="assTitle" notwhite={true} placeholder="Assingment title here" />
            <h4 className="font-medium">Description*</h4>
            <div className="flex flex-col md:flex-row gap-10 md:gap-3 w-full">
                <div className="w-full">
                    <span className="font-medium">Time Duration*</span>
                    <div className="flex gap-2">
                        <div className="w-1/2 md:w-full">
                            <NumberInput name="timeDuration" value={0} />
                        </div>
                        <div className="w-1/2 md:w-full"><Select /></div>
                    </div>
                    <span>Assignment time duration, set 0 for no limit.</span>
                </div>
                <div className="">
                    <span className="font-medium">Total Number*</span>
                    <NumberInput name="totNum" value={15} />
                    <span>Maximum points a student can score</span>
                </div>
                <div className="">
                    <span className="font-medium">Minimum Pass Number*</span>
                    <NumberInput name="minPass" value={5} />
                    <span>Minimum points required for the student to pass this assignment.</span>
                </div>
                
            </div>
            <div className="flex flex-col gap-6">
                <div>
                    <span className="font-medium">Upload attachment limit*</span>
                    <TextInput notwhite={true} name="attachLimit" placeholder="1" />
                    <span>Maximum attachment size limit</span>
                </div>
                <div>
                    <span className="font-medium">Maximum attachment size limit*</span>
                    <TextInput notwhite={true} name="maxAttaSize" placeholder="10" />
                    <span>Define maximum attachment size in MB</span>
                </div>
                
            </div>
            <div className="w-full p-4 border border-dashed">
                <FileUpload name="attachemt" icon={<BiSolidPlusSquare/>} fileFormat="jpg, jpeg, png, pdf or .zip"/>
                <UploadContainer id={5} />
                <UploadContainer id={6} />
            </div>
        </div>
        <div className="flex self-end gap-2">
        <button
                                  onClick={closeModal}
                                  className={`transition-all
                                              duration-400 font-semibold
                                              text-stone-600 text-center transition-500 bg-slate-200
                                              rounded-sm w-36 h-9 hover:cursor-pointe hover:text-stone-800`}>
                                  Close</button>
                              <button
                                  className={`transition-all
                                              duration-400 font-semibold
                                              text-stone-100 text-center transition-500 bg-redbutton
                                              rounded-sm w-36 h-9 hover:cursor-pointer hover:bg-slate-700`}>
                                  Add Assignment</button>
        </div>
      </div>
    )
}