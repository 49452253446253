const thumbnail = require('../../assets/thumbnail.png');
export const Thumbnail = () => {
    return(
        <>
        <div>
            <img
            className="w-full h-36"
            src={thumbnail} alt="thumbnail for video" />
            <div className="flex flex-col relative justify-center items-center text-center h-36 bg-white">
                <label
                  htmlFor="uploadthumbnail"
                  className="border border-red-500 text-red-600 py-2 px-3 rounded-md bg-white cursor-pointer"
                >
                  CHOOSE THUMBNAIL
                </label>
                <input
                  type="file"
                  id="uploadthumbnail"
                  accept="image/jpg, image/jpeg, image/png"
                  className="opacity-0 absolute inset-0 z-[-1]"
                />
                <span className="pl-6 pr-6">Size: 590x300 pixels. Supports: jpg,jpeg, or png</span>
            </div>
        </div>
        </>
    )
}