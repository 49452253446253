import React from "react";
import RepeatedComponent from "./RepeatedComponent";
import RepeatedHeader from "./RepeatedHeader";
import Footer from "../../components/Footer";
const Company = () => {
  return (
    <>
      <div>
        <RepeatedHeader />
        <RepeatedComponent />

        <div className="py-10">
          <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 ">
            <h2 className="text-xl font-semibold font-roboto mb-1 text-left text-gray-700 leading-26">
              Our Story
            </h2>
            <div>
              <img
                className="  pt-5 mb-6"
                src="https://lms.dalloltech.com/images/line.svg"
                alt="line-svg"
              />
            </div>
            <div className="container   sm:grid-cols-1 lg:flex lg:justify-between ">
              <div className="sm:w-full lg:w-1/2 mb-44">
                <p className="text-base font-normal font-roboto text-left text-gray-600 leading-6 pt-27">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  consectetur vel dolor id ultrices. Proin a magna at mi pretium
                  pulvinar in eu enim. Nulla vel lacus lectus. Donec at
                  venenatis augue. Nam vitae purus placerat, hendrerit nisl id,
                  finibus magna. Etiam pharetra gravida ornare. Donec sagittis,
                  ipsum in egestas egestas, dui lorem sollicitudin justo, ut
                  ullamcorper velit neque eu velit. Ut et fringilla elit. Mauris
                  augue augue, auctor a blandit ac, convallis eget neque. bitur
                  in ante ante. Nullam laoreet tempus erat at ornare. In nisl
                  nisi, dapibus eget facilisis sit amet, commodo quis nibh.
                </p>
              </div>
              <div style={{ width: "470px", height: "300px" }}>
                <img
                  className="w-full h-ma "
                  src="https://lms.dalloltech.com/images/about/company.jpg"
                  alt="pic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-10 bg-gray-100 ">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 ">
          <div className=" w-full flex justify-center">
            <div>
              <h2 className="text-2xl font-semibold font-roboto mb-5 text-center text-gray-800 leading-6">
                Our Offices
              </h2>
              <div className=" max-w-xl">
                <p className=" text-ellipsis font-normal font-roboto mb-0 block   text-center text-gray-600 leading-6 px-300">
                  Cursus branches around the world
                </p>
                <div className="w-full flex justify-center">
                  <img
                    className="  pt-5 mb-6"
                    src="https://lms.dalloltech.com/images/line.svg"
                    alt="line-svg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mt-5 mx-auto grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-row">
            <div
              className="  @apply bg-white  p-3 m-5  rounded-md border border-gray-300 
  "
            >
              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto">
                <img
                  src="https://lms.dalloltech.com/images/about/company-1.jpg"
                  alt=""
                />
              </div>

              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mt-5 mx-auto ">
                <a className="@apply w-full block text-base font-medium font-roboto text-gray-700 text-left mb-2 leading-6">
                  Punjab India
                </a>
                <p className="  @apply w-full text-base font-normal font-roboto leading-6 text-gray-600 text-left mt-4">
                  #1235 Sks Nagar St No. 8 Phase 3, Pakhowal Road, 141001, LDH,
                  Punjab, India
                  <br />
                  <a className="mt-5">
                    <i class="uil uil-phone"></i> 0161-1234567
                  </a>
                </p>
              </div>
            </div>

            <div
              className="  @apply bg-white p-3 m-5 rounded-md border border-gray-300 
  "
            >
              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto">
                <img
                  src="https://lms.dalloltech.com/images/about/company-2.jpg"
                  alt=""
                />
              </div>
              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto">
                <a>
                  <p className="  @apply w-full text-base font-normal font-roboto leading-6 text-gray-600 text-left mt-4">
                    #1235 Sks Nagar St No. 8 Phase 3, Pakhowal Road, 141001,
                    LDH, Punjab, India
                    <br />
                    <a className="mt-5">
                      <i class="uil uil-phone"></i> 0161-1234567
                    </a>
                  </p>
                </a>
              </div>
            </div>

            <div className="  @apply bg-white  p-3 m-5 rounded-md border border-gray-300 ">
              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto  ">
                <img
                  className="w-full"
                  src="https://lms.dalloltech.com/images/about/company-1.jpg"
                  alt=""
                />
              </div>
              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto ">
                <a></a>
                <p className="  @apply w-full text-base font-normal font-roboto leading-6 text-gray-600 text-left mt-4">
                  #1235 Sks Nagar St No. 8 Phase 3, Pakhowal Road, 141001, LDH,
                  Punjab, India
                  <br />
                  <a className="mt-5">
                    <i class="uil uil-phone"></i> 0161-1234567
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Company;
