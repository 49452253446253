import React, {useState, useCallback} from "react";
import CommonClickables from "./commonClickables";
import { FiSettings, FiPenTool } from "react-icons/fi";
import { BiFile, BiQuestionMark, BiCheckCircle } from "react-icons/bi";
import { TextInput } from "../Settings/inputfield";
import { BiCircle } from "react-icons/bi";
import { BiSolidPencil, BiPencil, BiBasket, BiMenu } from "react-icons/bi";
import { FileUpload } from "../Settings/inputfield";
import { ContainerShowHover } from "./ContainerHoverShow";
import { SliderWithText } from "../Settings/inputfield";
import { TextArea } from "./TextArea";

export default function Quiz({closeModal}) {
    const icons = [<BiFile />, <BiQuestionMark />, <FiSettings />]
    const items = ["Basic", "Questions", "Setting"]
    const iconsQuestion = [<BiCircle />,<BiCheckCircle/>,<BiSolidPencil/>,<BiFile />]
    const itemsQuestion = ["Single Choice", 'Multiple Choice', 'Single Line Text', 'Multi Line Text']
    const [menu, setMenu] = useState(0);
    const [showHide, setShowHide] = useState(false);
    const [QuizType, setQuizType] = useState(0);
    const [showIcons, setShowIcons] = useState([false, false,false,false]);
    const [toggle, setToggle] = useState([false, false])
    const toggleSlide = useCallback((index) => {
        setToggle((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    }, []);

    const handleIconToFalse = (index) => {
        setShowIcons(prevState => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
        })
    }
    const handleIconToTrue = (index) => {
        setShowIcons(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        })
    }
    const changeShowHide = () =>{
        setShowHide(!showHide);
    }
    const changeQuizType = (index) => {
        setQuizType(index)
    }
    const changeMenu = (index) => {
        setMenu(index)
    }
    return(
        <div className="flex flex-col gap-3 h-full w-full">
            <div className="flex flex-col overflow-y-hidden hover:overflow-y-scroll   h-full   gap-3 w-full">
                <div className="flex justify-between px-3">
                    <h4 className="font-medium">Add Quiz</h4>
                    <span className="font-bold text-stone-400 hover:text-stone-500 cursor-pointer" onClick={closeModal}>X</span>
            </div> 
            <hr/>
            <div className="grid grid-cols-1 md:grid-cols-3 w-full">
                <CommonClickables
                lists={items}
                state={menu}
                stateChange={changeMenu}
                dynamicClass={"w-full h-10 self-center justify-center"}
                white={false}
                icon={icons} />
            </div>
            {menu === 0 &&
            <div className="flex flex-col gap-6">
                <label className="font-medium">Quiz Title*</label>
                <TextInput name="quizTitle" placeholder="Title here" />
                <TextArea
                label={"Description*"}
                name={"coursedesc"}
                placeholder={"description here..."} />
            </div>}
            {menu === 1 &&
            <>
            <button className="w-full text-white py-2 bg-redbutton2 hover:bg-stone-700"
            onClick={changeShowHide}>Add Question</button>
            {showHide &&
            <div className="flex flex-col gap-3 transition-all ease-in-out duration-1000">
                <span className="text-stone-500">Question Type</span>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 md:gap-0 w-full">
                    <CommonClickables
                    lists={itemsQuestion}
                    state={QuizType}
                    stateChange={changeQuizType}
                    dynamicClass={"w-full h-10 self-center justify-center"}
                    white={false}
                    icon={iconsQuestion} />
                </div>
                <div className="flex flex-col md:flex-row md:items-start justify-center md:justify-around">
                    <FileUpload name={<span className="text-stone-600">image*</span>} /> 
                    <div className="flex flex-col md:w-1/2">
                        <label className="">Question Title*</label>
                        <div className="w-full border">
                            <TextInput name='questionTitle' placeholder="Write question title" notwhite={false}/>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label>Score*</label>
                        <input type='number' name='score' placeholder="Score" className="pl-4 h-11 border" />
                    </div>

                </div>
                {(QuizType=== 0 || QuizType=== 1) &&
                <><button className="bg-redbutton hover:bg-stone-700 text-white py-1 w-36">Add Option</button>
                <div className="flex flex-col bg-stone-100 p-2 gap-4">
                    <span>1. Option</span>
                    <hr />
                    <label>Option Title*</label>
                    <TextInput name="option1" placeholder="Option title" />
                    <div className="flex gap-2">
                        <input type="checkbox" className="cursor-pointer" name="correct"/>
                        <label>Correct Answer*</label>
                    </div>
                </div>
                </>}
                <button className="bg-redbutton2 w-36 py-2 text-white hover:bg-stone-700 ">Save Option</button>
                <ContainerShowHover
                icons={[<BiCircle />,<BiPencil/>, <BiBasket/>,<BiMenu/>]}
                text="Question Title"
                show={showIcons[0]}
                showHandle={()=>handleIconToTrue(0)}
                hideHandle={()=>handleIconToFalse(0)}/>
                <ContainerShowHover
                icons={[<BiCheckCircle />,<BiPencil/>, <BiBasket/>,<BiMenu/>]}
                text="Question Title"
                show={showIcons[1]}
                showHandle={()=>handleIconToTrue(1)}
                hideHandle={()=>handleIconToFalse(1)}/>
                <ContainerShowHover
                icons={[<BiPencil />,<BiPencil/>, <BiBasket/>,<BiMenu/>]}
                text="Question Title"
                show={showIcons[2]}
                showHandle={()=>handleIconToTrue(2)}
                hideHandle={()=>handleIconToFalse(2)}/>
                <ContainerShowHover
                icons={[<BiFile />,<BiPencil/>, <BiBasket/>,<BiMenu/>]}
                text="Question Title"
                show={showIcons[3]}
                showHandle={()=>handleIconToTrue(3)}
                hideHandle={()=>handleIconToFalse(3)}/>
            </div>}
            </>}
            {menu === 2 && 
            <>
            <h4>Gradable</h4>
            <SliderWithText
            label="Quiz Gradable"
            desc="If this quiz test affect on the students grading system for this course."
            tog={[toggle, toggleSlide]}
            index={0}/>
            <h4>Remaining time display</h4>
            <SliderWithText
            label="Show Time"
            desc="By enabling this option, quiz taker will show remaining time during attempt."
            tog={[toggle, toggleSlide]}
            index={1} />
            <div className="flex flex-col md:flex-row gap-2">
                <div className="flex flex-col">
                    <label className="font-medium">Time Limit*</label>
                    <div className="flex w-full border bg-stone-100">
                        <input type="number" className="border py-1"/>
                        <span className="px-1">Minutes</span>
                    </div>
                    <span>Set zero to disable time limit</span>
                </div>
                <div className="flex flex-col">
                    <label className="font-medium">Passing Score(%)*</label>
                    <input type="number" className="border py-1"/>
                    <span>Student have to collect this score in percent for the pass this quiz.</span>
                </div>
                <div className="flex flex-col">
                    <label className="font-medium">Question Limit*</label>
                    <input type="number" className="border py-1"/>
                    <span>The number of questions student have to answer in this quiz.</span>
                </div>
            </div>
            </>}
        </div>
        <div className="flex self-end gap-2">
      <button
                                onClick={closeModal}
                                className={`transition-all
                                            duration-400 font-semibold
                                            text-stone-600 text-center transition-500 bg-slate-200
                                            rounded-sm w-36 h-9 hover:cursor-pointe hover:text-stone-800`}>
                                Close</button>
                            <button
                                className={`transition-all
                                            duration-400 font-semibold
                                            text-stone-100 text-center transition-500 bg-redbutton
                                            rounded-sm w-36 h-9 hover:cursor-pointer hover:bg-slate-700`}>
                                Add Quiz</button>
      </div>
    </div>
    )
}