import React, { useState } from "react";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";
import { FaTrash } from "react-icons/fa";
import Tabel from "./Tabel";
import { RiSearchLine, RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineDownload } from "react-icons/ai";
import { AiOutlineAppstore } from "react-icons/ai";
const data2 = [
  ["John Doe", 30, "Engineer", "Male", "USA", "Single"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
];

const header2 = [
  "Name",
  "Age",
  "Occupation",
  "Gender",
  "Country",
  "Marital Status",
];
const actionIcons = [
  {
    icon: <FaTrash />,
    onClick: (rowIndex) => {
      // Handle delete action for the row at the given index
      console.log("Delete row:", rowIndex);
    },
  },
];
const Statments = () => {
  const [isMenuOpen, setMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className=" bg-[#F7F7F7] w-full min-h-[700px]">
      <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

      <DashSidebar isMenuOpen={isMenuOpen} />
      <div
        className={`relative ml-5 mr-10  ${
          isMenuOpen ? "lg:ml-64" : "lg:ml-5"
        }`}
      >
        <div className=" flex flex-col gap-8">
          <div className=" p-4 flex gap-3 items-center text-[20px] font-[500] text-[#333333 pt-[80px]">
            <AiOutlineAppstore size={29} />
            <h2> Instructor Dashbaord</h2>
          </div>
          <div className=" w-full  ml-3  grid grid-cols-1 lg:grid-cols-6 gap-4">
            <div className=" lg:col-span-4  w-full flex flex-col gap-2 bg-white">
              <div className=" p-4  border-b-[1px] border-solid border-[#efefef] text-[16px] font-[500]">
                Earnings
              </div>
              <div className=" p-4  text-[14px] font-[400] text-[#686f7a]">
                Your Sales Earnings Over The last month
              </div>
              <div className=" flex justify-between items-center p-4 max-w-full">
                <div className=" flex flex-col gap-2 text-[14px] font-[400] text-[#686f7a]">
                  My Funds{" "}
                  <span className="text-[20px] font-[600]">$289.64</span>
                </div>
                <div className=" flex flex-col gap-2 text-[14px] font-[400] text-[#686f7a]">
                  My Funds{" "}
                  <span className="text-[20px] font-[600] text-[#82b541]">
                    $289.64
                  </span>
                </div>
                <div className=" flex flex-col gap-2 text-[14px] font-[400] text-[#686f7a]">
                  My Funds{" "}
                  <span className="text-[20px] font-[600] text-[#ed2a26]">
                    -$289.64
                  </span>
                </div>
              </div>
            </div>
            <div className=" lg:col-span-2  w-full bg-white">
            <div className=" flex gap-1 ml-7 justify-center items-center h-24 ">
              <div className=" ">
                <select
                  name=""
                  id=""
                  className=" block w-44 items-center px-4 p-1 pr-8 border text-xs font-[400]  bg-white focus:outline-none focus:border-danger"
                >
                  <option value="Option 1" className=" hover:bg-inherit">
                    Monthly Invoice
                  </option>
                  <option value="option 2" className=" hover:bg-inherit">
                    Option
                  </option>
                </select>
                <div className=" absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <RiArrowDropDownLine className="w-5 h-5 text-gray-400" />
                </div>
              </div>
              <div className=" bg-danger rounded-md flex items-center justify-center px-1">
                <AiOutlineDownload size={14} className=" text-white " />
              </div>
            </div>
            </div>
           
          </div>
          <div className="w-full">
          <div className=" flex flex-col lg:flex-row gap-2 items-center  ml-3">
          <div className=" bg-[#333333] py-2 px-3 text-[14px] font-medium rounded-sm text-white">
             This Month
          </div>
          <div className="bg-[#E9E9E9] hover:bg-white py-2 px-3 text-[14px] font-medium rounded-sm text-[#686f7a]">
             This Month
          </div>
          <div className="relative">
            <input type="text" placeholder="Document Number"  className="px-8 py-2 text-[12px] font-[400] border w-52 focus:outline-none focus:text-[#333333]" />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 py-2">
            <RiSearchLine size={13} className=" text-gray-400" />
              </div>
          </div>
            </div>
          </div>
          <div className=" w-full ml-3">
            <Tabel data={data2} header={header2} actionIcons={actionIcons}  />
          </div>
        </div>
      </div>
    </div>
  );
};



export default Statments;
