import { BiSearch } from "react-icons/bi";
import NavSideBarComb from "./navSidebarComb";
import { TextInput } from "./Settings/inputfield";
import { user } from "../tempFiles/tempFiles";
import ProfileComponent from "./rightComp/profileCommon"
export default function AllInstructors({tog}) {
  const allusers = [user, user, user, user, user, user, user, user, user, user]
    return(
        
        <NavSideBarComb>
          <div
            className={`transition-all duration-500 transform flex flex-col flex-wrap ${
              tog ? "w-11/12" : "w-11/12"
            } mt-12 pt-16 lg:ml-16 ml-3 gap-6`}
          >
            <div className="flex bg-white w-full rounded-md items-center pl-3 border">
              <BiSearch size={20} />
              <TextInput
                name={'search'}
                placeholder={'Search for Tuts Videos, Tutors, Tests and more...'}
                value={''}
              />
            </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {allusers.map((user, index) => (
                  <div className="bg-white py-6 gap-3">
                    <ProfileComponent key={index} user={user} tog={tog} />
                  </div>
                ))}
              </div>
            </div>
        </NavSideBarComb>
         
    )
}