import React, { useState } from "react";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaInstagram,
  FaFacebookF,
  FaPinterestP,
  FaMailBulk,
} from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { AiOutlineDown } from "react-icons/ai";

const SITEMAP = [
  {
    title: "Company",
    links: ["About", "Careers", "Blog", "Press"],
  },
  {
    title: "Help Center",
    links: ["Help", "Advertise", "Developers", "Contact Us"],
  },
  {
    title: "Resources",
    links: ["Copyright Policy", "Terms", "Privacy Policy", "Sitemap"],
  },
];

const currentYear = new Date().getFullYear();

export default function Footer() {
  const [lang, setLang] = useState(false);

  const Iconmap = ({ items }) =>
    items.map((item, index) => (
      <div className="opacity-80 transition-opacity hover:opacity-100" key={index}>
        {item({ size: 20, color: "white" })}
      </div>
    ));

  const SidebarButton = ({ items }) => (
    <button
      onClick={() => {
        setLang(!lang);
      }}
      className="h-10 w-36 flex flex-row bg-transparent justify-between items-center border border-white text-white"
    >
      <TbWorld size={28} className="ps-3" />
      <p>Languages</p>
      <AiOutlineDown size={20} className="pe-2" />
      {lang && (
        <ul className="flex-col space-y-1 justify-between     items-center">
          {items.map((item, index) => (
            <li
              key={index}
              className="bg-white justify-center hover:text-black text-black cursor-pointer"
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </button>
  );

  return (
    <footer className="w-full bg-zinc-800 mt-auto">
      <div className="mx-auto w-full max-w-7xl px-4 md:px-8">
        <div className="flex flex-col lg:flex-row">
          <div className="pl-8 mx-auto grid w-full grid-cols-1 gap-8 py-8 md:grid-cols-2 lg:grid-cols-4">
            {SITEMAP.map(({ title, links }, key) => (
              <div key={key} className="w-full">
                <div className="mb-4 font-bold uppercase text-white opacity-50">
                  {title}
                </div>
                <ul className="space-y-1">
                  {links.map((link, index) => (
                    <div key={index} className="font-bold">
                      <a
                        href="#"
                        className="inline-block py-1 pr-2 text-white text-sm transition-transform hover:scale-105"
                      >
                        {link}
                      </a>
                    </div>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="flex flex-col mt-8 space-y-4 mx-auto lg:mt-14 lg:space-y-0 lg:space-x-4 lg:flex-row lg:items-center">
            <button className="h-10 w-full lg:w-40 bg-red-500 text-white rounded">
              Teach on Course
            </button>
            <SidebarButton
              items={[
                "About",
                "About",
                "About",
                "About",
                "About",
                "About",
                "About",
                "About",
              ]}
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <div className="mb-4 text-center font-normal text-white md:mb-0">
            &copy; {currentYear}{" "}
            <a href="https://dalloltech.com/" className="text-blue-500 font-bold">
              Dallol Tech
            </a>
            . All Rights Reserved.
          </div>
          <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
            <Iconmap
              items={[
                BsTwitter,
                FaFacebookF,
                FaInstagram,
                FaLinkedinIn,
                FaPinterestP,
                BsYoutube,
                FaMailBulk,
              ]}
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
