import React from "react";
const Tabel = ({ data, header, actionIcons }) => {
  return (
    <div className="w-full  overflow-x-auto">
      <div className="min-w-full">
          <table className="w-full border-collapse">
          <thead className=" bg-gray-50 border-b-2 border-gray-200">
            <tr>
              {header.map((heading, index) => (
                <th key={index} className="px-2 py-1 text-[14px] font-[500] tracking-wide text-center border-none bg-[#FFECEC]">
                  {heading}
                </th>
              ))}
              {actionIcons && actionIcons.length > 0 && (
                <th className="px-2 py-3 text-sm font-semibold tracking-wide text-center bg-[#FFECEC]">Actions</th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} className=" bg-gray-50">
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="py-4 px-2 text-center text-[14px] font-[400] text-gray-700 border-t-[1px] border-solid border-[#efefef] ">
                    {cell}
                  </td>
                ))}
                {actionIcons && actionIcons.length > 0 && (
                  <td className=" border-t-[1px] border-solid border-[#efefef] p-2 text-center">
                    {actionIcons.map((icon, iconIndex) => (
                      <button
                        key={iconIndex}
                        onClick={() => icon.onClick(rowIndex)}
                        className="mr-1 px-1 py-1 rounded text-gray-300"
                      >
                        {icon.icon}
                      </button>
                    ))}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    </div>
  );
};

export default Tabel;
