import React from "react";
import HistoryIcon from '@mui/icons-material/History';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
const StyleIcon = ({children}) => {
    return(
    <div class="flex justify-center items-center w-16 h-16 bg-red-100 mx-auto">
        {children}
    </div>
    );
};
const BoldPara = ({children}) => {
    return(
        <p className="font-semibold text-lg">{children}</p>
    );
};


export default function GuideInfo({tog}) {
    return(
        <>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full px-2 lg:px-0 lg:pl-8 flex-wrap gap-4">
            <div
            className={`bg-white shadow p-4 transition-all duration-500 transform
        text-center flex flex-col justify-center
        items-center my-8 gap-2 w-full`}>
            <StyleIcon>
            <HistoryIcon />
          </StyleIcon>
                <BoldPara>Go at your own pace</BoldPara>
                <p>Enjoy lifetime access to courses on Edututs+'s website</p>
            </div>
            <div
            className={`bg-white shadow p-4
    text-center flex flex-col justify-center
    items-center my-8 gap-2 w-full transition-all duration-500 transform`}>
            <StyleIcon>
            <PersonAddAltIcon />
          </StyleIcon>
                <BoldPara>Learn from industry experts</BoldPara>
                <p>Select from top instructors around the world</p>
            </div>
            <div
            className={`bg-white shadow p-4
    text-center flex flex-col justify-center
    items-center my-8 gap-2 w-full transition-all duration-500 transform`}>
            <StyleIcon>
                <PlayCircleOutlineIcon />
            </StyleIcon>
                <BoldPara>Find video courses on almost any topic</BoldPara>
                <p>Build your library for your career and personal growth</p>
            </div>
            <div
            className={`bg-white shadow p-4
    text-center flex flex-col justify-center
    items-center my-8 gap-2 w-full transition-all duration-500 transform`}>
            <StyleIcon>
            <OndemandVideoIcon />
          </StyleIcon>
                <BoldPara>100,000 online courses</BoldPara>
                <p>Explore a variety of fresh topics</p>
            </div>
        </div>
        </>
    )
}