import React, { useState } from 'react';
import '../../App.css'
const CollapsiblePanel = ({ title, content }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };


    return (
        <div className=" mb-2 rounded-sm float-left w-full border-b border-gray-300 ">
            <div className=" cursor-pointer" id={`heading${title}`}>
                <div className="text-xl justify-between font-medium text-gray-700 font-roboto leading-6 flex">
                    <button
                        className={`text-base font-semibold flex justify-between w-full text-gray-800 p-4 font-roboto leading-6 btn btn-link  transition-transform  ${isCollapsed ? 'collapsed,' : ''}`}
                        onClick={toggleCollapse}
                        type="button"
                        data-toggle="collapse"
                        data-target={`#collapse${title}`}
                        aria-expanded={!isCollapsed}
                        aria-controls={`collapse${title}`}
                    >
                        <div>
                            {title}
                        </div>
                        <div>
                            <p className=' items-center flex justify-between'>
                                {isCollapsed ? '+' : '-'}
                            </p>
                        </div>

                    </button>


                </div>

            </div>
            <div
                id={`collapse${title}`}
                className={` panel-collapse collapse transition-transform ${isCollapsed ? '' : 'show'} visible`}
                role="tabpanel"
                onClick={isCollapsed ? 'rotate-45' : ''
                }
                aria-labelledby={`heading${title}`}
            >
                <div className="panel-body visible">{content}</div>
            </div>
        </div>
    );
};

export default CollapsiblePanel;