import React from "react";

import RepeatedHeader from "./RepeatedHeader";
import { useState } from "react";
import Footer from "../../components/Footer";
import RepeatedComponent from "./RepeatedComponent";
const About = () => {
  return (
    <>
      <RepeatedHeader />
      <RepeatedComponent />
      <div className=" py-10 bg-gray-100">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10">
          <div className="w-full">
            <h2 className="text-2xl font-semibold font-roboto mb-17 text-gray-700 text-center leading-26">
              Our Features
            </h2>
            <p className="text-base font-normal font-roboto mb-0 text-center text-gray-500 leading-26">
              On cursus you have access to:
            </p>
          </div>

          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-row">
            <div className=" max-w-sm text-center mt-10 px-5">
              <i class="uil uil-mobile-android-alt"></i>
              <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                Mobile learning
              </h4>
              <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-26">
                Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                ipsum primis in faucibus.
              </p>
            </div>
            <div className=" max-w-md text-center mt-10 px-5">
              <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                Academic &amp; Technical Support
              </h4>
              <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-ellipsis leading-26">
                Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                ipsum primis in faucibus.
              </p>
            </div>
            <div className="max-w-md text-center mt-10 px-5">
              <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                Sharable Certificates
              </h4>
              <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-26">
                Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                ipsum primis in faucibus.
              </p>
            </div>
            <div className="max-w-md text-center mt-10 px-5">
              <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                An Inclusive Experience
              </h4>
              <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-26">
                Quisque nec volutpat sem. Interdum et malesuada fames ac ante
                ipsum primis in faucibus.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" py-10  ">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 ">
          <h2 className="text-xl font-semibold font-roboto mb-1 text-left text-gray-700 leading-26">
            Our Story
          </h2>
          <div>
            <img
              className="  pt-5 mb-6"
              src="https://lms.dalloltech.com/images/line.svg"
              alt="line-svg"
            />
          </div>
          <div className="container   sm:grid-cols-1 lg:flex lg:justify-between relative">
            <div className="sm:w-full lg:w-1/2 mb-44">
              <p className="text-base font-normal font-roboto text-left text-gray-600 leading-6 pt-27">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                consectetur vel dolor id ultrices. Proin a magna at mi pretium
                pulvinar in eu enim. Nulla vel lacus lectus. Donec at venenatis
                augue. Nam vitae purus placerat, hendrerit nisl id, finibus
                magna. Etiam pharetra gravida ornare. Donec sagittis, ipsum in
                egestas egestas, dui lorem sollicitudin justo, ut ullamcorper
                velit neque eu velit. Ut et fringilla elit. Mauris augue augue,
                auctor a blandit ac, convallis eget neque. bitur in ante ante.
                Nullam laoreet tempus erat at ornare. In nisl nisi, dapibus eget
                facilisis sit amet, commodo quis nibh.
              </p>
            </div>
            <div className=" w-max my-div transform -translate-y-1/4  ">
              <img
                className="w-full  "
                src="https://lms.dalloltech.com/images/about/stroy_img.png"
                alt="pic"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="   py-10 bg-gray-100   ">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 ">
          <div className=" w-full flex justify-center">
            <div>
              <h2 className="text-2xl font-semibold font-roboto mb-5 text-center text-gray-800 leading-6">
                Our globale reach
              </h2>
              <div className=" max-w-xl">
                <p className=" text-ellipsis font-normal font-roboto mb-0 block   text-center text-gray-600 leading-6 px-300">
                  Cursus is the leading global marketplace for teaching and
                  learning, connecting millions of students to the skills they
                  need to succeed.
                </p>
                <div className="w-full flex justify-center">
                  <img
                    className="  pt-5 mb-6"
                    src="https://lms.dalloltech.com/images/line.svg"
                    alt="line-svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-row  justify-evenly">
              <div className="p__metric text-4xl font-bold font-roboto text-center text-gray-800 mt-5 ">
                25k
                <p className="text-sm font-normal font-roboto leading-6 text-center text-gray-600 mb-0 mt-5">
                  Instructors
                </p>
              </div>
              <div className="p__metric text-4xl font-bold font-roboto text-center text-gray-800 mt-5">
                95k
                <p className="text-sm font-normal font-roboto leading-6 text-center text-gray-600 mb-0 mt-5">
                  Courses
                </p>
              </div>
              <div className="p__metric text-4xl font-bold font-roboto text-center text-gray-800 mt-5">
                40M
                <p className="text-sm font-normal font-roboto leading-6 text-center text-gray-600 mb-0 mt-5">
                  Courses erollement
                </p>
              </div>
              <div className="p__metric text-4xl font-bold font-roboto text-center text-gray-800 mt-5">
                50+
                <p className="text-sm font-normal font-roboto leading-6 text-center text-gray-600 mb-0 mt-5">
                  Language
                </p>
              </div>
              <div className="p__metric text-4xl font-bold font-roboto text-center text-gray-800 mt-5">
                595+
                <p className="text-sm font-normal font-roboto leading-6 text-center text-gray-600 mb-0 mt-5">
                  Membership partners
                </p>
              </div>
              <div className="p__metric text-4xl font-bold font-roboto text-center text-gray-800 mt-5">
                295+
                <p className="text-sm font-normal font-roboto leading-6 text-center text-gray-600 mb-0 mt-5">
                  Countries
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 ">
          <div className=" w-full flex justify-center">
            <div>
              <h2 className="text-2xl font-semibold font-roboto mb-5 text-center text-gray-800 leading-6">
                Meet Our Team
              </h2>
              <div className=" max-w-xl">
                <p className=" text-ellipsis font-normal font-roboto mb-0 block   text-center text-gray-600 leading-6 px-300">
                  Cursus is the leading global marketplace for teaching and
                  learning, connecting millions of students to the skills they
                  need to succeed.
                </p>
                <div className="w-full flex justify-center">
                  <img
                    className="  pt-5 mb-6"
                    src="https://lms.dalloltech.com/images/line.svg"
                    alt="line-svg"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="sm:grid-cols-1 lg:flex lg:justify-between mt-20">
              <div className="sm:w-full lg:w-1/2 mb-44">
                <p className="text-base font-normal font-roboto text-left text-gray-600 leading-6 pt-27">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  consectetur vel dolor id ultrices. Proin a magna at mi pretium
                  pulvinar in eu enim. Nulla vel lacus lectus. Donec at
                  venenatis augue. Nam vitae purus placerat, hendrerit nisl id,
                  finibus magna. Etiam pharetra gravida ornare. Donec sagittis,
                  ipsum in egestas egestas, dui lorem sollicitudin justo, ut
                  ullamcorper velit neque eu velit. Ut et fringilla elit. Mauris
                  augue augue, auctor a blandit ac, convallis eget neque. bitur
                  in ante ante. Nullam laoreet tempus erat at ornare. In nisl
                  nisi, dapibus eget facilisis sit amet, commodo quis nibh.
                </p>
                <button className="crer_btn_link  px-20 border-0 mt-34 mt-10 text-white rounded-md font-medium bg-red-600 inline-block">
                  Join Our Team
                </button>
              </div>
              <div style={{ width: "540px", height: "340" }}>
                <img
                  className="w-full"
                  src="https://lms.dalloltech.com/images/about/team.jpg"
                  alt="group-pic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
