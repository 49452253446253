/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const PrintButton = () => {
    const handlePrint = () => {
        window.print();
    };
    return (
        <a
            className="float-right h-10 px-8 py-2 mb-24 bg-red-600 text-white font-semibold text-sm uppercase rounded"
            href="#"
            onClick={handlePrint}
        >
            Print
        </a>
    );
};
export default PrintButton;