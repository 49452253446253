import React, {useContext} from "react";
import ProfileComponent from "./rightComp/profileCommon";
import { instructors } from "./rightComp/tempFiles";
import Slider from "../Model/Slider";
import AppContext from "../Context/AppContext";

export default function InstructorProfile() {
  const {tog} = useContext(AppContext);

  return (
    <>
    <div className='flex justify-between px-4 font-semibold font-family-san-sarif'>
        <p className="text-lg text-stone-600">Popular Instructors</p>
        <p className='text-stone-300 text-base hover:text-stone-600 hover:cursor-pointer'>See all</p>
    </div>
    <Slider classProp={["translate-x-6", "-translate-x-5"]}>
      <div
        className="flex w-full gap-4 h-full whitespace-nowrap"
      >
        
          {instructors.map((instructor, index) => (
            <div
              key={index}
              className={`${tog ? "w-cardsm ":"w-cardbg "}
              flex flex-col items-center
               ease-in-out duration-500
              bg-white shadow pt-4 pb-4 my-8 gap-4`}
            >
                <ProfileComponent
                key={index}
                tog={tog}
                user={instructor} />
            </div>
          ))}
      </div>
      </Slider>
    </>
  );
}