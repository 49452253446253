import NavSideBarComb from "../navSidebarComb";
import UserCard from "../UserCard.jsx";
import GuideInfo from "../GuideInfo.jsx";
import InstructorProfile from "../InstructorsList.jsx";
import FeaturedCourses from '../FeaturedCourses';
import NewestCourseCard from "../NewestCourseCard.jsx";
import Combinaton from "../rightComp/allComp.js";
import AppContext from "../../Context/AppContext";
import { useContext } from "react";

export default function Home() {
  const {tog} = useContext(AppContext);

  return (
    <NavSideBarComb>
      <div
          className={`transition-all duration-500 transform flex flex-col flex-wrap  ${
            tog ? "w-11/12" : "w-11/12 ml-3"
          } mt-5 lg:mt-12 lg:pt-16 ml-2`}
        >
          <div className="flex flex-col lg:flex-row w-full gap-4">
            <div className="w-full lg:w-[78%]">
              <UserCard tog={tog}/>
              <FeaturedCourses tog={tog} />
              <NewestCourseCard tog={tog} />
              <GuideInfo tog={tog} />
              <InstructorProfile tog={tog} />

            </div>
            <div className="w-full lg:w-1/4">
              <Combinaton />
            </div>
          </div>
        </div>
      
    </NavSideBarComb>
  );
}
