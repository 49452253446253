import React from "react";
import RepeatedComponent from "./RepeatedComponent";
import RepeatedHeader from "./RepeatedHeader";
import Footer from "../../components/Footer";
const Press = () => {
  return (
    <div>
      <RepeatedHeader />
      <RepeatedComponent />
      <div className="py-10 bg-gray-100">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10">
          <div className="flex justify-between">
            <div className=" mr-10">
              <div className="bg-white w-72  p-3   border border-gray-300 transition-all duration-200 flex flex-col items-center">
                <div className=" w-full p-3  flex flex-col justify-center items-center">
                  <button className=" bg-blue-500 text-white font-medium text-sm font-roboto py-2   h-8 w-full">
                    Follow
                  </button>
                  <button className="bg-blue-600 text-white font-medium text-sm font-roboto py-2 px-4  h-8 w-full mt-2">
                    Follow
                  </button>
                </div>
              </div>
              <div className="bg-white w-72  p-3 mb-6 border bottom-0 transition-all duration-200 flex flex-col items-center">
                <p className="font-normal font-roboto text-base leading-6 text-gray-600 mb-2 mt-0">
                  Learn more
                </p>
                <a
                  href="#"
                  className="font-normal font-roboto text-base leading-6"
                >
                  Cursus help center
                </a>
              </div>
            </div>
            <div className="mt-0">
              <div className=" mb-10">
                <h2 className="text-2xl font-semibold font-roboto mb-4 text-left text-gray-700 leading-6">
                  Cursus in the News
                </h2>
                <p className="text-base font-normal font-roboto mb-0 text-left text-gray-500 leading-6">
                  For media interviews and inquiries, please send an email to
                  <a href="#">[email protected]</a>
                </p>
              </div>
              <div className="py-0">
                <div className="bg-white p-4  rounded-md border border-gray-200">
                  <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                    March 10,2023
                  </div>
                  <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                    Press Newa Title
                  </h4>
                  <p className="text-base font-normal font-roboto mb-0 text-left text-gray-500 leading-6">
                    Donec eget arcu vel mauris lacinia vestibulum id eu elit.
                    Nam metus odio, iaculis eu nunc et, interdum mollis arcu.
                    Pellentesque viverra faucibus diam. In sit amet laoreet
                    dolor, vitae fringilla quam...
                  </p>
                  <a
                    className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                    href="#"
                  >
                    {" "}
                    Read More
                  </a>
                </div>

                <div className="bg-white p-4 rounded-md border border-gray-200 mt-8">
                  <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                    March 10,2023
                  </div>
                  <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                    Press Newa Title
                  </h4>
                  <p className="text-base font-normal font-roboto mb-0 text-left text-gray-500 leading-6">
                    Donec eget arcu vel mauris lacinia vestibulum id eu elit.
                    Nam metus odio, iaculis eu nunc et, interdum mollis arcu.
                    Pellentesque viverra faucibus diam. In sit amet laoreet
                    dolor, vitae fringilla quam...
                  </p>
                  <a
                    className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                    href="#"
                  >
                    {" "}
                    Read More
                  </a>
                </div>

                <div className="bg-white p-4 rounded-md border border-gray-200 mt-8">
                  <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                    March 10,2023
                  </div>
                  <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                    Press Newa Title
                  </h4>
                  <p className="text-base font-normal font-roboto mb-0 text-left text-gray-500 leading-6">
                    Donec eget arcu vel mauris lacinia vestibulum id eu elit.
                    Nam metus odio, iaculis eu nunc et, interdum mollis arcu.
                    Pellentesque viverra faucibus diam. In sit amet laoreet
                    dolor, vitae fringilla quam...
                  </p>
                  <a
                    className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                    href="#"
                  >
                    {" "}
                    Read More
                  </a>
                </div>
                <a
                  className="block text-center mt-8 text-base font-medium font-roboto text-gray-700 "
                  href="#"
                >
                  See More News
                </a>
                <div className="mt-0">
                  <div className=" mb-10 ">
                    <h2 className="text-2xl font-semibold font-roboto mb-4 text-left text-gray-700 leading-6">
                      Cursus in the News
                    </h2>
                    <p className="text-base font-normal font-roboto mb-0 text-left text-gray-500 leading-6">
                      For media interviews and inquiries, please send an email
                      to
                      <a href="#">[email protected]</a>
                    </p>
                  </div>
                  <div className="py-0">
                    <div className="bg-white p-4 rounded-md border border-gray-200">
                      <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                        March 10,2023
                      </div>
                      <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                        Press Newa Title
                      </h4>

                      <a
                        className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                        href="#"
                      >
                        {" "}
                        Read More
                      </a>
                    </div>

                    <div className="bg-white p-4 rounded-md border border-gray-200 mt-8">
                      <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                        March 10,2023
                      </div>
                      <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                        Press Newa Title
                      </h4>

                      <a
                        className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                        href="#"
                      >
                        {" "}
                        Read More
                      </a>
                    </div>

                    <div className="bg-white p-4 rounded-md border border-gray-200 mt-8">
                      <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                        March 10,2023
                      </div>
                      <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                        Press Newa Title
                      </h4>

                      <a
                        className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                        href="#"
                      >
                        {" "}
                        Read More
                      </a>
                    </div>
                    <div className="bg-white p-4 rounded-md border border-gray-200 mt-8">
                      <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                        March 10,2023
                      </div>
                      <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                        Press Newa Title
                      </h4>

                      <a
                        className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                        href="#"
                      >
                        {" "}
                        Read More
                      </a>
                    </div>
                    <div className="bg-white p-4 rounded-md border border-gray-200 mt-8">
                      <div className="text-base font-normal font-roboto mb-0 text-left text-gray-500">
                        March 10,2023
                      </div>
                      <h4 className="mb-3 mt-4 text-lg font-semibold font-roboto text-gray-700">
                        Press Newa Title
                      </h4>

                      <a
                        className="mt-4 inline-block text-base font-medium font-roboto text-gray-700 rounded-md"
                        href="#"
                      >
                        {" "}
                        Read More
                      </a>
                    </div>
                    <a
                      className="block text-center mt-8 text-base font-medium font-roboto text-gray-700 "
                      href="#"
                    >
                      See More News
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Press;
