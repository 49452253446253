import React from "react";
import NavSideBarComb from "../../components/navSidebarComb";
import { useState } from "react";
const AddLiveStream = ({ tog }) => {
  const [toggle, setToggle] = useState(true);
  return (
    <>
      <NavSideBarComb>
        <div
          className={`transition-all duration-500 transform flex flex-col flex-wrap max-h-72 ${
            tog ? "w-11/12" : "w-11/12 ml-3"
          } mt-12 pt-16 ml-3 max-h-screen`}
        >
          {toggle ? (
            <div className="w-full px-4 mx-auto">
              <div className=" w-full"></div>
              <div className=" w-full flex justify-center">
                <div className=" mr-4">
                  <button
                    onClick={() => setToggle(true)}
                    className="border rounded-md px-5 py-2 bg-black text-white font-normal font-roboto text-sm"
                  >
                    <a href="#">Add Streaming</a>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => setToggle(false)}
                    class="border-2  border-red-500 px-5 py-2 bg-red-500 text-white font-normal font-roboto text-sm"
                  >
                    <a href="#">Setting</a>
                  </button>
                </div>
              </div>
              <div className=" w-full flex justify-center">
                <div className="bg-white mt-8 p-8 max-w-md  px-5 rounded-lg border border-gray-200 mb-5">
                  <h4 className="text-lg font-medium font-roboto text-center text-gray-700 leading-6 mb-0">
                    Connect your live stream to the Live API
                  </h4>
                  <div className="text-sm font-normal font-roboto text-center text-gray-600 mt-2 leading-6">
                    Use live-streaming software or a hardware encoder.
                    <a className="font-medium text-gray-700" href="#">
                      Learn More
                    </a>
                  </div>
                  <div className="text-base font-normal font-roboto mt-8 text-gray-700 leading-6 flex justify-center">
                    Preview your broadcast with a stream key or paired encoder.
                  </div>
                  <div className=" flex justify-center">
                    <div className="mt-27  ">
                      <div className="">
                        <div className="">
                          <label
                            className="text-sm font-medium font-roboto mb-4 text-gray-700 leading-6"
                            htmlFor=""
                          >
                            Server URL*
                          </label>
                        </div>
                        <div className=" relative max-w-md">
                          <input
                            placeholder="hey"
                            className=" pl-5 pr-28 py-2 bg-slate-400 w-96"
                            type="text"
                          />
                          <div className="absolute top-2 right-2 ">
                            <button className=" bg-red-500 px-3 ">Copy</button>
                          </div>
                        </div>
                      </div>
                      <div className=" mt-7">
                        <div className="">
                          <div className="">
                            <label
                              className="text-sm font-medium font-roboto mb-4 text-gray-700 leading-6"
                              htmlFor=""
                            >
                              Persistent stream key*
                            </label>
                          </div>
                          <div className=" relative max-w-md">
                            <input
                              placeholder="hey"
                              className=" pl-5 pr-28 py-2 bg-slate-400 w-96"
                              type="text"
                            />
                            <div className="absolute top-2 right-2 ">
                              <button className=" bg-red-500 px-3 ">
                                Copy
                              </button>
                            </div>
                          </div>
                        </div>
                        <button className="h-10 px-5 bg-red-500 text-white border-0 mt-8 rounded-md text-sm font-medium font-roboto">
                          Go live
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className=" w-full flex justify-center">
              <div className=" mr-4">
                <button
                  onClick={() => setToggle(true)}
                  className="border rounded-md px-5 py-2 bg-black text-white font-normal font-roboto text-sm"
                >
                  <a href="#">Add Streaming</a>
                </button>
              </div>
              <div className=" ">
                <button
                  onClick={() => setToggle(false)}
                  class="border-2  border-red-500 px-5 py-2 bg-red-500 text-white font-normal font-roboto text-sm"
                >
                  <a href="#">Setting</a>
                </button>
              </div>
            </div>
          )}
        </div>
      </NavSideBarComb>
    </>
  );
};

export default AddLiveStream;
