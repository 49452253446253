import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
const RepeatedComponent = () => {
  const [toggle, setToggle] = useState("About");
  return (
    <div>
      <div class="w-full pt-10 bg-white relative bg-aboutbg  mb-0">
        <div class=" w-96 mx-auto ">
          <div className=" flex justify-between mt-10">
            {["About", "Company", "Careers", "Press"].map((item) => (
              <div className="text-gray-700 bg-transparent border-none border-b-2 border-red-600">
                <Link to={`/${item}`}>
                  <p
                    onClick={() => setToggle(item)}
                    className="text-gray-800 bg-transparent  border-solid border-opacity-100"
                  >
                    {item}
                  </p>

                  {toggle === item ? (
                    <img
                      className="  pt-5 mb-6"
                      src="https://lms.dalloltech.com/images/line.svg"
                      alt="line-svg"
                    />
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            ))}
          </div>
          <div className=" mt-20 mb-10 text-center pb-10">
            <h2 className="text-2xl font-semibold font-roboto mt-30 text-gray-700 text-center leading-30">
              Improving Lives Through Learning
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepeatedComponent;
