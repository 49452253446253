import React from "react";
import NavSideBarComb from "../components/navSidebarComb";
import { FiSettings } from "react-icons/fi";
// import { FbIcon } from "react-icons/fb";
import { TiSocialTwitter } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useState } from "react";
import line from '../assets/line.svg'
import AboutInstructor from "./AboutInstructor";
import Purchased from "./Purchased";
import Discussion from "./Discussion";
import Subacription from "./Subacription";
import Courses from "./Courses";
const MyInstructorProfile = ({ tog }) => {
  const [toggle, setToggle] = useState("About");
  return (
    <>
      <NavSideBarComb>
        <div
          className={`transition-all duration-500 transform flex flex-col flex-wrap  mt-12 pt-16 ml-12 w-full `}
        >
          <div className=" w-full bg-gray-900 py-7">
            <div className="w-full px-4 mx-auto">
              <div className="w-full flex flex-col md:flex-row md:justify-center">
                <div className=" flex-col w-2/3  ">
                  <div className="flex flex-col items-center  w-full pl-8 lg:flex-col pl-8 ">
                    <div className=" flex flex-col md:flex-row">
                      <div className="flex justify-center  md:justify-start   ">
                        <img
                          className=""
                          src="https://lms.dalloltech.com/images/hd_dp.jpg"
                          alt="avator"
                        />
                      </div>
                      <div className="ml-5 text-left mb-5 md:text-center sm:text-center">
                        <h2 className="text-white text-2xl font-semibold font-roboto mb-4">
                          {" "}
                          Joginder Singh
                        </h2>
                        <span className=" text-white">
                          UI / UX Designer and Web Developer
                        </span>
                      </div>
                    </div>
                    <ul className="mt-2 border border-gray-600 w-full md:w-96">
                      <li className="inline-block w-1/4 py-4 px-2 border-r border-gray-600">
                        <div className="text-white flex justify-center items-center">
                          Eroll Students
                        </div>
                        <div className="text-xs mt-2 text-white flex justify-center items-center">
                          612k
                        </div>
                      </li>
                      <li className="inline-block w-1/4 py-4 px-2 border-r border-gray-600">
                        <div className="text-white flex justify-center items-center">
                          Courses
                        </div>
                        <div className="text-xs mt-2 text-white flex justify-center items-center">
                          8k
                        </div>
                      </li>
                      <li className="inline-block w-1/4 py-4 px-2 border-r border-gray-600">
                        <div className="text-white flex justify-center items-center">
                          Reviews
                        </div>
                        <div className="text-xs mt-2 text-white flex justify-center items-center">
                          11k
                        </div>
                      </li>
                      <li className="inline-block w-1/4 py-4 px-2 border-r border-gray-600">
                        <div className="text-white flex justify-center items-center">
                          Subscription
                        </div>
                        <div className="text-xs mt-2 text-white flex justify-center items-center">
                          452k
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className=" w-5/12 mr-12">
                  <div className=" pr-10">
                    <a
                      href="#"
                      className="mt-6 text-white text-sm font-roboto font-normal text-center "
                    >
                      <div className="flex justify-end items-center">
                        <FiSettings />
                        <span className="text-white ml-2">Setting</span>
                      </div>
                    </a>
                  </div>
                  <div className=" pr-10 flex justify-end items-center mt-4 w-full">
                    <div className=" w-8 border border-gray-400 flex justify-center ml-3 px-5 py-2 bg-blue-500">
                      <a href="#">f</a>
                    </div>
                    <div className=" w-8 border border-gray-400 flex justify-center ml-3 px-5 py-2 bg-blue-400">
                      <a href="#">t</a>
                    </div>
                    <div className=" w-8 border border-gray-400 flex justify-center ml-3 px-5 py-2">
                      <a href="#">i</a>
                    </div>
                    <div className=" w-8 border border-gray-400 flex justify-center ml-3 px-5 py-2 bg-red-600">
                      <a href="#">y</a>
                    </div>
                  </div>
                  <div className=" flex justify-end pr-10 mt-7">
                    <button className=" bg-red-600 text-white px-7 py-2">
                      Cursus Studio
                    </button>
                    <div className="ml-4 border border-gray-100 text-white px-7 py-2">
                      Edit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-10 mb-8  bg-white">
            <div className="  flex justify-between mt-10 max-w-xl">
              {[
                "About",
                "Courses",
                "Purchased",
                "Discussion",
                "Subscription",
              ].map((item, index) => (
                <div className="text-gray-700 bg-transparent border-none border-b-2 ">
                  <Link>
                    <p
                      onClick={() => setToggle(item)}
                      className="text-gray-800 bg-transparent  border-solid border-opacity-100"
                    >
                      {item}
                    </p>

                    {toggle === item ? (
                      <img
                        className="  pt-5 "
                        src={line}
                        alt="line-svg"
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {toggle === "About" && <AboutInstructor />}
          {toggle === "Purchased" && <Purchased />}
          {toggle === "Discussion" && <Discussion />}
          {toggle === "Subscription" && <Subacription />}
          {toggle === "Courses" && <Courses />}
        </div>
      </NavSideBarComb>
    </>
  );
};

export default MyInstructorProfile;
