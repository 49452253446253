import React from "react";
import { testimonial } from "../tempFiles/tempFiles";
import Slider from "../Model/Slider";

export default function Testimonials() {
  return (
    <div className="bg-stone-100">
      <p className="text-lg text-stone-600 pl-2">What Our Student Have To Say</p>
      <Slider classProp={["translate-x-4", "-translate-x-5"]}>
        <div
          className="w-full h-full whitespace-nowrap"
        >
          <div
            className="flex gap-4 w-full
            scroll-smooth scroll-snap scroll-snap-type-y scroll-snap-points-x-[0, 50, 100]"
            style={{
              width: `${testimonial.length * 33.333}%`, // Set each card to be 33.333% wide
            }}
          >
            {testimonial.map((testimonialVal, index) => (
              <div
                key={index} // Added key prop to avoid React warning
                className="position-relative touch-manipulation transition-all ease-0"
                style={{ width: "33.333%" }} // Set each card to be 33.333% wide
              >
                <div className="flex flex-col bg-white shadow w-full my-8 gap-4">
                  <p className="whitespace-break-spaces pr-6 pl-6">
                    "{testimonialVal.testimonial}"
                  </p>

                  <div className="flex pb-4 pl-8">
                    <img
                      className="rounded-full w-12 flex radius-100"
                      src={testimonialVal.image}
                      alt={testimonialVal.name}
                    />
                    <p className="pt-4 pl-1 font-medium">{testimonialVal.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Slider>
      </div>
  );
}
