import React, { useContext } from "react";
import Livestream from "./rightComp/liveStream";
import { Users } from "../tempFiles/tempFiles";
import UserCards from "../Model/userCard";
import NavSideBarComb from "./navSidebarComb";
import AppContext from "../Context/AppContext";
export default function LiveStreams() {
  const { tog } = useContext(AppContext);

  return (
    <NavSideBarComb>

      <div className={`flex flex-col lg:flex-row w-full transition-all duration-500 lg:${tog ? "w-11/12 pl-12" : 'w-11/12'}`}>
        <div
          className={`flex flex-col flex-wrap mt-5 lg:mt-12 lg:pt-16 px-2 lg:px-0 transition-all duration-500 lg:${tog ? "ml-18" : "ml-3"} lg:w-3/4`}
        >
          <h3 className="text-xl text-stone-700 font-medium">All Live Streams</h3>
          <div
            className={`flex flex-wrap gap-6`}
          >
            <UserCards Users={Users} />
          </div>
        </div>
        <div
          className={`pt-16 px:2 lg:px-0 transition-all duration-500 lg:mr-5 lg:w-1/4`}
        >
          <Livestream tog={tog} />
        </div>
      </div>
    </NavSideBarComb>
  )
}
