/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import 'react-router-dom';
import CommonFormat from './commonFormat';
import './course.css';
import Footer from "../Footer";
const CourseDetail = () => {

    return (
        <>
            <CommonFormat />


            <Footer />
        </>
    );
}

export default CourseDetail;

