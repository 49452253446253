import React from "react";

const Subacription = () => {
  return (
    <>
      <div className=" mt-8 mb-5">
        <div>
          <h3 className="text-2xl font-medium font-roboto text-gray-700 text-left mb-4">
            Subscription
          </h3>
        </div>
        <div className="flex flex-col items-center  md:flex-row">
          <div className=" w-72">
            <div className="bg-white w-full float-left p-2  border border-gray-300 transition-all duration-200 ease-in-out">
              <div className=" flex-col">
                <div className="w-full  p-3 text-center">
                  <a className="text-blue-500 no-underline" href="#">
                    <img
                      className="text-center w-24 h-24 inline-block rounded-full border-2 border-white shadow-sm"
                      src="https://lms.dalloltech.com/images/left-imgs/img-1.jpg"
                      alt="pic"
                    />
                  </a>
                </div>
                <div className="text-center  w-full mb-5 px-2">
                  <div className="flex items-center justify-center text-center">
                    <a
                      className="text-lg font-medium font-roboto text-gray-700 text-center relative"
                      href="#"
                    >
                      {" "}
                      John Doe
                    </a>
                  </div>
                  <div className="text-sm text-gray-500 font-roboto font-normal mt-3 mb-4 truncate">
                    Wordpress & Plugin Tutor
                  </div>
                  <div className="flex justify-center">
                    <div className=" mr-2">
                      <button className="text-base font-medium font-roboto text-white bg-red-600 border-0 h-10 px-4 rounded-md text-center">
                        Subscription
                      </button>
                    </div>
                    <div className="">
                      <button className="text-xl font-medium font-roboto text-gray-700 bg-gray-300 rounded-md h-10 w-10 focus:outline-none">
                        B
                      </button>
                    </div>
                  </div>
                  <div className=" flex mt-5 justify-center">
                    <div className="text-xs font-normal font-roboto text-gray-700 text-center block mt-4 mr-2">
                      <span>100K Students</span>
                    </div>
                    <div className="text-xs font-normal font-roboto text-gray-700 text-center block mt-4">
                      <span>15 Courses</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-72 mx-10">
            <div className="bg-white w-full float-left p-2  border border-gray-300 transition-all duration-200 ease-in-out">
              <div className=" flex-col">
                <div className="w-full  p-3 text-center">
                  <a className="text-blue-500 no-underline" href="#">
                    <img
                      className="text-center w-24 h-24 inline-block rounded-full border-2 border-white shadow-sm"
                      src="https://lms.dalloltech.com/images/left-imgs/img-2.jpg"
                      alt="pic"
                    />
                  </a>
                </div>
                <div className="text-center  w-full mb-5 px-2">
                  <div className="flex items-center justify-center text-center">
                    <a
                      className="text-lg font-medium font-roboto text-gray-700 text-center relative"
                      href="#"
                    >
                      {" "}
                      Kerstin Cable
                    </a>
                  </div>
                  <div className="text-sm text-gray-500 font-roboto font-normal mt-3 mb-4 truncate">
                    Language Learning Coach, Writer, Online Tutor
                  </div>
                  <div className="flex justify-center">
                    <div className=" mr-2">
                      <button className="text-base font-medium font-roboto text-white bg-red-600 border-0 h-10 px-4 rounded-md text-center">
                        Subscription
                      </button>
                    </div>
                    <div className="">
                      <button className="text-xl font-medium font-roboto text-gray-700 bg-gray-300 rounded-md h-10 w-10 focus:outline-none">
                        B
                      </button>
                    </div>
                  </div>
                  <div className=" flex mt-5 justify-center">
                    <div className="text-xs font-normal font-roboto text-gray-700 text-center block mt-4 mr-2">
                      <span>114K Students </span>
                    </div>
                    <div className="text-xs font-normal font-roboto text-gray-700 text-center block mt-4">
                      <span>11 Courses</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-72">
            <div className="bg-white w-full float-left p-2  border border-gray-300 transition-all duration-200 ease-in-out">
              <div className=" flex-col">
                <div className="w-full  p-3 text-center">
                  <a className="text-blue-500 no-underline" href="#">
                    <img
                      className="text-center w-24 h-24 inline-block rounded-full border-2 border-white shadow-sm"
                      src="https://lms.dalloltech.com/images/left-imgs/img-3.jpg"
                      alt="pic"
                    />
                  </a>
                </div>
                <div className="text-center  w-full mb-5 px-2">
                  <div className="flex items-center justify-center text-center">
                    <a
                      className="text-lg font-medium font-roboto text-gray-700 text-center relative"
                      href="#"
                    >
                      {" "}
                      Jose Portilla
                    </a>
                  </div>
                  <div className="text-sm text-gray-500 font-roboto font-normal mt-3 mb-4 truncate">
                    Head of Data Science, Pierian Data Inc.
                  </div>
                  <div className="flex justify-center">
                    <div className=" mr-2">
                      <button className="text-base font-medium font-roboto text-white bg-red-600 border-0 h-10 px-4 rounded-md text-center">
                        Subscription
                      </button>
                    </div>
                    <div className="">
                      <button className="text-xl font-medium font-roboto text-gray-700 bg-gray-300 rounded-md h-10 w-10 focus:outline-none">
                        B
                      </button>
                    </div>
                  </div>
                  <div className=" flex mt-5 justify-center">
                    <div className="text-xs font-normal font-roboto text-gray-700 text-center block mt-4 mr-2">
                      <span>1M Students </span>
                    </div>
                    <div className="text-xs font-normal font-roboto text-gray-700 text-center block mt-4">
                      <span>25 Courses</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subacription;
