import React from "react";

function Recommendation({ tog }) {
  return (
    <div className={`w-full h-64 transition-all duration-500 lg:${
      tog ? "w-64" : "w-72"
    } relative`}>
      <div
        className={`p-4 bg-space -translate-y-10 absolute z-0 top-0 left-0 right-0 bottom-0 opacity-80 bg-cover bg-center my-8`}
      >
        {/* Empty div to hold the background image */}
      </div>
      <div className="relative flex flex-col translate-y-7 z-[5] text-white font-bold justify-center items-center text-center space-y-2">
        <p>Get Personalized recommendations</p>
        <p>Answer a few questions for your top picks</p>
        <div className="text-stone-100 text-center bg-redbutton rounded-sm w-24 h-7 hover:cursor-pointer hover:bg-red-700">
          Get Started
        </div>
      </div>
    </div>
  );
}

export default Recommendation;
