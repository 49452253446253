import React, { useState } from "react";
import Modal from "react-modal";
import Lecture from "./Lecture";
import Quiz from "./Quiz";
import Assignment from "./Assignment";
import { BiBookmarkAlt, BiPlusCircle, BiBasket, BiPencil, BiClipboard, BiListUl, BiMenu, BiMenuAltRight, BiBook } from "react-icons/bi";
export default function Curriculum() {
    const [isModalOpen, setIsModalOpen] = useState([false, false, false, false]);
    const [showIcons, setShowIcons] = useState([false, false, false]);
console.log(isModalOpen[0])
  const openModal = (index) => {
    setIsModalOpen(prevState => {
      const newState = [...prevState];
      newState[index]=true;
      return newState;
    });
  };

  const closeModal = (index) => {
    setIsModalOpen(prevState => {
      const newState = [...isModalOpen];
      newState[index]=false;
      return newState;
    });
  };
 
  const handleIconToTrue = (index) => {
    setShowIcons(prevState => {
        const newState = [...showIcons];
        newState[index] = true;
        return newState;})
  }
  const handleIconToFalse = (index) => {
    setShowIcons(prevState => {
        const newState = [...showIcons];
        newState[index] = false;
        return newState;})
  }
    return(
        <>
                <div className="flex flex-col pb-8 gap-8">
                    <hr/>
                    <div className="flex gap-2 items-center">
                        <BiBookmarkAlt size={20}/>
                        <h3>Curriculum</h3>
                    </div>
                    <hr/>
                </div>
            <div className="flex flex-col gap-3">
            <div className="flex flex-col justify-center h-16 bg-white items-center text-center w-full md:pl-6">
                <div className="flex w-full justify-between items-center text-center">
                    <div className="flex gap-3 justify-center items-center text-center">
                        <BiListUl size={22}/>
                        <h4>Curriculum</h4>
                    </div>
                    <button
                    onClick={()=>openModal(0)}
                    className={`transition-all
                                duration-400 font-semibold
                                text-stone-100 text-center transition-500 bg-redbutton
                                rounded-sm w-36 h-9 hover:cursor-pointer hover:bg-slate-700`}>New Section</button>
                </div>
            </div>
            
                <div className="flex flex-col pt-3 gap-6 bg-white">
                    <div className="flex justify-between">
                        <div className="flex pl-3 gap-3 items-center">
                            <BiMenu size={22}/>
                            <h4>Introduction</h4>
                        </div>
                        <div className="flex pr-4 gap-3">
                            <BiPencil size={20}/>
                            <BiBasket size={20}/>
                        </div>
                    </div>
                    <hr/>
                    <div className="flex flex-col gap-4 pl-3 pt-4 pb-4">
                    <div className="flex gap-3 pl-3 h-14 items-center cursor-move bg-slate-100" onMouseEnter={() => handleIconToTrue(0)} onMouseLeave={() => handleIconToFalse(0)}>
                      <BiClipboard size={20} />
                      <div className="flex w-full justify-between">
                          <div className="flex gap-3">
                            <span>lecture Title</span>
                            <div className={`pt-1 gap-2 ${showIcons[0] ? 'flex' : 'hidden'}`}>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  <BiPencil size={20}/>
                              </div>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  <BiBasket size={20} />
                              </div>
                            </div>
                          </div>
                          <div className={`pr-4 text-purple-400 hover:text-purple-800 cursor-pointer ${showIcons[0] ? 'flex' : 'hidden'}`}>
                            <BiMenu size={20} />
                          </div>
                      </div>
                    </div>

                    <div className="flex gap-3 pl-3 h-14 items-center cursor-move bg-slate-100" onMouseEnter={() => handleIconToTrue(1)} onMouseLeave={() => handleIconToFalse(1)}>
                            <BiMenuAltRight size={20}/>
                           <div className="flex w-full justify-between">
                          <div className="flex gap-3">
                            <span>Quiz Title</span>
                            <div className={`pt-1 gap-2 ${showIcons[1] ? 'flex' : 'hidden'}`}>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  <BiPencil size={20}/>
                              </div>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  <BiBasket size={20} />
                              </div>
                            </div>
                          </div>
                          <div className={`pr-4 text-purple-400 hover:text-purple-800 cursor-pointer ${showIcons[1] ? 'flex' : 'hidden'}`}>
                            <BiMenu size={20} />
                          </div>
                      </div>
                    </div>                       
                    <div className="flex gap-3 pl-3 h-14 items-center cursor-move bg-slate-100" onMouseEnter={() => handleIconToTrue(2)} onMouseLeave={() => handleIconToFalse(2)}>
                            <BiBook size={20}/>
                            <div className="flex w-full justify-between">
                          <div className="flex gap-3">
                            <span>Assingment Title</span>
                            <div className={`pt-1 gap-2 ${showIcons[2] ? 'flex' : 'hidden'}`}>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  <BiPencil size={20}/>
                              </div>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  <BiBasket size={20} />
                              </div>
                            </div>
                          </div>
                          <div className={`pr-4 text-purple-400 hover:text-purple-800 cursor-pointer ${showIcons[2] ? 'flex' : 'hidden'}`}>
                            <BiMenu size={20} />
                          </div>
                      </div>
                    </div>
                    </div>
                </div>
                </div>
            <div className="flex text-white h-16 pl-4 text-center items-center bg-black gap-5">
                <div
                onClick={()=>openModal(1)}
                className="flex cursor-pointer gap-2 items-center">
                    <BiPlusCircle size={20}/>
                    <button>Lecture</button>
                </div>
                <div
                onClick={()=>openModal(2)}
                className="flex cursor-pointer gap-2 items-center">
                    <BiPlusCircle size={20}/>
                    <button>Quiz</button>
                </div>
                <div
                onClick={()=>openModal(3)}
                className="flex cursor-pointer gap-2 items-center">
                    <BiPlusCircle size={20}/>
                    <button>Assignment</button>
                </div>
                </div>
                <Modal
                    isOpen={isModalOpen[0]}
                    onRequestClose={()=>closeModal(0)}
                    contentLabel="Popup"
                    className="fixed top-1/3 z-30 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md w-4/5 lg:w-3/5"
                    overlayClassName="fixed top-0 z-10 left-0 w-screen h-screen bg-gray-500 bg-opacity-50"
                >
                    <div className="flex justify-between items-center">
                        <h5>New Section</h5>
                        <button onClick={()=>closeModal(0)} className="text-stone-500 font-bold text-lg hover:text-black">
                          x
                        </button>
                    </div>
                    <hr />
                    <div className="flex flex-col gap-4 pt-2 w-full">
                        <label className="text-lg font-bold mb-2">Section Name*</label>
                        <input type="text" placeholder="Section title here" className="w-full pl-2 h-8 border text-sm border-stone-200 focus:outline-none"/>
                        <hr />
                    </div>
                        <div className="flex justify-end gap-2 pt-3">
                            <button
                                onClick={()=>closeModal(0)}
                                className={`transition-all
                                            duration-400 font-semibold
                                            text-stone-600 text-center transition-500 bg-slate-200
                                            rounded-sm w-36 h-9 hover:cursor-pointe`}>
                                Close</button>
                            <button
                                onClick={()=>openModal(0)}
                                className={`transition-all
                                            duration-400 font-semibold
                                            text-stone-100 text-center transition-500 bg-redbutton
                                            rounded-sm w-36 h-9 hover:cursor-pointer hover:bg-slate-700`}>
                                Add Section</button>
                        </div>
                </Modal>
                <Modal
                isOpen={isModalOpen[1]}
                onRequestClose={()=>closeModal(1)}
                contentLabel="Popup"
                className="fixed top-1/2 h-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md w-full sm:w-md lg:w-3/5"
                overlayClassName="fixed top-0 z-10 left-0 w-screen h-screen bg-gray-500 bg-opacity-50">
                  <Lecture closeModal={()=>closeModal(1)}/>
                </Modal>
                
                <Modal
                isOpen={isModalOpen[2]}
                onRequestClose={()=>closeModal(2)}
                contentLabel="Popup"
                className="fixed top-1/2 h-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md w-full sm:w-md lg:w-3/4"
                overlayClassName="fixed top-0 z-10 left-0 w-screen h-screen bg-gray-500 bg-opacity-50">
                  <Quiz closeModal={()=>closeModal(2)}/>
                </Modal>
                <Modal
                isOpen={isModalOpen[3]}
                onRequestClose={()=>closeModal(3)}
                contentLabel="Popup"
                className="fixed top-1/2 h-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-md w-full sm:w-md lg:w-3/5"
                overlayClassName="fixed top-0 z-10 left-0 w-screen h-screen bg-gray-500 bg-opacity-50">
                  <Assignment closeModal={() => closeModal(3)}/>
                </Modal>            
        </>
    )
}