/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import Second_accordion from './second-accordion';
import Accordion from './accordion';
import '../../App.css';
import '../../index.css';
import Footer from '../Footer'
import 'react-router-dom';
import CirtificateHeader from './header'
import icon_1 from '../../assets/icon-1.svg';
import icon_2 from '../../assets/icon-2.svg';


export const PaidMember = () => {
    return (
        <div className=' overflow-x-hidden'>
            <CirtificateHeader />
            <div className="wrapper _bg4586 _new89">
                <div className="_215b15">
                    <div className="">
                        <div className="flex flex-wrap mt-24 ml-10">
                            <div className="col-lg-12">
                                <div className=" float-left w-full">
                                    <div className=" float-left">
                                        <div className="ttl121">
                                            <nav aria-label="bg-white p-0">
                                                <ol className="flex flex-wrap p-3 mb-4 list-none bg-gray-50 rounded-sm">
                                                    <li className="text-base font-normal font-roboto text-gray-700 leading-6"><a href="index.html">Home /&nbsp;</a></li>
                                                    <li className="text-base font-normal font-roboto leading-6 text-gray-500" aria-current="page"> Paid Membership</li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className=" ml-3">
                                    <h2>Paid Membership</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-full mt-0 mb-5">
                    <div className=" px-4 ">
                        <div className=" flex flex-col sm:flex-row justify-around">
                            <div className=" md:w-2/5">
                                <div className="bg-white w-full p-8 px-5 rounded-md border border-gray-300 shadow-sm transition-all duration-200 ease-in-out mt-12  md:ml-2 md:mr-0">
                                    <div className=" float-left w-full">
                                        <div className=" float-left">
                                            <h2 className='text-xl font-semibold font-roboto mb-5 text-gray-700 text-left leading-7'>Baby Plan</h2>
                                            <div className="text-base font-medium font-roboto mb-4 text-red-600 text-left leading-6">$49/month</div>
                                            <div className="text-base font-medium font-roboto text-gray-600 text-left leading-6">Save $79 when paid annually</div>
                                        </div>
                                        <div className="float-right hidden lg:block">
                                            <img className='h-24' src={icon_1} alt='ballon' ></img>
                                        </div>
                                    </div>
                                    <div className="text-base font-normal font-roboto mt-10 text-gray-600 text-left leading-6">
                                        <p>For instructors launching their first digital products.</p>
                                    </div>
                                    <Accordion />
                                    <button className="mt-5 w-full h-12 rounded-md border-0 bg-red-600 text-white text-base font-semibold font-roboto transition-colors duration-200 hover:bg-gray-700">Purchase Membership</button>
                                </div>
                            </div>
                            <div className="md:w-2/5">
                                <div className="bg-white w-full p-8 px-5 rounded-md border border-gray-300 shadow-sm transition-all duration-200 ease-in-out  mt-12 sm:ml-2 ">
                                    <div className=" float-left w-full">
                                        <div className=" float-left">
                                            <h2 className='text-xl font-semibold font-roboto mb-5 text-gray-700 text-left leading-7'>Premium Plan</h2>
                                            <div className="text-base font-medium font-roboto mb-4 text-red-600 text-left leading-6">$49/month</div>
                                            <div className="text-base font-medium font-roboto text-gray-600 text-left leading-6">Save $79 when paid annually</div>
                                        </div>
                                        <div className=" float-right hidden lg:block">
                                            <img className='h-24' src={icon_2} alt='ballon'></img>
                                        </div>
                                    </div>
                                    <div className="text-base font-normal font-roboto mt-10 text-gray-600 text-left leading-6">
                                        <p>For instructors launching their first digital products.</p>
                                    </div>
                                    <Accordion />
                                    <button className="mt-5 w-full h-12 rounded-md border-0 bg-red-600 text-white text-base font-semibold font-roboto transition-colors duration-200 hover:bg-gray-700">Purchase Membership</button>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-20 ml-10">
                            <div className="w-full">
                                <div className="title589 mt-10">
                                    <h2 className="text-2xl font-semibold font-roboto mb-4 text-center text-gray-700 leading-6">Membership FAQ</h2>
                                    <p className="text-base font-normal font-roboto mb-0 text-center text-gray-600 leading-6 px-24">Wait, what about…</p>
                                </div>
                                <Second_accordion />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}
export default PaidMember;
