import React from 'react'
import CirtificateHeader from '../Tests/cirtificateHeader'
import CollapsiblePanel from './AddressForm';
// import './all.css'
import pyicon_1 from '../../assets/pyicon-1.svg'
import pyicon_2 from '../../assets/pyicon-2.svg'
import pyicon_3 from '../../assets/pyicon-3.svg'
import pyicon_4 from '../../assets/pyicon-4.svg'
import pyicon_5 from '../../assets/pyicon-5.svg'
import line from '../../assets/line.svg'
const Checkout = () => {
    return (<>
        <CirtificateHeader />
        <div className="">
            <div className="flex flex-wrap mt-6 ml-10">
                <div className="col-lg-12">
                    <div className=" float-left w-full">
                        <div className=" float-left">
                            <div className="ttl121">
                                <nav aria-label="bg-white p-0">
                                    <ol className="flex flex-wrap p-3 mb-4 list-none bg-gray-50 rounded-sm">
                                        <li className="text-base font-normal font-roboto text-gray-700 leading-6"><a href="index.html">Home /&nbsp;</a></li>
                                        <li className="text-base font-normal font-roboto text-gray-700 leading-6"><a href="index.html">Paid Membership /&nbsp;</a></li>
                                        <li className="text-base font-normal font-roboto leading-6 text-gray-500" aria-current="page"> Checkout</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className=" ml-3">
                        <h2>Checkout</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='flex flex-col md:flex-row mt-6'>

            <div className=" wrapper flex flex-row-reverse float-left justify-center items-center ml-1 mt-1 mb-5">
                <div className="float-left w-full md:mx-[10px] mt-47 mb-5 mx-16">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="bg-white w-full  float-left p-30  border border-[#efefef]-300 transition-all duration-200 ease-in-out mb-10 ">
                                    <div className="checkout_title">
                                        <h4 className="text-18 font-semibold font-roboto m-3 text-gray-800 text-left leading-26">Billing Details</h4>
                                        <img src={line} alt="" />
                                    </div>
                                    <CollapsiblePanel
                                        title="Edit Address"
                                    />
                                    <div class="mb-4 ml-3">
                                        <h3 class="text-lg font-semibold mb-2">Joginder Singh</h3>
                                        <div class="text-gray-600">
                                            <p class="mb-2"> #1234 Street No. 45, Ward No. 60, Phase 3,</p>
                                            <p class="mb-2"> Shahid Karnail Singh Nagar, Near Pakhowal
                                                Road.</p>
                                            <p class="mb-2"> Ludhiana, Punjab, 141013</p>
                                            <p class="mb-2"> India</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white w-full  float-left p-30  border border-[#efefef]-300 transition-all duration-200 ease-in-out mb-30">
                                    <div className="checkout_title  m-5">
                                        <h4 className="text-lg font-bold">Select Payment Method</h4>
                                        <img src={line} alt="" />
                                    </div>
                                    <div className=" w-full mt-[42px] mb-[20px]">
                                        <ul className="flex gap-[2.5rem] border-t border-l border-r border-[#efefef]-200 m-0 border-b-0 bg-gray-100 rounded-t-md" id="myTab" role="tablist">
                                            <li className=" w-2/6 text-center border-r-[#efefef] inline-block border-b-2 border-transparent hover:border-red-600">
                                                <a href="#credit-method-tab" id="credit-tab" className="active nav-link"><i className="uil uil-card-atm check_icon5" />Credit/Debit Card</a>
                                            </li>
                                            <li className="w-2/6 text-center border-r-[#efefef] inline-block border-b-2 border-transparent hover:border-red-600">
                                                <a href="#bank-method-tab" id="bank-tab" className="nav-link"><i className="uil uil-university check_icon5" />Bank Transfer</a>
                                            </li>
                                            <li className="w-2/6 text-center border-r-[#efefef] inline-block border-b-2 border-transparent hover:border-red-600">
                                                <a href="#payapl-method-tab" id="payapl-tab" className="nav-link"><i className="uil uil-paypal check_icon5" />Paypal</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content ml-5" id="myTabContent">
                                        <div class="tab-content mb-5" id="myTabContent">
                                            <div class="tab-pane fade show active" id="credit-method-tab" role="tabpanel" aria-labelledby="credit-tab">
                                                <form>
                                                    <div class="flex flex-wrap gap-4">
                                                        <div class="w-full md:w-1/2">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">Holder Name</label>
                                                                <div class="ui left icon input ">
                                                                    <input class="w-full  ml-[-10px] sm:ml-[0px]   px-10 py-2  border border-gray-300 rounded-md text-base" type="text" name="holder[name]" id="id_holdername" required maxlength="64" placeholder="Enter Holder Name" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/4">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">Card Number</label>
                                                                <div class="ui left icon input ">
                                                                    <input class="w-full  ml-[-10px] sm:ml-[0px]   px-10 py-2  border border-gray-300 rounded-md text-base" type="text" name="card[number]" maxlength="16" placeholder="Card #" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/4">
                                                            <div class="relative inline w-64">
                                                                <label class="font-light">Expiration Month</label>
                                                                <select class=" mb-[15px]  text-base block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500" name="card[expire-month]">
                                                                    <option value>Month</option>
                                                                    <option value="1">January</option>
                                                                    <option value="2">February</option>
                                                                    <option value="3">March</option>
                                                                    <option value="4">April</option>
                                                                    <option value="5">May</option>
                                                                    <option value="6">June</option>
                                                                    <option value="7">July</option>
                                                                    <option value="8">August</option>
                                                                    <option value="9">September</option>
                                                                    <option value="10">October</option>
                                                                    <option value="11">November</option>
                                                                    <option value="12">December</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/5">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">Expiration Year</label>
                                                                <div class="ui left icon input ">
                                                                    <input class=" mb-[15px]  text-base block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500" type="text" name="card[expire-year]" maxlength="4" placeholder="Year" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/5">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">CVC</label>
                                                                <div class="ui left icon input ">
                                                                    <input class="w-full  ml-[-10px] sm:ml-[0px]   px-10 py-2  border border-gray-300 rounded-md text-base" type="text" name="card[cvc]" maxlength="3" placeholder="CVC" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="tab-pane fade" id="bank-method-tab" role="tabpanel" aria-labelledby="bank-tab">
                                                <form>
                                                    <div class="flex flex-wrap gap-4">
                                                        <div class="w-full md:w-1/2">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">Account Holder Name</label>
                                                                <div class="ui left icon input ">
                                                                    <input class="w-full  ml-[-10px] sm:ml-[0px]   px-10 py-2  border border-gray-300 rounded-md text-base" type="text" name="account[holdername}" required maxlength="64" placeholder="Enter Your Full Name" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/2">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="ftext-base font-normal font-roboto text-gray-800 text-left block">Account Number</label>
                                                                <div class="ui left icon input ">
                                                                    <input class="w-full  ml-[-10px] sm:ml-[0px]   px-10 py-2  border border-gray-300 rounded-md text-base" type="text" name="Account[number]" maxlength="10" placeholder="Enter Account Number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/2">
                                                            <div class="lbel25 mt-30">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">Bank Name</label>
                                                                <select class="w-full px-10 py-2  border border-gray-300 rounded-md text-base" name="Bank[name]">
                                                                    <option value>State Bank of India</option>
                                                                    <option value="1">Indian Bank</option>
                                                                    <option value="2">ICICI Bank</option>
                                                                    <option value="3">HDFC Bank</option>
                                                                    <option value="4">Yes Bank</option>
                                                                    <option value="5">Oriental Bank</option>
                                                                    <option value="6">Punjab National Bank</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="w-full md:w-1/2">
                                                            <div class="ui search focus mt-30 lbel25">
                                                                <label class="text-base font-normal font-roboto text-gray-800 text-left block">IFSC Code</label>
                                                                <div class="ui left icon input ">
                                                                    <input class="w-full  ml-[-10px] sm:ml-[0px]   px-10 py-2  border border-gray-300 rounded-md text-base" type="text" name="IFSC[code]" maxlength="10" placeholder="Enter IFSC Code" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="tab-pane fade" id="payapl-method-tab" role="tabpanel" aria-labelledby="payapl-tab">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p class="t-body">After payment via PayPal's secure checkout, we will send you a link to download your files.</p>
                                                        <div class="media h-mt2">
                                                            <div class="media__item -align-center">
                                                                <p class="t2-body h-m0">PayPal accepts</p>
                                                            </div>
                                                            <div class="flex  self-start">
                                                                <ul id="paypal-gateway" class="  flex flex-wrap gap-1   list-none leading-[0] ">
                                                                    <li class="m-0 mr-1 mb-5px ">
                                                                        <img className='w-[48px] h-[30px] ' alt="Visa" title="Visa" src={pyicon_1} />
                                                                    </li>
                                                                    <li class="m-0 mr-1 mb-5px">
                                                                        <img className='w-[48px] h-[30px] ' alt="MasterCard" title="MasterCard" src={pyicon_2} />
                                                                    </li>
                                                                    <li class="m-0 mr-1 mb-5px">
                                                                        <img className='w-[48px] h-[30px] ' alt="American Express" title="American Express" src={pyicon_3} />
                                                                    </li>
                                                                    <li class="m-0 mr-1 mb-5px">
                                                                        <img className='w-[48px] h-[30px] ' alt="Discover" title="Discover" src={pyicon_4} />
                                                                    </li>
                                                                    <li class="m-0 mr-1 mb-5px">
                                                                        <img className='w-[48px] h-[30px] ' alt="China UnionPay" title="China UnionPay" src={pyicon_5} />
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <div className=" mt-5 w-full border-t  pt-5">
                                            <div className="checkout_title ml-3 mt-5">
                                                <h4 className="text-lg font-bold">Order Details</h4>
                                                <img src={line} alt="" />
                                            </div>
                                            <div className="float-left w-full px-[15px]">
                                                <div className="float-left w-full py-1 border-b border-[#efefef]-300">
                                                    <h4 className="text-base">Baby Plan</h4>
                                                    <div className="text-base font-medium font-roboto text-gray-500 text-right float-right w-1/3 mb-0">$49</div>
                                                </div>
                                                <div className="float-left w-full py-1 border-b border-[#efefef]-300">
                                                    <h6 className="text-base">Taxes(GST)</h6>
                                                    <div className="text-base font-medium font-roboto text-gray-500 text-right float-right w-1/3 mb-0">$2</div>
                                                </div>
                                                <div className="float-left w-full py-1 border-b border-[#efefef]-300">
                                                    <h3 className="text-xl">Total</h3>
                                                    <div className="text-base font-medium font-roboto text-gray-500 text-right float-right w-1/3 mb-0">$51</div>
                                                </div>
                                                <button className="chckot_btn bg-red-500 text-white font-bold px-4 py-2 rounded-lg" type="submit">Confirm Checkout</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/3">
                <div className="w-415 h-418 flex  self-start items-start ml-3 border border-gray-300 flex-col justify-start bg-white p-5 top-[90px] sticky mr-[20px] mx-[2px] mb-5">
                    <div className="checkout_title">
                        <h4 className="text-lg font-bold">Order Summary</h4>
                        <img src={line} alt="" />
                    </div>
                    <div className="float-left w-full">
                        <div className="float-left w-full py-1 border-b border-[#efefef]-300">
                            <h4 className="text-base ">Baby Plan</h4>
                            <div className="text-base font-medium font-roboto text-gray-500 text-right float-right w-1/3 mb-0">$49</div>
                        </div>
                        <div className="float-left w-full py-1 border-b border-[#efefef]-300">
                            <h6 className="text-base ">Taxes(GST)</h6>
                            <div className="text-base font-medium font-roboto text-gray-500 text-right float-right w-1/3 mb-0">$2</div>
                        </div>
                        <div className="float-left w-full py-1 border-b border-[#efefef]-300">
                            <h2 className="text-2xl">Total</h2>
                            <div className="text-base font-medium font-roboto text-gray-500 text-right float-right w-1/3 mb-0">$51</div>
                        </div>
                        <div className="text-sm font-normal text-gray-500 font-sans text-center float-left w-full mt-27"><i className="text-xl mr-5" />Secure checkout</div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
};



export default Checkout
