import React from "react";
import {  BiAddToQueue, BiAlbum, BiBaseball, BiInfoCircle, BiWalletAlt, BiCategory } from "react-icons/bi";
import { MdOutlineNotifications, MdMessage, MdOutlineAnalytics, MdOutlineBook } from "react-icons/md";
import { Link } from "react-router-dom";

export default function PersonalLeftSideBar() {
    const SidebarButton = ({ icon, label }) => (
        <button
          className="flex items-center w-full pt-2 pb-2 space-x-6 focus:outline-none text-black hover:text-red-700 hover:bg-red-100"
        >
          {icon({ size: 30, className: "pl-3" })}
            <Link to={`${label[1]}`}>
                <p className="text-base leading-4 ">{label[0]}</p>
            </Link>
        </button>
      );
    return(
    <>
        <div className=" mr-64 w-0">
                        <div className="fixed mr-12 pb-5 pt-5 font-medium h-screen overflow-auto
                        overflow-y-hidden hover:overflow-y-visible  
                          w-full sm:w-64 bg-white
                        flex-col shadow-2xl">
                            <SidebarButton icon={BiCategory} label={["Dashboard", "/dashboard"]} />
                            <SidebarButton icon={MdOutlineBook} label={["Courses", "/"]} />
                            <SidebarButton icon={MdOutlineAnalytics} label={["Analysis", "/"]} />
                            <SidebarButton icon={BiAddToQueue} label={["Create Course", "/create_new_course"]} />
                            <SidebarButton icon={MdMessage} label={["Messages", "/instructor_messages"]} />
                            <SidebarButton icon={MdOutlineNotifications} label={["Notifications", "/instructor_notifications"]} />
                            <SidebarButton icon={BiInfoCircle} label={["My Certificates", "/"]} />
                            <SidebarButton icon={BiAlbum} label={["Reviews", "/"]} />
                            <SidebarButton icon={BiAddToQueue} label={["Earning", "/"]} />
                            <SidebarButton icon={BiBaseball} label={["Payout", "/"]} />
                            <SidebarButton icon={BiAlbum} label={["Statements", "/"]} />
                            <SidebarButton icon={BiBaseball} label={["Verification", "/instructor_verification"]} /><hr/>
                            <SidebarButton icon={BiWalletAlt} label={["Setting", "/setting"]} />
                            <SidebarButton icon={BiAlbum} label={["Send Feedback", "/"]} />
                        </div>
                    </div>
    </>
    )
}