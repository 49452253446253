import { useRef } from "react";
const Div = ({children, tog}) => {
    const ref = useRef();
    return (
    <div ref={ref} 
    className={`bg-white shadow p-4
    text-center flex flex-col justify-center
    items-center my-8 gap-6 transition-all duration-500 transform w-full lg:${tog ? 'w-64':'w-72'}`}>
        {children}
    </div>
    );
};

export default Div;