import React, { createContext, useState, useEffect } from "react";

// Create the initial context state
const AppContext = createContext();

// Create a provider component to wrap the entire application
export function AppContextProvider({ children }) {
  const [tog, setTog] = useState(false);
  const [isBrightMode, setIsBrightMode] = useState(true);

  // Function to update 'tog' based on screen size
  const updateTogBasedOnScreenSize = () => {
    const largeScreen = window.matchMedia("(max-width: 1024px)").matches;
    setTog(!largeScreen);
  };

  useEffect(() => {
    // Call the function initially to set the initial 'tog' value
    updateTogBasedOnScreenSize();

    // Add a resize event listener to update 'tog' when screen size changes
    window.addEventListener("resize", updateTogBasedOnScreenSize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateTogBasedOnScreenSize);
  }, []);

  return (
    // Provide the state value to all children components
    <AppContext.Provider value={{ tog, setTog, isBrightMode, setIsBrightMode }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
