import React, { useState } from "react";
import { TextArea } from "./TextArea";
import Select from "react-select";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { CourseCategoryData, Language, CourseLevel } from "../../Model/basicInfoData";
import { customStyles, specificCustomStyles } from "./customStyle";

export default function BasicInformation() {
  const [selectedCourseLevel, setSelectedCourseLevel] = useState([]);
  const [selectedCourseCategory, setSelectedCourseCategory] = useState([]);
  const [selectedAudioLanguage, setSelectedAudioLanguage] = useState([]);
  const [selectedCloseCaption, setSelectedCloseCaption] = useState([]);

  const handleCourseLevelChange = (selectedOptions) => {
    setSelectedCourseLevel(selectedOptions);
  };

  const handleCourseCategoryChange = (selectedOptions) => {
    setSelectedCourseCategory(selectedOptions);
  };

  const handleAudioLanguageChange = (selectedOptions) => {
    setSelectedAudioLanguage(selectedOptions);
  };

  const handleCloseCaptionChange = (selectedOptions) => {
    setSelectedCloseCaption(selectedOptions);
  };

  
  const formattedOptions = CourseCategoryData.flatMap(category => [
    { label: category.label, value: null, isDisabled: true },
    ...category.options.map(option => ({ label: option, value: option }))
  ]);
    
    return(
        <>
            <div className="pb-4">
                <hr/>
                <div className="flex gap-2 pb-6 pt-6">
                    <div className="pt-1">
                        <HiOutlineInformationCircle size="20"/>
                    </div>
                    <p className="font-semibold text-lg">Basic Information</p>
                </div>
                <hr/>
            </div>
            <div className="bg-white pl-2 pr-4">
            <div>
                <div className="flex flex-col">
                    <label className="pt-4">Course Title*</label>
                    <div className="flex pl-6 w-full border h-8 border-solid justify-between">
                        <input type="dropdown" name="title" id="title"
                        placeholder="Course title here" className="w-11/12 border border-white focus:border-white focus:outline-none text-sm"></input>
                        <div className="flex border justify-center items-center text-center w-9 text-white font-semibold bg-danger">60</div>
                    </div>
                    <p className="font-light text-sm">(Please make this a maximum of 100 characters and unique)</p>
                </div>
                <TextArea
                label={"Short Description*"}
                name={"shortdes"}
                placeholder={'Item description here...'}/>
                <p className="font-light text-sm">220 words</p>
                <TextArea
                label={"Course Description*"}
                name={"coursedesc"}
                placeholder={"Insert your course description"} />
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/2">
                        <TextArea
                        label={"What will students learn in your course?*"}
                        name={"learnwhat"}
                        placeholder={""} />
                        <p className="font-light text-sm">Student will gain this skills, knowledge after completing this course. (One per line).</p>
                    </div>
                    <div className="md:w-1/2">
                        <TextArea
                        label={"Requirements*"}
                        name={"requirements"}
                        placeholder={""} />
                        <p className="font-light text-sm">What knowledge, technology, tools required by users to start this course. (One per line).</p>
                    </div>
                </div>

                <div className="pt-6 pb-6">
              <div className="flex flex-col md:flex-row gap-6">
                <div className="flex flex-col w-full">
                  <p>Course Level*</p>
                  <Select
                    options={CourseLevel}
                    value={selectedCourseLevel}
                    isMulti
                    onChange={handleCourseLevelChange}
                    className="w-full h-10 cursor-pointer"
                    classNamePrefix="react-select"
                    placeholder="Select an option"
                    styles={customStyles}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <p>Audio Language*</p>
                  <Select
                    options={Language}
                    value={selectedAudioLanguage}
                    isMulti
                    onChange={handleAudioLanguageChange}
                    className="w-full h-10 cursor-pointer"
                    classNamePrefix="react-select"
                    placeholder="Select an option"
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-6 pt-8">
                <div className="flex flex-col w-full">
                  <p>Close Caption*</p>
                  <Select
                    options={Language}
                    value={selectedCloseCaption}
                    isMulti
                    onChange={handleCloseCaptionChange}
                    className="w-full h-10 cursor-pointer"
                    classNamePrefix="react-select"
                    placeholder="Select an option"
                    styles={customStyles}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <p>Course Category*</p>
                  <Select
                    options={formattedOptions}
                    value={selectedCourseCategory}
                    onChange={handleCourseCategoryChange}
                    className="w-full h-10 cursor-pointer bg-white text-black"
                    classNamePrefix="react-select"
                    placeholder="Select an option"
                    styles={specificCustomStyles}
                  />

                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}