export const FormatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

 export const isMobileView = () => {
    return window.innerWidth < 768; // You can adjust this breakpoint as needed
  };
  export const isTabletview = () => {
    return window.innerWidth >= 768 && window.innerWidth < 1024;
  };
  export const isLargeScreen = () => {
    return window.innerWidth >= 1024;
  }