import React from "react";
import CodeIcon from '@mui/icons-material/Code';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import MonitorIcon from '@mui/icons-material/Monitor';
import StraightenIcon from '@mui/icons-material/Straighten';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

function TopCategory({tog}) {
    const category = {
        'Development': CodeIcon,
        'Business': EqualizerIcon,
        'IT and Software': MonitorIcon,
        'Design': StraightenIcon,
        'Marketing': ShowChartIcon,
        'Personal Development': ImportContactsIcon,
        'Photography': CameraAltIcon,
        'Music': MusicNoteIcon,
      };

  return (
    <>
    <div class={`bg-white flex flex-col gap-3 rounded-sm shadow p-4 transition-all duration-500 transform w-full lg:${tog ? "w-64":"w-72"} my-8`}>
        <p>Top Categories</p>
        <div class="border-t-2 border-stone-300"></div>
      {Object.entries(category).map(([key, IconComponent]) => (
        <div 
        class="p-2 flex gap-4 text-stone-500 hover:cursor-pointer hover:text-stone-700"
        key={key}
        >
          <IconComponent />
          {key}
        </div>
      ))}
      </div>
    </>
  );
}

export default TopCategory;
