import React from 'react'
import logo from '../../assets/logo.svg'
import sign_logo from '../../assets/sign_logo.png'
import { Link } from 'react-router-dom';
import {AiOutlineMail} from 'react-icons/ai'
function ForgotPassword() {
  return (
    <div className="  bg-stars  bg-[#f7f7f7] flex justify-center items-center" >
        <div className="container w-5/6">
      <div className="flex items-center justify-center min-h-screen flex-col">
          <div className="flex justify-center  h-9" id="logo">
            
              <img className="text-center  items-center justify-center  w-[135px]"src={logo} alt="" />
          </div>
      <div className='  flex justify-center items-center '>
      <div className="justify-center bg-white mt-10 py-10 z-9 gap-12  flex  flex-col items-center ">
          <div >  <h2 className="text-xl md:text-3xl font-semibold font-serif  text-gray-700   text-center  ">Request a Password Reset</h2> </div>
            
          <div>
          <form className="flex flex-col items-center justify-center gap-9 px-10" >
            <div className="relative items-center mt-4 text-base font-normal font-roboto">
            <div className=" flex   gap-1 items-center justify-center border pl-3 rounded focus:outline-none  focus:border-black transition duration-150 ease-in-out">
                <AiOutlineMail/>
                <input
                 
                    className="ml-4 pr-16 md:pr-64 h-10 md:h-12 font-roboto w-full flex-1 py-2 px-4 md:py-3 md:px-4 text-base font-normal font-sans bg-white   rounded focus:outline-none  focus:border-black transition duration-150 ease-in-out"
                    type="email"
                    name="emailaddress"
                    value=""
                    id="id_email"
                    required
                    maxlength="64"
                    placeholder="Email Address"
                  />
                </div>
                </div>
              <button className="    w-full   md:font-serif font-medium li text-base font-serif text-white rounded-md  text-center h-10 bg-red-600" type="submit">
                Reset Password
              </button>
              
           <div>
           <p className="font-serif text-center">
              Go Back <Link className="  text-blue-500" to= '/signup'>SignIn</Link>
            </p>
           </div>
          
      </form>
          </div>
      </div>
      
      </div>
      <div className="text-base md:text-lg font-normal text-center font-roboto text-gray-700 py-8 md:py-12 block w-full">
            <img className="pl-5 md:pl-8 inline" src={sign_logo} alt="Sign Logo" /> © 2023 <strong className="font-semibold">Cursus</strong>.
            All Rights Reserved.
          </div>
      </div>
      </div>
      </div>    
  )
}

export default ForgotPassword;