import React, { useState } from 'react';
import Tabel from './Tabel';
import { FaTrash } from "react-icons/fa";

const Discount = () => {
  const [showForm, setShowForm] = useState(false);

  const handleToggleForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };
  const data2 =[
    ['John Doe', 30, 'Engineer', 'Male', 'USA', 'Single', ],
    ['Jane Smith', 28, 'Designer', 'Female', 'Canada', 'Married', ],
    ['Jane Smith', 28, 'Designer', 'Female', 'Canada', 'Married', ]
    
    
  ];


const header2 = ['Name', 'Age', 'Occupation', 'Gender', 'Country', 'Marital Status'];
const actionIcons = [
 
  {
    icon: <FaTrash />,
    onClick: (rowIndex) => {
      // Handle delete action for the row at the given index
      console.log("Delete row:", rowIndex);
    },
  },
];

  return (
    <div>
      <div className='ml-3 bg-white flex justify-between items-center border-[1px] border-solid p-2 border-[#efefef]'>
        <div>New Discount</div>
        <button className={`text-2xl transition-transform ${
            showForm ? 'rotate-45' : ''
          }`}onClick={handleToggleForm}>
        {/* {showForm ? '-' : '+'} */} +
        </button>
      </div>
      {showForm && (
        <div className={`${
          showForm ? 'block' : 'hidden'
        } ml-3 mb-5 gap-10 bg-white p-5 border-1 h-[350px] border-gray-200 flex-col transform origin-top transition-all duration-1000 ${
          showForm ? 'scale-y-100' : 'scale-y-0'
        }`}>
          <div className="flex gap-10">
            <div className="flex flex-col w-[300px]">
              <div className="flex flex-col w-[100%]">
                <label className="py-4">Course Title*</label>
                <div className="flex w-full border h-10 border-solid">
                  <select
                    value={"title"}
                    className="w-full border-solid focus:border-solid focus:outline-none text-sm h-10 bg-transparent text-gray-500 text-center"
                  >
                    <option value="">-- Select an option --</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col w-[100%]">
                <label className="py-7">Course Title*</label>
                <div className="flex w-full border h-10 border-solid justify-between">
                  <select
                    value={"title"}
                    className="w-full border-solid focus:border-solid focus:outline-none text-sm h-10 bg-transparent text-gray-500 text-center "
                  >
                    <option value="" className="">
                      Select an option
                    </option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
                <button
                  className='text-1xl bg-danger py-3 text-white collapseicon w-full ml-10 mt-[70px] rounded-3xl '
                >
                  Save Changes
                </button>
              </div>
            </div>
            <div className="flex flex-col h-[600px] w-[300px]">
              <div className="flex flex-col w-[100%]">
                <label className="py-4">Course Title*</label>
                <div className="flex w-full border h-10 border-solid justify-between">
                  <select
                    value={"title"}
                    className="w-full border-solid focus:border-solid focus:outline-none text-sm h-10 bg-transparent text-gray-500 text-center "
                  >
                    <option value="">-- Select an option --</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-col w-[100%]">
                <label className="py-7">Course Title*</label>
                <div className="flex w-full border h-10 border-solid justify-between">
                  <select
                    value={"title"}
                    className="w-full border-solid focus:border-solid focus:outline-none text-sm h-10 bg-transparent text-gray-500 text-center"
                  >
                    <option value="">-- Select an option --</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
      <Tabel data={data2} header={header2} actionIcons={actionIcons} />
      </div>
    </div>
  );
};

export default Discount;
