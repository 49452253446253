import React from "react";

function BecomeInstructor({tog}) {
    return(
        <>
        <div
    className={`bg-white shadow p-4
    text-center flex flex-col justify-center
    items-center my-8 gap-2 transition-all duration-500 transform w-full lg:${tog ? 'w-64':'w-72'}`}>
            <div className={` w-full lg:${tog ? "w-64":"w-72"} flex flex-col gap-2`}>
                <p className="text-lg font-medium">Become an Instructor</p>
                <p className="text-stone-700">Top instructors from around the world teach millions of students on Cursus. We provide the tools and skills to teach what you love.</p>
            </div>
            <div className="text-stone-100 bg-redbutton rounded-sm w-28 h-8 flex justify-center items-center font-medium hover:cursor-pointer hover:bg-red-700">Start Teaching</div>
        </div>
        </>
    )
}

export default BecomeInstructor;