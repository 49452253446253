import React from 'react';
import '../App.css';
import Slider from '../Model/Slider';
import CoursesCard from './CoursesCard';
import { Courses } from '../tempFiles/tempFiles';

const FeaturedCourses = () => {
  // Assuming you have a separate array for the featured courses
  const featuredCourses = Courses.slice(0, 8); // Extract the first two courses for the slider

  return (
    <div className="w-full">
        <div className=' px-6 flex justify-between' >
          <p className='text-1xl'>Featured Courses</p>
          <p className='text-stone-300 text-base hover:text-stone-600 hover:cursor-pointer'>See all</p>
        </div>
      <Slider classProp={["translate-x-6", "-translate-x-5"]}>
        <div
          id="courseSlider"
          className='flex gap-4 scroll whitespace-nowrap scroll-smooth scrollbar-hide'
        >
          {/* Render only the two featured courses */}
          {featuredCourses.map((course) => (
            <CoursesCard key={course.id} course={course} customClassName="w-[300px] md:w-[345px]" />
          ))}
        </div>
      </Slider>
    </div>
  );
};

export default FeaturedCourses;
