import React, { useContext } from "react";
import NavSideBarComb from "./navSidebarComb";
import UserCard from "./UserCard";
import { TextInput } from "./Settings/inputfield";
import { BiSearch } from "react-icons/bi";
import CoursesCard from "./CoursesCard";
import { Courses } from "../tempFiles/tempFiles";
import AppContext from "../Context/AppContext";

const Help = () => {
  const { tog } = useContext(AppContext);
  return (
    <NavSideBarComb>
      <div className=" pt-[60px] w-full">
        <div className=" bg-[#333] py-[80px] w-full">
          <div class="flex flex-col items-center justify-center">
            <h2 className=" text-[30px] font-[600] mb-[30px] text-white">How may we help you?</h2>
            <div class="explore_search">
              <div class="ui search focus">
                <div class="ui left icon input swdh11">
                  <input
                    class="prompt srch_explore"
                    type="text"
                    placeholder="Search for solutions"
                  />
                  <i class="uil uil-search-alt icon icon2"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavSideBarComb>
  );
};

export default Help;
