import React from "react"
import { Link } from 'react-router-dom';
import '../../index.css';
import logo from '../../assets/logo.svg';
const profile = require('../../assets/hd_dp.jpg');

function CirtificateHeader() {
    return (
        <>
            <div className="p-2 flex justify-around fixed top-0 left-0 z-50 w-full h-16 bg-white items-center shadow-sm">
                <Link to="/">
                    <div className=" flex justify-center
                border-solid border w-36 h-8 text-center items-center
                border-slate-300 align-middle rounded-full
                hover:bg-stone-800 hover:text-white hover:cursor:pointer">Back To Curcus
                    </div>
                </Link>
                <div className=" rounded-sm"><Link to='/' ><img className="img-responsive w-36" src={logo} alt="Logo" /></Link></div>
                <img src={profile} className="rounded-full w-9 flex radius-100" alt="Personal Profile" />
            </div>
        </>
    )
}
export default CirtificateHeader;