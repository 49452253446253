import React, { useContext } from "react";
import Navbar from "./Navbar"
import LeftSidebar from "./LeftSidebar"
import Footer from "./Footer"
import { useLocation } from "react-router-dom";
import Testimonials from "./Testimonials";
import AppContext from "../Context/AppContext";
export default function NavSideBarComb({ children }) {
  const { tog, setTog } = useContext(AppContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const handleToggle = (newTog) => {
    setTog(newTog);
  };

  return (
    <div className="bg-stone-100">
      <div className="flex flex-col lg:flex-row bg-stone-100 pb-2">
        <div className="mb-12">
          <Navbar handleToggle={handleToggle} />
        </div>
        <div className="flex flex-col lg:flex-row w-full transition-all duration-500">
          <div
            className={`${
              tog ? "w-[240px]" : "w-0"
            } mt-1 lg:mt-12 lg:pt-3 transition-all duration-500`}
          >
            <LeftSidebar tog={tog} />
          </div>
          <div
            className={`flex flex-col lg:flex-row transition-all duration-500 transform
               w-full lg:${
                 tog
                   ? `w-4/5 ${
                       (currentPath === "/" || currentPath === "/explore") &&
                       "lg:pl-60"
                     }`
                   : "w-full"
               }`}
          >
            {children}
          </div>
        </div>
      </div>
      {currentPath === "/" && (
        <div
          className={`relative transition-all duration-500 transform ${
            tog && "lg:pl-64"
          }`}
        >
          <Testimonials />
        </div>
      )}
      <div
        className={`relative z-0 transition-all duration-500 transform ${
          tog && "lg:pl-60"
        }`}
      >
        {" "}
        <Footer />
      </div>
    </div>
  );
}
