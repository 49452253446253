import React from "react";
import MyInstructorProfile from "./MyInstructorProfile";
const AboutInstructor = () => {
  return (
    <>
      <div className=" max-h-96">
        <h3 className="text-lg font-medium font-roboto text-left mb-4 text-gray-700">
          About Me
        </h3>
        <p className="text-base font-roboto font-normal text-gray-600 leading-6 text-left my-4">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            scelerisque nibh sed ligula blandit, quis faucibus lorem
            pellentesque. Suspendisse pulvinar dictum pellentesque. Vestibulum
            at sagittis lectus, sit amet aliquam turpis. In quis elit tempus,
            semper justo vitae, lacinia massa. Etiam sagittis quam quis
            fermentum lacinia. Curabitur blandit sapien et risus congue viverra.
            Mauris auctor risus sit amet cursus sollicitudin. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Nulla feugiat sodales massa,
            in viverra dolor condimentum ut. In imperdiet, justo nec volutpat
            blandit, tellus justo tempor quam, sed pretium nibh nunc nec mauris.
            Mauris vel malesuada magna. Quisque iaculis molestie purus, non
            luctus mauris porta id. Maecenas imperdiet tincidunt mauris
            vestibulum vulputate. Aenean sollicitudin pretium nibh, et sagittis
            risus tincidunt ac. Phasellus scelerisque rhoncus massa, ac euismod
            massa pharetra non. Phasellus dignissim, urna in iaculis varius,
            turpis libero mollis velit, sit amet euismod arcu mi ac nibh.
            Praesent tincidunt eros at ligula pellentesque elementum. Fusce
            condimentum enim a tellus egestas, sit amet rutrum elit gravida.
            Pellentesque in porta sapien. Fusce tristique maximus ipsum et
            mollis. Sed at massa ac est dapibus vulputate at eu nibh.
          </p>
        </p>
      </div>
    </>
  );
};

export default AboutInstructor;
