import NavSideBarComb from "../navSidebarComb";
import AppContext from "../../Context/AppContext";
//import HorizontalSlider from '../HorizontalSlider';
import { useContext } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdOutlineFeedback  } from "react-icons/md";
export default function SendFeedBack() {
  const {tog} = useContext(AppContext);

  return (
    <NavSideBarComb>
      <div
          className={`transition-all duration-500 transform flex flex-col flex-wrap  ${
            tog ? "w-11/12" : "w-11/12 ml-3"
          } mt-5 lg:mt-12 lg:pt-16 ml-2`}
        >
          <div className="px-11">
            <div className=" w-full  pb-4 flex gap-3 justify-start items-center">
          <MdOutlineFeedback size={25}/>
            <h1 className=" md:font-serif text-slate-900  text-xl "> Send Feedback</h1>
            </div>
          <div className=" pb-4">
          <input
                    className=" border  w-[55%] h-14 focus:outline-none  focus:border-black "
                    type="email"
                    name="emailaddress"
                    defaultValue=""
                    id="id_email"
                    required=""
                    maxLength={64}
                    placeholder="Email Address"
                    

                  />
                  
            </div>
            <div className="">
                          <textarea
                            rows={3} 
                            name="description"
                            id="id_about"
                            placeholder="Describe your issue or share your ideas"
                            defaultValue={""}
                            style={{ minWidth: '544px', height: '130px' }}

                          />
                        </div>
           <p className=" md:font-serif text-slate-900">Add ScreenShots</p>
            <div className="flex flex-col relative border border-dashed justify-center items-center 
            text-center md:w-1/3 h-36 bg-white ">
            <label
              htmlFor="uploadimage"
              className=" text-gray-900 py-2 px-3 rounded-md bg-white cursor-pointer flex flex-col items-center"
            >
                           <FaCloudUploadAlt size={30}/>
                            <h4 className="select md:font-serif text- text-gray-900 font-bold">Select screenshots to upload</h4>
                            <p>or drag and drop screenshots</p>
            </label>
            <input
              id="uploadimage" 
              type="file" 
              accept="image/*"
              className="opacity-0 absolute inset-0 z-[-1]"
            />
            
        </div>
           <div className="">
             <button className=" bg-red-600 w-44  text-white md:font-serif font-medium li text-base" type="submit">
                  Send FeedBack
                </button>
           </div>
          
          </div>
        </div>
      
    </NavSideBarComb>
  );
}
