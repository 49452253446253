import React from "react";
import Livestream from "./liveStream";
import Profile from "./profile";
import Recommendation from "./recommendation";
import TopCategory from "./topCategories";
import BecomeInstructor from "./becomeInsturctor";

export default function Combinaton({tog}) {
    return(
        <div className="pt-4 w-full transition-all duration-500 transform">
        <Profile tog={tog}/>
        <Livestream tog={tog}/>
        <Recommendation tog={tog}/>
        <TopCategory tog={tog}/>
        <BecomeInstructor tog={tog}/>
        </div>
    )
}