import React from "react";
export default function CommonClickables({lists, icon, state, stateChange, white, dynamicClass}) {
    return(
    lists.map( (item, index) => (
            <div 
            onClick={()=> {stateChange(index)}}
            className={`flex gap-1 items-center font-medium ${dynamicClass} hover:cursor-pointer pl-3 pr-3 items-center
            rounded-sm h-9
            ${ state === index ? "bg-red-500 text-white": `${white ? "bg-white":"bg-stone-100"} text-black`}
            `}
            key={index}>
                <span>{icon && icon[index] }</span>
                <span>{item}</span>
            </div>
        ))
    )
}