import RepeatedHeader from "../pages/About/RepeatedHeader";
import Footer from "../components/Footer";
import { RiSearchLine  } from "react-icons/ri";
import CoursesCard from "../components/CoursesCard";
import { Courses } from "../tempFiles/tempFiles";
import { useState, useCallback } from 'react'




const Category = ({ state, lists, category})=>{
  const [ dropRadio, setDropRadio ] = useState(null);

  const changeRadio = useCallback((index) => {
      setDropRadio(index)
  },[])
  return(
  lists.map((list, index) => (
      <div
      onClick={() => changeRadio(index)}
      className='flex gap-3 items-center w-full '>
          <div className={`flex justify-center items-center w-4 h-4 border rounded-full ${index === dropRadio ? "border-red-500":"border-slate-500"}`}>
              <div className={`${index === dropRadio && "bg-red-500"} w-2 h-2 rounded-full`}></div>
          </div>
          <label className="">{list}</label>
      </div>
      ))
  )
}

const SearchResults = ({tog}) => {
  let CategoryList = {
    "Topic": ["WordPress", "HTML CSS", "Full Time"],
    "Level":["Accouting", "Finance Fundamentals"],
    "Language": ["Adobe Photoshop", "Adobe Illustrator", "Adobe After Effects", "Adobe InDesign"],
    "Price": ["Google Ads (Adwords)", "Google Ads (AdWords) Certification", "Social Marketing"],
    "Featues": ["Math", "Humanities", "Engineering", "Science"],
    "Ratings": ["Math", "Humanities", "Engineering", "Science"],
    "Video-Duration": ["Math", "Humanities", "Engineering", "Science"],
    "Close Caption": ["Math", "Humanities", "Engineering", "Science"],
};
const [ dropDown, setDropDown ] = useState(null)
const handleCategoryClick = (cat) => {
    setDropDown(cat === dropDown ? null : cat)
}
console.log(dropDown);
CategoryList = Object.entries(CategoryList);
  return (
    <>
      <RepeatedHeader />
     
      <div className=" flex flex-col gap-4 md:gap-0 md:flex-row  justify-between  bg-white pt-[80px] mx-20">
        <div className=" ">
          Home <span>/Search Results</span>
        </div>
       
        <div className="relative">
                  <input
                    type="text"
                    placeholder="Search"
                    className="px-8 py-2 text-[12px] font-[400] border w-52 focus:outline-none focus:text-[#333333]"
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 py-2">
                    {/* Search icon from react-icons */}
                    <RiSearchLine size={13} className=" text-gray-400" />
                  </div>
                </div>
      </div>
      <div className=" mx-20 mt-20 mb-5">
          <h1>Search Results</h1>
        </div>
       <div className=" bg-[#efefef] w-full   grid grid-cols-1 md:grid-cols-6  gap-8 px-12 md:px-20">
           <div className=" md:col-span-2">
           <div className="flex w-full flex-col self-start gap-4">
                    <div className=" flex w-full justify-between pt-6">
                      <div className=" text-1xl font-medium">Filters</div>
                      <div>Sort</div>
                    </div>
                    <hr />
                    <div className='flex flex-col w-full gap-4'>
                                {CategoryList.map(([category, values], index) => (
                                    <>
                                    <div
                                    key={category}
                                    onClick={() => handleCategoryClick(category)}
                                    className='flex justify-between font-medium hover:cursor-pointer'>
                                        <p>{category}</p>
                                        <p className='font-medium text-lg'>{category === dropDown ? "-":"+"}</p>
                                    </div>
                                    {category === dropDown && (
                                    <div
                                    className=' '>
                                         <Category className='' state={dropDown} lists={values} />
                                    </div>)}
                                    <hr />
                                    </>
                                ))}
                       
                    </div>
                </div>
           </div>
           <div className=" md:col-span-4 ">
           <div className="w-full">
                            <h4 className="font-medium text-1xl pt-6">7 results</h4>
                            <div className="flex flex-col gap-2">
                            {Courses.map((course) => (
                                <CoursesCard key={course.id} course={course} customClassName="md:flex ml-0 lg:flex xl:flex w-full gap-2" />
                            ))}
                            </div>
                        </div>
           </div>
       </div>
      

     
     
      <Footer />
    </>
  )
}

export default SearchResults