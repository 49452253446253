import React from "react";

const Courses = () => {
  return (
    <>
      <div className=" w-full max-h-full">
        <div className=" mb-20">
          <h3 className="text-base font-medium font-roboto text-left mb-4 text-gray-700 float-left">
            My courses (8)
          </h3>
        </div>
        <div className=" px-4 mx-auto">
          <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 ">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1">
              <div className=" max-w-min  mt-8  p-2 bg-white mr-2">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-1.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 109k views </span>
                    <span>15 days ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      Complete Python Bootcamp: Go from zero to hero in Python 3
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Web Development | Python
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $10
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-2.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 5M views </span>
                    <span>15 days ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      The Complete JavaScript Course 2023: Build Real Projects!
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Development | JavaScript
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $5
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-20.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 200 Views </span>
                    <span>4 days ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      WordPress Development - Themes, Plugins &amp; Gutenberg
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Design | Wordpress
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $14
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-4.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 153k views </span>
                    <span>4 days ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      The Complete Digital Marketing Course - 12 Courses in 1
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Digital Marketing | Marketing
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $12
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-13.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 20 Views </span>
                    <span>1 day ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      The Complete Node.js Developer Course (3rd Edition)
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Development | Node.js
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $3
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-7.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 109k views </span>
                    <span>15 day ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      WordPress for Beginners: Create a Website Step by Step
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Design | Wordpress
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $18
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-8.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 196k views </span>
                    <span>1 month ago</span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      CSS - The Complete Guide 2023 (incl. Flexbox, Grid &amp;
                      Sass)
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Design | CSS
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $10
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" max-w-min  mt-8  p-2 bg-white">
                <a href="#">
                  <div className=" w-48">
                    <img
                      src="https://lms.dalloltech.com/images/courses/img-16.jpg"
                      alt="pic"
                    />
                  </div>
                </a>
                <div className=" w-full ">
                  <div className="mt-1 font-normal font-roboto text-gray-600 text-left block">
                    <span> 11 Views </span>
                    <span>5 Days </span>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-semibold font-roboto text-left text-gray-700 mb-2 leading-6 "
                      href="#"
                    >
                      Vue JS 2 - The Complete Guide (incl. Vue Router &amp;
                      Vuex)
                    </a>
                  </div>
                  <div className=" mt-5">
                    <a
                      className="font-normal font-roboto text-left text-gray-600 pt-1"
                      href="#"
                    >
                      Development | Vue JS
                    </a>
                  </div>
                  <div className=" mt-4 flex justify-between">
                    <div>
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        By John Doe
                      </p>
                    </div>
                    <div className="">
                      <p className="mb-0 mt-3 text-sm font-roboto text-left text-gray-600 leading-6">
                        $10
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
