import React from "react";
import ProfileComponent from "./profileCommon";
import "tailwindcss/tailwind.css";
import { user } from "./tempFiles";
import { Link } from "react-router-dom";
export default function Profile({ tog }) {
  return (
    <div
      className={`bg-white flex flex-col text-center w-full lg:${
        tog ? "w-64" : "w-72"
      } gap-2 pt-4 pb-4 justify-center items-center transition-all duration-500`}
    >
      <ProfileComponent tog={tog} user={user} />
      <Link to="/my_instructor_profile_view">
        <p class="font-medium hover:text-red-400 hover:cursor-pointer">
          Go To Profile
        </p>
      </Link>
    </div>
  );
}
