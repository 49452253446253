export const CourseCategoryData = [
    {
        label: "Development",
        options: ["Web Development", "Data Science"]
    },
    {
        label: "Business",
        options: ["Finance", "Entrepreneurship", "Communications"]
    },
    {
        label: "Finance & Accounting",
        options: ["Accounting & Bookkeeping", "Compliance"]
    }
];

export const CourseLevel = [
    {value: "Beginner", label: "Beginner"},
    {value: "Intermediate", label: "Intermediate"},
    {value: "Expert", label: "Expert"}
  ]
export const Language = [
    {value: "Amharic", label: "Amharic"},
    {value: "Arabic", label: "Arabic"},
    {value: "English", label: "English"},
    {value: "French", label: "French"},
    {value: "German", label: "German"},
    {value: "Guragigna", label: "Guragigna"},
    {value: "Oromifa", label: "Oromifa"},
    {value: "Russian", label: "Russian"},
    {value: "Tigrigna", label: "Tigrigna"},
  ]