import CirtificateHeader from "./cirtificateHeader"
import { Link } from "react-router-dom"
import { BiChevronsLeft, BiCheck } from "react-icons/bi"
import Button from '../Button'
import Footer from "../Footer"
export default function TestResult() {
    return (
        <>
        <CirtificateHeader/>
        <div className='px-4 lg:px-10 py-4'>
            <div className="flex  flex-col sm:flex-row gap-4 sm:gap-1 justify-between">
                <div className="flex gap-1">
                    <Link to='/'><p>Home</p></Link>
                    <Link to='/certification_center'><p>/ Certificate Center</p></Link>
                    <p className='text-stone-500'>/ Result</p>
                </div>
                <Link to="/certification_center">
                    <div className='flex items-center'><BiChevronsLeft />Back to Certificate Center</div>
                </Link>
            </div>
                <h2 className='text-2xl font-medium'>Result</h2>
        </div>
        <div className="flex flex-col gap-10 py-10 justify-center items-center">
            <div className="flex gap-10 items-center text-center">
                <div className="flex flex-col gap-2">
                    <div className="flex justify-center items-center gap-2 rounded-full w-20 md:w-24 h-20 md:h-24 bg-green-600">
                        <BiCheck color="white" size="40"/>
                    </div>
                    <span>Right(15)</span>
                </div>
                <div className="flex flex-col gap-2">
                    <span className="flex justify-center items-center gap-2 bg-red-500 rounded-full w-20 md:w-24 h-20 md:h-24 text-white text-3xl">X</span>
                    <span>Wrong(5)</span>
                </div>
                <div className="flex flex-col gap-2">
                    <span className="flex justify-center items-center gap-2 bg-[rgb(82,169,212)] rounded-full w-20 md:w-24 h-20 md:h-24 text-white text-3xl">15</span>
                    <span>Out of 20</span>
                </div>
            </div>
            <h2 className="text-4xl font-bold flex text-center">Congratulation! Joginder</h2>
            <p className="text-stone-500 text-xl">You are eligible for this certificate.</p>
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
                <Button>Download Certificate</Button>
            </div>

        </div>
        <Footer /> 
        </>
    )
}