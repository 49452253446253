import React from 'react'
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineAppstore, AiOutlineShoppingCart } from "react-icons/ai";
import { BiSearchAlt2, BiBell } from "react-icons/bi";
import { CiMail } from "react-icons/ci";
import Logo from "../../assets/logo.png";
import Avatar from "../../assets/avatar.png";
import { Link } from 'react-router-dom';


const DashNavbar = ({ isMenuOpen, onMenuToggle }) => {
  return (
    <div className=" flex h-[60px] justify-between bg-white w-full shadow-md fixed top-0 left-0 z-10">
      <div className=" flex gap-4 items-center ">
        <div className=" bg-red-500 p-5 text-white " onClick={onMenuToggle}>
          <RxHamburgerMenu size={20} />
        </div>
        <div className="">
          <Link to='/'><img className=" w-[100px] h-8 object-contain" src={Logo} alt="" /></Link>
        </div>
        <div className=" hidden sm:hidden lg:inline-block">
          <AiOutlineAppstore size={29} />
        </div>
        <div className="hidden  lg:flex text-gray-400 items-center gap-2">
          <BiSearchAlt2 />

          <input
            type="text"
            class="rounded border bg-gray-100 text-gray-800 w-64 h-6 text-sm font-light px-21 font-sans shadow-sm"
            placeholder="search tutors video tutors book and more"
          />
        </div>
      </div>

      <div className=" flex items-center mr-10 gap-4">
        <div className=" hidden  bg-red-500 sm:inline-block border-[1px] solid rounded-md px-5 py-2 text-white">
          Create New Course
        </div>
        <ul className=" flex gap-3 text-gray-600 ">
          <li className="hidden sm:inline-block">
            <AiOutlineShoppingCart size={20} />
          </li>
          <li>
            <CiMail size={20} />
          </li>
          <li>
            <BiBell size={20} />
          </li>
        </ul>
        <div className=" ">
          <img src={Avatar} className=" w-[40px] h-10 object-contain" alt="" />
        </div>
      </div>
    </div>
  )
}

export default DashNavbar
