import { useState } from "react";
import { Link } from 'react-router-dom';
import {   AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF} from 'react-icons/fa';
import {FaGoogle} from 'react-icons/fa';
import {AiOutlineMail} from 'react-icons/ai'
import {  FiKey} from "react-icons/fi";
import logo from '../../assets/logo.svg'
import sign_logo from '../../assets/sign_logo.png'

function SignIn() {
  const [email, setEmail] = useState('');
 const [password, setPassword] = useState('');


 const handleSubmit = (e) => {
  e.preventDefault();
  console.log(email);}
  console.log( password);
    
  return (
    <div className="bg-stars   w-full bg-[#f7f7f7] flex items-center justify-center relative bg-cover bg-transparent">
        <div className="container w-5/6">
      <div className="flex items-center justify-center min-h-screen flex-col">
        <div className="flex justify-center  h-9" id="logo">
          
          <img className="text-center  items-center justify-center  w-[135px]" src={logo} alt="" />
      </div>
        <div className=' max-w-max flex justify-center flex-col w-full  '>
      <div className="bg-white rounded-md border border-gray-300 p-4 md:p-8  flex-1 w-full text-center ">
            <h2 className="text-xl md:text-3xl font-semibold font-serif text-gray-700 mb-4 md:mb-8 py-4  text-center">Welcome Back</h2> 
            <p className="text-base md:text-lg  text-center leading-6 md:leading-7 text-gray-600 md:py-4  ">Log In to Your Edututs+ Account!</p>
            <form className="flex flex-col items-center justify-center" >
              <div className="relative items-center mt-4 text-base font-normal font-roboto flex flex-col gap-4  ">
            <button className="py-2 text-white font-serif font-normal text-center border px-5 w-[100%] flex justify-center items-center gap-2  bg-blue-900">
              <FaFacebookF />
              Continue with Facebook
            </button>
            <button className="py-2 text-white font-serif font-normal text-center border w-[100%] flex justify-center items-center gap-2  mt-15 bg-blue-400">
            <AiOutlineTwitter/>
              Continue with Twitter
            </button>
            <button className=" py-2 text-white font-serif font-normal text-center border w-[100%] flex justify-center items-center gap-2  mt-15 bg-green-600">
              <FaGoogle/>
             Continue with Google
            </button>
            <div className="justify-center items-center">
            <div className="relative items-center mt-4">
                <div className="text-base font-normal font-roboto">
                <div className=" flex gap-1 items-center justify-center border pl-3 rounded focus:outline-none  focus:border-black transition duration-150 ease-in-out">
                <AiOutlineMail/>
                <input
                 
                    className="pl-4 pr-16 md:pr-64 h-10 md:h-12 font-roboto w-full flex-1 py-2 px-4 md:py-3 md:px-4 text-base font-normal font-sans bg-white   rounded focus:outline-none  focus:border-black transition duration-150 ease-in-out"
                    type="email"
                    name="emailaddress"
                    value=""
                    id="id_email"
                    required
                    maxlength="64"
                    placeholder="Email Address"
                  />
                </div>
                  
                </div>
              </div>
              <div className="relative items-center mt-4">
                <div className="text-base font-normal font-roboto">
                  <div className="flex gap-1 items-center justify-center border pl-3">
                  < FiKey/>
                  <input
                    className="pl-4 pr-16 md:pr-64 h-10 md:h-12 font-roboto w-full flex-1 py-2 px-4 md:py-3 md:px-4 text-base font-normal font-sans bg-white   text-gray-800 rounded focus:outline-none  focus:border-black transition duration-150 ease-in-out"
                    type="password"
                    name="password"
                    value=""
                    id="id_password"
                    required
                    maxlength="64"
                    placeholder="Password"
                  />
                  </div>
                </div>
              </div>
              <div className="Check">
          <div className=" text-left">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
            <label className=" text-gray-600 " JsFor="customCheck1">
              Remember me
            </label>
          </div>
        </div>
              <div className="flex flex-col justify-center items-center gap-16 ">
              <button className=" text-sm mt-7 font-medium font-serif text-white rounded-md bg-red-600 w-full text-center h-7 md:h-12" type="submit">
                Sign In
              </button>

            <p className="font-serif text-center  text-gray-600 ">
              Or <Link className="text-red-600" to= '/forgotpassword'>Forgot Password</Link>.
            </p>

            <hr className=" w-full" />
            
            <p className="mb-0 mt-0 font-serif text-center text-gray-600">
            Don't have an account? <a className="text-red-600" href="/signUp">SignUp</a>
            </p>
              </div>
            </div>
            </div>
            </form>
          </div>
      
      
      
      </div>
      <div className="text-base md:text-lg font-normal text-center font-roboto text-gray-700 py-8 md:py-12 block w-full">
            <img className="pl-5 md:pl-8 inline" src={sign_logo} alt="Sign Logo" /> © 2023 <strong className="font-semibold">Cursus</strong>.
            All Rights Reserved.
          </div>
    </div>
    </div>
    </div>
  )
}

export default SignIn