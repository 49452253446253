import { TextArea } from "./TextArea"
const Source = ({uploadId, id}) => (
    <>
        {id === 1 &&
        <div className="flex flex-col gap-3">
            <label forHtml="externalurl">External URL*</label>
            <input 
            className="pl-4 h-8 border-white focus:border-white focus:outline-none"
            type="text" placeholder="External Video URL" id="externalurl" name="externalurl" />
        </div>
        }
        {id === 2 &&
        <div className="flex flex-col gap-3">
            <label forHtml="youtubeurl">YouTube URL*</label>
            <input 
            className="pl-4 h-8 border-white focus:border-white focus:outline-none"
            type="text" placeholder="Youtube Video URL" id="youtubeurl" name="youtubeurl" />
        </div>}
        {id === 3 &&
        <div className="flex flex-col gap-3">
            <label forHtml="vimeourl">Vimeo URL*</label>
            <input 
            className="pl-4 h-8 border-white focus:border-white focus:outline-none"
            type="text" placeholder="Vimeo Video URL" id="vimeourl" name="vimeourl" />
        </div>}
        {id === 4 &&
            <TextArea label='Embedded Code*' name="embeddedcode" placeholder="External Video UR" />
        }
        {id === 0 && <div className="flex flex-col relative border border-dashed justify-center items-center 
            text-center h-36 bg-white">
            <label
              htmlFor="uploadvideo"
              className="border border-red-500 text-red-600 py-2 px-3 rounded-md bg-white cursor-pointer"
            >
              UPLOAD VIDEO
            </label>
            <input
              type="file"
              id="uploadvideo"
              accept="video/*"
              className="opacity-0 absolute inset-0 z-[-1]"
            />
            <span className="text-stone-500 pt-2 text-sm">File Format: .mp4</span>
            {uploadId && <span className="pt-4 text-sm">Upload ID: <span className="font-medium">{uploadId}</span></span>}
        </div>}
    </>
)

export {Source}