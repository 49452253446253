/* eslint-disable jsx-a11y/alt-text */
import logo from '../../assets/logo.svg'
import sign_logo from '../../assets/sign_logo.png'
import { Link } from 'react-router-dom';
function SignUp() {
  return <div className=" inset-0 bg-cover flex justify-center bg-center bg-no-repeat bg-stars bg-opacity-5 " >
    <div className="container w-5/6">
      <div className="flex items-center justify-center min-h-screen flex-col">
        <div className="relative w-full px-5 md:px-15">
          <Link to="../">
            <div className="w-36 mx-auto text-center my-8 py-8 md:py-12">
              <a href="index.html">
                <img className="w-36 md:w-48 mx-auto" src={logo} alt="Logo" />
              </a>
            </div>
          </Link>
        </div>
        <div className="max-w-max flex justify-center flex-col w-full">
          <div className="bg-white rounded-md border border-gray-300 p-4 md:p-8 shadow-md flex-1 w-full text-center">
            <h2 className="text-2xl md:text-3xl font-semibold font-roboto text-gray-700 mb-4 md:mb-8">Welcome to Cursus</h2>
            <p className="text-base md:text-lg leading-6 md:leading-7 text-gray-600 py-4 md:py-10">Sign Up and Start Learning!</p>
            <form className="flex flex-col items-center">

              <div className="relative items-center mt-4">
                <div className="text-base font-normal font-roboto">
                  <input
                    className="pl-4 pr-16 md:pr-64 h-10 md:h-12 font-roboto w-full flex-1 py-2 px-4 md:py-3 md:px-4 text-base font-normal font-sans bg-white border border-gray-300 text-gray-800 rounded focus:outline-none focus:ring focus:border-blue-500 transition duration-150 ease-in-out"
                    type="text"
                    name="fullname"
                    value=""
                    id="id_fullname"
                    required
                    maxLength="64"
                    placeholder="Full Name"
                  />
                </div>
              </div>
              <div className="relative items-center mt-4">
                <div className="text-base font-normal font-roboto">
                  <input
                    className="pl-4 pr-16 md:pr-64 h-10 md:h-12 font-roboto w-full flex-1 py-2 px-4 md:py-3 md:px-4 text-base font-normal font-sans bg-white border border-gray-300 text-gray-800 rounded focus:outline-none focus:ring focus:border-blue-500 transition duration-150 ease-in-out"
                    type="email"
                    name="emailaddress"
                    value=""
                    id="id_email"
                    required
                    maxlength="64"
                    placeholder="Email Address"
                  />
                </div>
              </div><div className="relative items-center mt-4">
                <div className="text-base font-normal font-roboto">
                  <input
                    className="pl-4 pr-16 md:pr-64 h-10 md:h-12 font-roboto w-full flex-1 py-2 px-4 md:py-3 md:px-4 text-base font-normal font-sans bg-white border border-gray-300 text-gray-800 rounded focus:outline-none focus:ring focus:border-blue-500 transition duration-150 ease-in-out"
                    type="password"
                    name="password"
                    value=""
                    id="id_password"
                    required
                    maxlength="64"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="mt-7">
                <div className="inline field">
                  <div className="ui checkbox mncheck">
                    <label className="inline-flex items-center cursor-pointer">
                      <input type="checkbox" className="form-checkbox h-5 w-5 md:h-6 md:w-6 text-red-600 transition duration-150 ease-in-out" />
                      <span className="ml-2 text-gray-700">I'm in for emails with exciting discounts and personalized recommendations</span>
                    </label>
                  </div>
                </div>
              </div>
              <button className="text-base md:text-lg mt-6 md:mt-8 font-medium text-white bg-red-600 rounded-sm text-center w-full h-10 md:h-12 md:mb-5" type="submit">Next</button>
            </form>
            <p className="pt-8 pb-10 md:pt-12 md:pb-14 border-b border-gray-300">
              By signing up, you agree to our
              <a className="text-red-600" href="terms_of_use.html"> Terms of Use</a> and
              <a className="text-red-600" href="terms_of_use.html"> Privacy Policy</a>.
            </p>
            <p className="mb-0 mt-0">
              Already have an account? <a className="text-red-600" href="sign_in.html">Log In</a>
            </p>
          </div>
          <div className="text-base md:text-lg font-normal text-center font-roboto text-gray-700 py-8 md:py-12 block w-full">
            <img className="pl-5 md:pl-8 inline" src={sign_logo} alt="Sign Logo" /> © 2023 <strong className="font-semibold">Cursus</strong>.
            All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>

}

export default SignUp;

