import React from 'react';
import { useState } from 'react';
import Footer from '../Footer';
import 'tailwindcss/tailwind.css';
import Select from 'react-select';
import CirtificateHeader from './cirtificateHeader';
const playImg = require('../../assets/play.png');
const plusImg = require('../../assets/plus.png');
const certificate = require('../../assets/certificate.png');
const student = require('../../assets/student.png');
const instructor = require('../../assets/instructor.png');

export default function Certificate() {
    const [categories, setCategories] = useState(0);

    const handleCategryChange = (index) => {
        if (categories !== index) {
            setCategories(index);
        }
    }
    const categoryOptions = [
        { value: "development", label: "Development" },
        { value: "design", label: "Design" },
      ];
    const PopularCategories = 
    ['Development', 'Finance & Accounting',
    'Design', 'Marketing', 'Teaching & Academics']
    const subCategories = {
        'Development': [
            'WordPress', 'GTML CSS', 'MotoCMS 3', 'Joomla', 'OpenCart', 'Joomla Pro', 
            'WordPress Pro', 'WordPress Elementor', 'WordPress Elementor Pro', 'PrestaShop'
        ],
        'Finance & Accounting': ['Accounting', 'Finance Fundamentals', 'BookKeeping']
    }
    
    const stylePopularCategories = `${subCategories[PopularCategories[categories]].length > 3 ? 'w-1/5': ''}
    m-2 p-2 h-14 w-full
    border flex justify-center items-center
    hover:cursor-pointer font-medium
    text-stone-700 bg-white hover:bg-redbutton2
    hover:text-white`

    return (
        <>
            
                <CirtificateHeader />
                <div className='flex gap-8 text-white flex-col
                place-items-center
                bg-certificateBackground pb-16 pt-16'>
                    <h1 className="text-sixty flex text-center font-bold">Certification Center</h1>
                    <p className="text-2xl">For Students and Instructors</p>
                    <div className='flex justify-center items-center space-x-6 p-6 pb-12'>
                        <img className='h-20 lg:h-32' src={playImg} alt="" />
                        <img className="h-16 lg:h-20" src={plusImg} alt="" />
                        <img className='h-20 lg:h-32' src={certificate} alt="" />
                    </div>
                    <button className="text-stone-100 bg-redbutton2
                    rounded-sm w-64 h-10 hover:cursor-pointer">Start Certification</button>
                </div>
                <div className="pt-10">
                    <h2 className="text-2xl flex justify-center font-bold text-stone-800">Find Certificate</h2>
                    <div className='flex flex-col lg:flex-row px-2 lg:px-0 justify-evenly gap-4 lg:gap-0 pt-6 pb-16'>
                        <input type="text" className="indent-6 w-full border h-8 lg:w-56 focus:outline-stone-500 border-solid border-slate-200" placeholder="# Number"></input>
                        <input type="text" className="indent-6 w-full focus:outline-stone-500 border h-8 lg:w-56 border-solid border-slate-200" placeholder="Full Name"></input>
                        <Select
                              options={categoryOptions}
                              className="w-full lg:w-56 h-10 cursor-pointer focus:outline-stone-500"
                              classNamePrefix="react-select"
                              placeholder="Select Category"
                            />
                        <button className="text-stone-100 bg-redbutton2
                    rounded-sm w-44 h-10 hover:cursor-pointer hover:bg-stone-800">Find Certificate</button>
                    </div>
                </div>
                <div className='bg-gray-100'>
                <div className='pt-12 px-2 lg:px-0 pb-12 flex flex-col gap-6 place-items-center'>
                    <h2 className="text-2xl flex justify-center font-bold text-stone-800">Our Certification</h2>
                    <p className='text-stone-500 flex justify-center'>We prepared tests for the most popular categories and get cerificate</p>
                    <div className='flex flex-wrap justify-center gap-2 lg:gap-0 space-x-4'>
                        {PopularCategories.map((category, index) => (
                            <div
                            className={`
                              pl-3 pr-3 h-8 flex items-center hover:text-white
                              font-semibold hover:bg-redbutton2 rounded-md hover:cursor-pointer
                              ${categories === index && "bg-red-500 text-white"}
                            `}
                            key={index}
                            onClick={() => handleCategryChange(index)}>
                            {category}
                          </div>
                        ))}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 w-full flex-wrap px-2 lg:px-0 lg:pl-16 pb-10">
                        {subCategories[PopularCategories[categories]].map((subCategory, index) => (
                            <div
                            className={stylePopularCategories}
                            key={index}>
                                {subCategory}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-6 pt-14 pb-12
                text-white bg-certificateBackground">
                    <h2 className='flex font-semibold text-xl justify-center'>Who Can Get Benefit From This?</h2>
                    <div className="flex gap-32 justify-center font-semibold">
                        <div className="text-center">
                            <img className="w-onefifty pb-2" src={student} alt="Student icon" />
                            <h4>Students</h4>
                        </div>
                        <div className="text-center">
                            <img className="w-onefifty pb-2" src={instructor} alt="Instructor icon" />
                            <h4>Instructor</h4>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col text-center text-paragraph gap-8 pt-14 pb-12 pr-12 pl-12 place-items-center'>
                    <h2 className="text-2xl text-stone-800 font-bold">What Will You Get?</h2>
                    <p>Cursus company, which confirms your skills and knowledge of Certification</p>
                    <p>Morbi eget elit eget turpis varius mollis eget vel massa. Donec porttitor, sapien eget commodo vulputate, erat felis aliquam dolor, non condimentum libero dolor vel ipsum. Sed porttitor nisi eget nulla ullamcorper eleifend. Fusce tristique sapien nisi, vel feugiat neque luctus sit amet. Quisque consequat quis turpis in mattis. Maecenas eget mollis nisl. Cras porta dapibus est, quis malesuada ex iaculis at. Vestibulum egestas tortor in urna tempor, in fermentum lectus bibendum. In leo leo, bibendum at pharetra at, tincidunt in nulla. In vel malesuada nulla, sed tincidunt neque. Phasellus at massa vel sem aliquet sodales non in magna. Ut tempus ipsum sagittis neque cursus euismod. Vivamus luctus elementum tortor, ac aliquet dolor vehicula et. Nulla vehicula pharetra lacus ornare gravida. Vivamus mollis ullamcorper dui quis gravida. Aenean pulvinar pulvinar arcu a suscipit.</p>
                    <button className="text-stone-100 bg-redbutton2
                    rounded-sm w-64 h-10 hover:cursor-pointer hover:bg-stone-800">knowledge Base</button>
                </div>
            </div>
            <Footer />
        </>
    )
}
