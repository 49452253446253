import profile from '../assets/profilepic.jpg';

export default function UserCards({Users}) {
    return(
        <>
            {
                  Users.map((user)=> (
                    <div
                    key={user.name}
                    className='touch m-1 bg-third p-4 w-full md:w-1/5 flex flex-col items-center justify-center h-[170px] cursor-pointer hover:scale-200 ease-in-out duration-300'>
                      <div className='bg-fourth shadow-lg flex flex-col justify-center gap-1 text-center items-center w-full h-full'>
                        <span className='text-center items-center flex justify-center'>
                           <img src={profile} width={60} height={60} className=" float-center rounded-full"alt="" />
                        </span>
                       <span>{user.name}</span>
            
                       <span className='text-stone-600 text-sm hover:text-gray-400 duration-500 flex justify-center'>{user.action}
                       <ion-icon
                            name="ellipse"
                            
                            class={user.action==="live"?"live_user_actions":"offline_user_actions"}></ion-icon>
                       </span>
                       </div>
                    </div>
                  ))
                }
        </>
    )
}