import React, { useState } from "react";
import { FiSettings } from "react-icons/fi";
import CommonClickables from "../CreateCourse/commonClickables";
import Account from "./Account";
import NotificationSetting from "./notificationSetting";
import Privacy from "./Privacy";
import BillingPayout from "./Billing";
import ApiClients from "./APIclients";
import CloseAccount from "./CloseAccount";
import NavSideBarComb from "../navSidebarComb";
export default function Setting() {
    const [ settingType, setSettingType ] = useState(0);

    const settingTypeItems = [
        "Account", "Notification", "Privacy", "Billing and Payouts",
        "API Clients", "Close Account"
    ]
    const changeSetting = (index) => {
        setSettingType(index);
    }
    return(
        <>
            <NavSideBarComb>
                <div className="px-2 lg:pl-12 pt-6 lg:pt-24">
                    <div className="flex flex-col px-1 pb-5">
                        <div className="flex gap-1 items-center">
                            <FiSettings size={18}/>
                            <h1 className="text-xl font-medium">Setting</h1>
                        </div>
                        <div className="flex flex-wrap py-6 pl-6">
                            <CommonClickables
                            lists={settingTypeItems}
                            state={settingType}
                            stateChange={changeSetting}
                            white={false} />
                        </div>
                    </div>
                    {settingType === 0 && <Account />}
                    {settingType === 1 && <NotificationSetting />}
                    {settingType === 2 && <Privacy />}
                    {settingType === 3 && <BillingPayout />}
                    {settingType === 4 && <ApiClients />}
                    {settingType === 5 && <CloseAccount />}
                </div>
            </NavSideBarComb>
        </>
    )
}