import Avatar from '../assets/avatar.png'
import { FaRegCheckCircle, FaMoon } from "react-icons/fa";
import { Link } from 'react-router-dom';
export const ProfileDropDown = ({isBrightMode, handleNightMode}) => {
    return(
        <>
                    <div className='flex gap-1 pt-3 pl-3 items-center'>
                      <img
                      className='w-8 h-8'
                      src={Avatar} alt="profile" />
                      <p>user.name</p>
                      <FaRegCheckCircle className="text-blue-500"/>
                    </div>
                    <Link to={'/my_instructor_profile_view'} className='hover:text-red-500 font-medium pl-3 pt-2' >View Instructor Profile</Link>
                    <hr />
                    <div
                    onClick={handleNightMode}
                    className="flex justify-evenly cursor-pointer">
                      <FaMoon />
                      <h2>Night mode</h2>
                      <div className={`flex flex-col transition-all duration-500 transform rounded-xl justify-center ease-in-out ${isBrightMode ? "bg-stone-200 items-start transition-all duration-1500":"bg-stone-400 items-end transition-all duration-1500"} w-[40px] h-[20px]`}>
                      <div
                        className={`rounded-full hover:cursor-pointer transition-all duration-1500 transform w-[20px] h-[20px] ease-in-out ${isBrightMode ? "bg-stone-400":"bg-white"}`}></div>
                      </div>
                    </div>
                    <hr />
                    </>
    )
}