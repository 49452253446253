import { useState, useCallback } from "react"
import { ToggleOnOff } from "./inputfield";
import Button from "../Button";
import { SliderWithText } from "./inputfield";
export default function NotificationSetting() {
    const [toggle, setToggle] = useState([false, true, true, false, true, false, false])
    const toggleSlide = useCallback((index) => {
        setToggle((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    }, []);

    

    return (
        <div className="pb-10">
           <div className="flex flex-col gap-2 py-6">
                <h4 className="text-xl font-medium">Notifications - Choose when and how to be notified</h4>
                <p className="text-sm text-stone-500">Select push and email notifications you'd like to receive</p>
                <div>
                    <h4 className="font-medium">Choose when and how to be notified</h4>
                </div>
                <div className="flex flex-col gap-3">
                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={0}
                    label={"Subscriptions"}
                    desc={"Notify me about activity from the profiles I'm subscribed to"}
                    />
                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={1}
                    label={"Recommended Courses"}
                    desc={"Notify me of courses I might like based on what I watch"}
                    />
                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={2}
                    label={"Activity on my comments"}
                    desc={"Notify me about activity on my comments on others’ courses"}
                    />
                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={3}
                    label={"Replies to my comments"}
                    desc={"Notify me about replies to my comments"}
                    />
                    <hr />
                    <h4 className="font-medium">Email notifications</h4>
                    <p className="text-sm text-stone-500">Your emails are sent to [email protected] To unsubscribe from an email, click the "Unsubscribe" link at the bottom of it. Learn more about emails from Edututs+.</p>

                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={4}
                    label={"Send me emails about my Cursus activity and updates I requested"}
                    desc={"If this setting is turned off, Cursus may still send you messages regarding your account, required service announcements, legal notifications, and privacy matters"}
                    />
                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={5}
                    label={"Promotions, courses recommendations, helpful resources from Cursus."}
                    desc={""}
                    />
                    <SliderWithText
                    tog = {[toggle, toggleSlide]}
                    index={6}
                    label={"Announcements from intructors whose course course(s) I'm enrolled in."}
                    desc={"To adjust this preference by course, leave this box checked and go to the course dashboard and click on"}
                    />
                </div>
            </div> 
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
                <Button>Save Changes</Button>
            </div>
        </div>
    )
}
