import React, { useState } from 'react';
import { TextArea } from '../CreateCourse/TextArea';

export default function Questions({ questions }) {
  const [selectedChoices, setSelectedChoices] = useState({});

  const handleChoiceChange = (questionIndex, choiceIndex) => {
    setSelectedChoices((prevChoices) => ({
      ...prevChoices,
      [questionIndex]: choiceIndex,
    }));
  };

  return (
    <>
      {questions.map((question, index) => (
        <div key={index} className='flex flex-col gap-3 pb-1'>
          <div className="flex gap-2">
            <span className="font-medium">{`Ques ${index + 1} :- ${question.questionText}`}</span>
          </div>
          {question.type === 'choice' ? (
            <div className='flex flex-col gap-1'>
              {question['answer'].map((choice, choiceIndex) => (
                <div
                onClick={() => handleChoiceChange(index, choiceIndex)}
                className={`flex gap-1 hover:cursor-pointer`}
                key={choiceIndex}>
                  <div
                    className={`flex justify-center items-center w-4 h-4 border rounded-full ${
                      selectedChoices[index] === choiceIndex ? 'border-red-500' : 'border-slate-400 hover:border-slate-700'
                    }`}
                    
                  >
                    {selectedChoices[index] === choiceIndex && (
                      <div className='bg-red-500 w-2 h-2 rounded-full'></div>
                    )}
                  </div>
                  <label className='text-stone-500 hover:cursor-pointer text-sm'>{choice}</label>
                </div>
              ))}
            </div>
          ) : question.type === 'short_answer' ? (
            <TextArea label={''} name={`answer_${index}`} placeholder='Pls Explain' />
          ) : (
            <p>Unsupported question type</p>
          )}
        </div>
      ))}
    </>
  );
}
