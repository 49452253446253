import React, { useState } from "react";
import { BiFile,BiPaperclip, BiSolidPlusSquare, BiVideo } from "react-icons/bi";
import CommonClickables from "./commonClickables";
import { TextInput } from "../Settings/inputfield";
import { TextArea } from "./TextArea";
import { SliderWithTextSolo } from "../Settings/inputfield";
import { Source } from "./mediaSource";
import { FileUpload } from "../Settings/inputfield";
import {UploadContainer} from './UploadContainer';
export default function Lecture({closeModal}) {
  const icons = [
    <BiFile />,
    <BiVideo />,
    <BiPaperclip />,
  ];
  const items = ["Basic", "Video", "Attachment"];
  const [media, setMedia] = useState(0);
  const [slide, setSlide] = useState(false);
  const [lecType, setLecType] = useState(0);

  const itemsCourse = [
      "HTML5(mp4)","External URL",
      "YouTube","Vimeo","embedded"
  ]

  const changeMedia = (index) => {
      setMedia(index)
  }


  const toggleSlide = () => {
    setSlide(!slide);
  }
  const changeLecType = (index) => {
    setLecType(index);
  };

 

  return (
    <div className="flex flex-col gap-3 h-full w-full">
      <div className="flex flex-col overflow-y-hidden hover:overflow-y-auto     gap-3 w-full h-full">
        <div className="flex justify-between px-3">
          <h4 className="font-medium">Add Lecture</h4>
          <span className="font-bold text-stone-400 hover:text-stone-500 cursor-pointer" onClick={closeModal}>X</span>
        </div>
        <hr />
        <div className="flex gap-3 flex-wrap md:gap-0 w-full">
          <CommonClickables
            lists={items}
            state={lecType}
            stateChange={changeLecType}
            dynamicClass={"w-1/3 h-10 self-center justify-center"}
            white={false}
            icon={icons}
          />
        </div>
        <div>
        {lecType === 0 &&
        <div>
        <label className="font-medium">Lecture Title*</label>
        <TextInput name="title" placeholder="Title here" notwhite={true}/>
        <label className="font-medium">Description*</label>
        <TextArea name="description" placeholder="description here..."/>
        <SliderWithTextSolo label="Free Preview" tog={[slide, toggleSlide]} />
        </div>}
        {lecType === 1 &&
        <div>
        <div className="flex flex-wrap gap-3 pl-6">
                          <CommonClickables
                          lists={itemsCourse}
                          state={media}
                          stateChange={changeMedia}
                          white={true} />
          </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-2 px-6">
                      <div className="">
                        <Source uploadId={12} id={media}/>
                      </div>
                    {media === 0 &&
                    <div className="flex flex-col items-center justify-center border w-full border-dashed">
                      <FileUpload name="VIDEO POSTER" />
                      <span>Uploaded ID: preview.jpg</span>
                      <span className="text-purple-400 text-sm">590x300 pixels. Supports: jpg, jpeg, or png</span>
                    </div>}
                  </div>
                  <span className="font-medium">Video Runtime - hh:mm:ss*</span>
                  <div className="flex w-1/2">
                    <TextInput name='hour' value={'00'} notwhite={true} placeholder="" />
                    <TextInput name='minute' value={'1'} notwhite={true}/>
                    <TextInput name='second' value={'00'} notwhite={true}/>
                  </div>
        </div>}
        {lecType === 2 &&
        <div className="flex flex-col gap-2 border border-dashed p-3 items-center w-full">
          <FileUpload icon={<BiSolidPlusSquare />} name="ATTACHMENT" />
          <UploadContainer id={12} />
          <UploadContainer id={13} />
          <UploadContainer id={14} />
        </div>}
        <hr />
      </div>
      </div>
      <div className="flex self-end gap-2">
      <button
                                onClick={closeModal}
                                className={`transition-all
                                            duration-400 font-semibold
                                            text-stone-600 text-center transition-500 bg-slate-200
                                            rounded-sm w-36 h-9 hover:cursor-pointe hover:text-stone-800`}>
                                Close</button>
                            <button
                                className={`transition-all
                                            duration-400 font-semibold
                                            text-stone-100 text-center transition-500 bg-redbutton
                                            rounded-sm w-36 h-9 hover:cursor-pointer hover:bg-slate-700`}>
                                Add Lecutre</button>
      </div>
    </div>
  );
}
