import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Certificate from "./components/Tests/Certification.jsx";
import CreateCourse from "./components/CreateCourse/createCourse.jsx";
import LiveStreams from "./components/LiveStreams.jsx";
import Notfications from "./components/CreateCourse/Notifications.jsx";
import Verification from "./components/CreateCourse/Verification.jsx";
import Setting from "./components/Settings/settings.jsx";
import "./index.css";
import Dashbord from "./components/Dashbord/Dashbord.jsx";
import Courses from "./components/Dashbord/Courses.jsx";
import About from "./pages/About/About.jsx";
import CertificateForm from "./components/Tests/certificationForm.jsx";
import "./index.css";
import Company from "./pages/About/Company.jsx";
import Careers from "./pages/About/Careers.jsx";
import Press from "./pages/About/Press.jsx";
import CertificateTest from "./components/Tests/certificationTest.jsx";
import TestResult from "./components/Tests/TestResult.jsx";
import Messages from "./components/Messages/messages.jsx";
import Explore from "./components/Explore.jsx";
import SavedCourses from "./components/SavedCourses.jsx";
import AllInstructors from "./components/Instructors.jsx";
import Home from "./components/Home/Home.jsx";
import { AppContextProvider } from "./Context/AppContext.jsx";

import SignUp from "./components/signUp/SignUp.jsx";
import CourseDetail from "./components/courseDetailView/courseDetail.jsx";
import PaidMember from "./components/membershipDetail/PaidMembership.jsx";
import Checkout from "./components/checkout/checkout.jsx";
import Invoice from "./components/checkout/invoice.jsx";
import Earnings from "./components/Dashbord/Earnings.jsx";
import MyCsertificate from "./components/Dashbord/MyCertificate.jsx";
import Reviews from "./components/Dashbord/Reviews.jsx";
import LiveStreamView from "./pages/LiveSteamView/LiveStreamView.jsx";
import MyInstructorProfile from "./ViewInstructorProfile/MyInstructorProfile.jsx";
import AddLiveStream from "./pages/AddLiveStream/AddLiveStream.jsx";
import Payouts from "./components/Dashbord/Payouts.jsx";
import Statments from "./components/Dashbord/Statments.jsx";
import SearchResults from "./pages/SearchResults.jsx";
import Terms from "./pages/Terms.jsx";
import Help from "./components/Help.jsx";
import SignIn from "./components/SignIn/SignIn.jsx";
import ForgotPassword from "./components/SignIn/ForgotPassword.jsx";
import SignUpSteps from "./components/SignUpSteps/SignUpSteps.jsx";
import SendFeedBack from "./components/SendFeedBack/SendFeedBack.jsx";

// eslint-disable-next-line no-unused-vars
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {

  return (
    <BrowserRouter>
        <ScrollToTop />
      <AppContextProvider>
        <Routes>
          {/* Routes that use the NavSideBarComb */}
          <Route path="/" element={<Home />} />
          <Route path="/live_streams" element={<LiveStreams />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/saved_courses" element={<SavedCourses />} />
          <Route path="/all_instructor" element={<AllInstructors />} />

          {/* Routes that don't use the NavSideBarComb */}
          <Route path="/setting" element={<Setting />} />
          <Route path="/certification_center" element={<Certificate />} />
          <Route path="/create_new_course" element={<CreateCourse />} />
          <Route path="/dashboard" element={<Dashbord />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about_us" element={<About />} />
          <Route path="/instructor_notifications" element={<Notfications />} />
          <Route path="/instructor_verification" element={<Verification />} />
          <Route
            path="/certification_start_form"
            element={<CertificateForm />}
          />
          <Route
            path="/certification_test_view"
            element={<CertificateTest />}
          />
          <Route path="/certification_test_result" element={<TestResult />} />
          <Route path="/instructor_messages" element={<Messages />} />
          <Route path="/Company" element={<Company />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Press" element={<Press />} />
          <Route path="/live" element={<LiveStreams />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/PaidMember" element={<PaidMember />} />
          <Route path="/CourseDetail" element={<CourseDetail />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route path="/earning" element={<Earnings />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/mycereficate" element={<MyCsertificate />} />
          <Route path="/live" element={<LiveStreamView />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/PaidMember" element={<PaidMember />} />
          <Route path="/CourseDetail" element={<CourseDetail />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route
            path="/my_instructor_profile_view"
            element={<MyInstructorProfile />}
          />
          <Route path="/Addlive" element={<AddLiveStream />} />
          <Route path="/payout" element={<Payouts />} />
          <Route path="/statments" element={<Statments/>} />
          <Route path="/search" element={<SearchResults/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/help" element={<Help/>} />
          <Route path="/feedback" element={<SendFeedBack />} />
          <Route path="/signin" element={< SignIn/>} />
          <Route path="/forgotpassword" element={< ForgotPassword/>} />
          <Route path="/signupsteps" element={< SignUpSteps/>} />
        </Routes>
      </AppContextProvider>
    </BrowserRouter>
  );
}
