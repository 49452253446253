import React from 'react';

import logo from '../../assets/ct_logo.svg'
import PrintButton from './printButton.jsx'
function Invoice() {


    return (
        <>
            <div className="bg-[#F7F7F7] overflow-hidden">
                <div className="mx-2 md:mx-[240px] items-center justify-center content-center">
                    <header className="invoice_header bg-gray-800">
                        <div className="container">
                            <div className="row justify-center">
                                <div className="col-md-8">
                                    <div className="invoice_header_main py-4 px-6 flex items-center justify-center">
                                        <div className="float-left w-24 md:w-32">
                                            <a href="index.html">
                                                <img src={logo} alt="logo" />
                                            </a>
                                        </div>
                                        <p className="text-white text-lg font-semibold ml-auto">Invoice</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="wrapper contents w-full md:w-1/2 bg-white py-0 md:py-8 transition-all duration-300 ease-out ml-2 md:ml-5 mb-24">
                        <div className="container">
                            <div className="row justify-center">
                                <div className="col-md-8">
                                    <div className="p-4 md:p-8 bg-white w-full mb-24">
                                        <div className="invoice_date_info float-right">
                                            <ul>
                                                <li>
                                                    <span className="vdt-list">
                                                        <span className="font-semibold">Date :</span> 10 April 2023
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="vdt-list">
                                                        <span className="font-semibold">Invoice No :</span> IVIP12023598
                                                    </span>
                                                </li>
                                                <li>
                                                    <span className="vdt-list">
                                                        <span className="font-semibold">Order ID :</span> 1258963487
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-span-12">
                                            <h2 className="invoice_title text-2xl mt-8 font-semibold font-roboto text-gray-800 text-left my-0">
                                                Invoice
                                            </h2>
                                        </div>
                                        <div className="flex flex-wrap -mx-2 mt-7">
                                            <div className="flex flex-col md:flex-row mb-5 justify-between w-full">
                                                <div className="ml-2 md:ml-7">
                                                    <div className="vhls140 float-left w-full mt-8">
                                                        <h4 className="font-semibold">To</h4>
                                                        <ul>
                                                            <li>
                                                                <span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">
                                                                    Rock William
                                                                </span>
                                                            </li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">133, Dracut</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">Massachusetts</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">01826</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">United States</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="float-right">
                                                    <div className="vhls140 w-full mt-8">
                                                        <h4 className="font-semibold">Cursus</h4>
                                                        <ul>
                                                            <li>
                                                                <span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">
                                                                    Cursus LTD
                                                                </span>
                                                            </li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">#1234, Shahid karnail Singh Nagar,</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">Near MBD Mall,</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">141001</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">Ludhiana</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">Punjab</span></li>
                                                            <li><span className="font-normal text-base font-roboto text-gray-500 text-left leading-6">India</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full mb-4 text-gray-800">
                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr className="bg-gray-100">
                                                        <th className="px-4 py-2" scope="col">
                                                            Item
                                                        </th>
                                                        <th className="px-4 py-2" scope="col">
                                                            Price
                                                        </th>
                                                        <th className="px-4 py-2" scope="col">
                                                            Vat(20%)
                                                        </th>
                                                        <th className="px-4 py-2" scope="col">
                                                            Total Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="border-t border-gray-200">
                                                        <td className="px-4 py-2 text-center">
                                                            <div>
                                                                <p>Item Title</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-2 text-center">
                                                            <div>
                                                                <p>$79.00</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-2 text-center">
                                                            <div className="user_dt_trans">
                                                                <p>$10.00</p>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-2 text-center">
                                                            <div className="user_dt_trans">
                                                                <p>$89.00</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="border-t border-gray-200">
                                                        <td className="px-4 py-2" colspan="1"></td>
                                                        <td className="px-4 py-2" colspan="3">
                                                            <div className="font-normal font-roboto text-gray-500  leading-6 text-right">
                                                                <div className="text-base font-semibold font-roboto text-gray-800 leading-6 mb-5">Invoice Total : USD $220.00</div>
                                                                <p>Paid via Paypal</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <div className="leftfooter flex justify-between w-full">
                                                <p className="inline">Thanks for buying.</p>

                                                <div className="righttfooter float-right">
                                                    <div className="btn">
                                                        <PrintButton></PrintButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Invoice;