import React from "react";

const Purchased = () => {
  return (
    <>
      <div className="  w-full px-4 mx-auto max-h-full ">
        <div className="mt-4 mb-6 w-full">
          <div className=" max-w-3xl">
            <div className=" mt-0">
              <h3 className="text-lg font-medium font-roboto text-left mb-4 text-gray-700 ">
                Purchased Courses
              </h3>
              <div className=" bg-white w-full  p-2  border border-gray-300  flex flex-col md:flex-row ">
                <div className="  sm:h-auto border border-spacing-9 mr-4">
                  <img
                    className="w-full md:max-w-xs "
                    src="https://lms.dalloltech.com/images/courses/img-1.jpg"
                    alt="pic"
                  />
                </div>
                <div className="mt-0">
                  <div className="flex">
                    <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left mr-2">
                      109K Views
                    </span>
                    <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left">
                      15 day a go
                    </span>
                  </div>
                  <div>
                    <a href="#">
                      <h2 className="block text-lg font-semibold font-roboto text-gray-700 text-left leading-6 mb-3">
                        Complete Python Bootcamp: Go from zero to hero in Python
                        3
                      </h2>
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <span className="block text-xs font-normal font-roboto text-gray-600 text-left ">
                        Web Development | Python
                      </span>
                    </a>
                  </div>
                  <div>
                    <span className="inline-block text-xs font-medium font-roboto text-white bg-gray-800 py-1 px-2 uppercase rounded-md mt-3 cursor-default">
                      Pruchased
                    </span>
                  </div>
                  <div className=" flex justify-between">
                    <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                      By John Doe
                    </p>
                    <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                      $10
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" bg-white w-full  p-2  border border-gray-300 flex flex-col md:flex-row mt-5 ">
              <div className="   sm:h-auto border border-spacing-9 mr-4 ">
                <img
                  className="w-full md:max-w-xs"
                  src="https://lms.dalloltech.com/images/courses/img-2.jpg"
                  alt="pic"
                />
              </div>
              <div className="mt-0">
                <div className="flex">
                  <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left mr-2">
                    5M views
                  </span>
                  <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left">
                    15 day a go
                  </span>
                </div>
                <div>
                  <a href="#">
                    <h2 className="block text-lg font-semibold font-roboto text-gray-700 text-left leading-6 mb-3">
                      The Complete JavaScript Course 2023: Builed real project
                    </h2>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <span className="block text-xs font-normal font-roboto text-gray-600 text-left ">
                      Web Development | JavaScript
                    </span>
                  </a>
                </div>
                <div>
                  <span className="inline-block text-xs font-medium font-roboto text-white bg-gray-800 py-1 px-2 uppercase rounded-md mt-3 cursor-default">
                    Pruchased
                  </span>
                </div>
                <div className=" flex justify-between">
                  <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                    By Jassica William
                  </p>
                  <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                    $10
                  </p>
                </div>
              </div>
            </div>

            <div className=" bg-white w-full  p-2  border border-gray-300 flex flex-col md:flex-row mt-5 ">
              <div className=" sm:h-auto border border-spacing-9 mr-4 ">
                <img
                  className="w-full md:max-w-xs "
                  src="https://lms.dalloltech.com/images/courses/img-3.jpg"
                  alt="pic"
                />
              </div>
              <div className="mt-0">
                <div className="flex">
                  <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left mr-2">
                    5M views
                  </span>
                  <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left">
                    18 day a go
                  </span>
                </div>
                <div>
                  <a href="#">
                    <h2 className="block text-lg font-semibold font-roboto text-gray-700 text-left leading-6 mb-3">
                      Beginning C++ Programming - From Beginner io Beyond
                    </h2>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <span className="block text-xs font-normal font-roboto text-gray-600 text-left ">
                      Web Development | C++
                    </span>
                  </a>
                </div>
                <div>
                  <span className="inline-block text-xs font-medium font-roboto text-white bg-gray-800 py-1 px-2 uppercase rounded-md mt-3 cursor-default">
                    Pruchased
                  </span>
                </div>
                <div className=" flex justify-between">
                  <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                    By Joginder Singh
                  </p>
                  <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                    $13
                  </p>
                </div>
              </div>
            </div>

            <div className=" bg-white w-full  p-2  border border-gray-300  flex flex-col md:flex-row mt-5 ">
              <div className="  sm:h-auto border border-spacing-9 mr-4 ">
                <img
                  className="w-full md:max-w-xs  "
                  src="https://lms.dalloltech.com/images/courses/img-4.jpg"
                  alt="pic"
                />
              </div>
              <div className="mt-0">
                <div className="flex">
                  <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left mr-2">
                    153k views
                  </span>
                  <span className="block mt-2 mb-2 text-xs font-normal font-roboto text-gray-600 text-left">
                    3 months ago
                  </span>
                </div>
                <div>
                  <a href="#">
                    <h2 className="block text-lg font-semibold font-roboto text-gray-700 text-left leading-6 mb-3">
                      The Complete Digital Marketing Course
                    </h2>
                  </a>
                </div>
                <div>
                  <a href="#">
                    <span className="block text-xs font-normal font-roboto text-gray-600 text-left ">
                      Digital Marketing | Marketing
                    </span>
                  </a>
                </div>
                <div>
                  <span className="inline-block text-xs font-medium font-roboto text-white bg-gray-800 py-1 px-2 uppercase rounded-md mt-3 cursor-default">
                    Pruchased
                  </span>
                </div>
                <div className=" flex justify-between">
                  <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                    By Poonam Verma
                  </p>
                  <p className="text-base font-normal font-roboto text-gray-600 text-left leading-7 my-2">
                    $12
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchased;
