import React, {useState} from "react"
import { BiCart, BiSolidTag } from "react-icons/bi";
export default function Price() {
    const [priceType, setPriceType] = useState(0)
    const [requireLogIn, setRequireLogIn] = useState(false);
    const [requireEnroll, setRequireEnroll] = useState(false);
    const changePriceType = (id) => {
        setPriceType(id);
    }
    
    const toggleLogIn = () => {
        setRequireLogIn(!requireLogIn);
      };
    const toggleEnroll = () => {
        setRequireEnroll(!requireEnroll);
      };

    return(
        <>
            <div className="flex flex-col gap-6 bg-white pl-4 py-4">
                <div className="flex w-full justify-center py-4">
                    <div
                        onClick={()=> {changePriceType(0)}}
                        className={`flex hover:cursor-pointer justify-center pl-3 pr-3 items-center
                        rounded-sm h-11 w-2/5 gap-1
                        ${ priceType === 0 ? "bg-red-500 text-white":"bg-stone-100 text-black"}
                        `}
                        key="free"><BiSolidTag />Free</div>
                    <div
                        onClick={()=> {changePriceType(1)}}
                        className={`flex hover:cursor-pointer justify-center pl-3 pr-3 items-center
                        rounded-sm h-11 w-2/5 gap-1
                        ${ priceType === 1 ? "bg-red-500 text-white":"bg-stone-100 text-black"}
                        `}
                        key="paid"><BiCart />Paid</div>
                </div>
                
                {priceType === 0 &&
                <div className="flex flex-col items-center gap-5">
      <div className="flex gap-3">
        <div className={`flex flex-col transition-all duration-500 transform rounded-xl justify-center ${requireLogIn ? "bg-red-500 items-end pr-1":"bg-stone-400 items-start pl-1"} w-10 h-6`}>
          <div
          onClick={toggleLogIn}
          className={`rounded-full hover:cursor-pointer transition-all duration-500 transform w-4 h-4 bg-white`}></div>
        </div>
        <label>Require Log In</label>
      </div>
      <div className={`flex gap-3`}>
        <div className={`flex flex-col transition-all duration-500 transform rounded-xl justify-center ${requireEnroll ? "bg-red-500 items-end pr-1":"bg-stone-400 items-start pl-1"} w-10 h-6`}>
          <div
          onClick={toggleEnroll}
          className={`rounded-full hover:cursor-pointer w-4 h-4 bg-white`}></div>
        </div>
        <label>Require Enroll</label>
      </div>
      </div>}
                {priceType === 1 && <div className="flex flex-col gap-2">
                    <label htmlFor="regprice">Regular Price*</label>
                    <div className="flex pl-4 w-3/12 border h-8 border-solid justify-between">
                            <input type="text" name="regprice" id="regprice"
                            placeholder="$0" className="w-11/12 border border-white focus:border-white focus:outline-none text-sm" />
                            <div className="flex border justify-center items-center text-center w-9 bg-stone-100 font-semibold">USD</div>
                    </div>
                    <label htmlFor="discount" className="pt-3">Discount Price*</label>
                    <div className="flex pl-4 w-3/12 border h-8 border-solid justify-between">
                            <input type="text" name="discount" id="discount"
                            placeholder="$0" className="w-11/12 border border-white focus:border-white focus:outline-none text-sm" />
                            <div className="flex border justify-center items-center text-center w-9 bg-stone-100 font-semibold">USD</div>
                    </div>
                </div>}

            </div>
        </>
    )
}