import React from "react";
import RepeatedHeader from "../pages/About/RepeatedHeader";
import Footer from "../components/Footer";
import { RiSearchLine } from "react-icons/ri";

const Terms = () => {
  return (
    <div>
      <RepeatedHeader />

      <div className=" flex flex-col gap-4 md:gap-0 md:flex-row  justify-between  bg-white pt-[80px] mx-20">
        <div className=" ">
          Home <span>/Terms of Use</span>
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="px-8 py-2 text-[12px] font-[400] border w-52 focus:outline-none focus:text-[#333333]"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 py-2">
            {/* Search icon from react-icons */}
            <RiSearchLine size={13} className=" text-gray-400" />
          </div>
        </div>
      </div>
      <div className=" mx-20 mt-20 mb-5">
        <h1>Terms of Use</h1>
      </div>
      <div className=" bg-[#efefef]">
        <div className=" grid grid-cols-1 lg:grid-cols-6 gap-4 mx-20 ">
          <div className=" lg: col-span-2  w-full h-screen my-10">
            <div className=" w-full  bg-white">
              <ul className=" flex flex-col">
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Terms&Use
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Privacy Policy
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Cookie Policy
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Copyright Policy
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Curus API Managemnt
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Instructur Terms
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Credit Program
                </li>
                <li className="p-4 border-b-[1px] border-[#efefef] text-[14px] font-[400]">
                  Pricing and Promotion Policy
                </li>
              </ul>
            </div>
          </div>
          <div className=" lg: col-span-4 w-full  my-10">
            <div className=" w-full ">
              <div>
                <div className=" text-[16px] font-[400] italic mb-[20px]">
                  These Terms of Use <strong>("Terms")</strong>were last updated
                  on August 1, 2023.
                </div>
                <div>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur volutpat maximus pellentesque. Integer sem enim,
                    luctus at nibh at, condimentum sagittis sapien. Sed tempus
                    ipsum erat, sit amet efficitur velit interdum eu. Vestibulum
                    hendrerit id dolor eu scelerisque. Phasellus ex dui,
                    consequat nec feugiat eu, dapibus eget ante. Sed sodales
                    interdum dui, at euismod mi feugiat hendrerit. Suspendisse
                    auctor libero in tempor mollis. Nulla et dolor velit.
                    Aliquam sit amet luctus quam.
                  </p>
                  <p className=" text-[14px] font-[400] leading-[26px]  my-[4px] text-[#686f7a]">
                    {" "}
                    Nam a egestas libero, eget eleifend turpis. Sed id ipsum a
                    ipsum aliquam laoreet sit amet sit amet nibh. Proin dapibus,
                    libero sed posuere rhoncus, orci mi cursus enim, at accumsan
                    eros massa lacinia mi. Nunc eget finibus felis, volutpat
                    malesuada sem. Aliquam ac nisl pellentesque, varius neque
                    sit amet, porttitor nunc. Nullam elit tellus, dapibus non
                    eleifend sed, hendrerit eget velit. Aliquam ut felis dictum,
                    tincidunt magna vitae, aliquam massa. In porttitor tristique
                    quam, non dignissim sapien pharetra ultrices. Cras non ante
                    non velit mollis mollis. Pellentesque habitant morbi
                    tristique senectus et netus et malesuada fames ac turpis
                    egestas. Quisque et bibendum urna, eget consequat sapien.
                    Integer sed condimentum nibh. Integer id neque tristique,
                    lobortis massa ac, dapibus nibh. Donec nulla odio, porttitor
                    ac rutrum eget, volutpat a velit. Curabitur et enim quis
                    diam congue dictum et vitae dui. Nulla tortor orci, luctus a
                    pretium vel, ultrices porta nisl.
                  </p>
                  <p className=" text-[14px] font-[400] leading-[26px]  my-[4px] text-[#686f7a]">
                    Etiam lobortis dictum tincidunt. Interdum et malesuada fames
                    ac ante ipsum primis in faucibus. Suspendisse ultricies
                    efficitur dui, suscipit tempus elit condimentum quis. Duis
                    sed vestibulum tortor, eget cursus odio.
                  </p>
                </div>
              </div>
              <div className=" my-[35px]">
                <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">
                  Tabel Of Content
                </h4>
                <ul className="">
                  <li className=" list-decimal ml-[30px] mb-[5px]">Accounts</li>
                  <li className=" list-decimal ml-[30px] mb-[5px]">
                    Course Enrollment and Lifetime Access
                  </li>
                  <li className=" list-decimal ml-[30px] mb-[5px]">
                    Payments, Credits, and Refunds
                  </li>
                  <li className=" list-decimal ml-[30px] mb-[5px]">
                    Content and Behavior Rules
                  </li>
                  <li className=" list-decimal ml-[30px] mb-[5px]">
                    Cursus’s Rights to Content You Post
                  </li>
                  <li className=" list-decimal ml-[30px] mb-[5px]">
                    Cursus’s Rights
                  </li>
                  <li className=" list-decimal ml-[30px] mb-[5px]">
                    How to Contact Us
                  </li>
                </ul>
              </div>
              <div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">1. Accounts</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                    Morbi lectus nunc, lacinia ut consequat a, semper vel erat.
                    Duis ut lacus nec dui sodales mattis. Mauris tellus dolor,
                    pulvinar sit amet pretium a, malesuada sed tellus. Aliquam
                    ultrices elit neque, quis lacinia ex porttitor non. Donec ac
                    iaculis turpis. Nulla lacinia enim quis orci aliquam, non
                    cursus urna pellentesque. Class aptent taciti sociosqu ad
                    litora torquent per conubia nostra, per inceptos himenaeos.
                    Phasellus in mi a nisi auctor interdum. Vivamus faucibus
                    magna sed elit interdum consequat. Vestibulum eu tortor vel
                    ante feugiat faucibus quis et urna. Quisque interdum ac enim
                    eu tempus. Fusce viverra, lectus egestas tincidunt cursus,
                    tortor sapien convallis metus, vitae auctor risus metus vel
                    nisi. Aenean dapibus bibendum mauris ut iaculis.
                  </p>
                </div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">2. Course Enrollment and Lifetime Access</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                  Quisque et bibendum urna, eget consequat sapien. Integer sed condimentum nibh. Integer id neque tristique, lobortis massa ac, dapibus nibh. Donec nulla odio, porttitor ac rutrum eget, volutpat a velit. Curabitur et enim quis diam congue dictum et vitae dui. Nulla tortor orci, luctus a pretium vel, ultrices porta nisl.
                  </p>
                </div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">3. Payments, Credits, and Refunds</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                  Morbi lectus nunc, lacinia ut consequat a, semper vel erat. Duis ut lacus nec dui sodales mattis. Mauris tellus dolor, pulvinar sit amet pretium a, malesuada sed tellus. Aliquam ultrices elit neque, quis lacinia ex porttitor non. Donec ac iaculis turpis. Nulla lacinia enim quis orci aliquam, non cursus urna pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna sed elit interdum consequat. Vestibulum eu tortor vel ante feugiat faucibus quis et urna. Quisque interdum ac enim eu tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor sapien convallis metus, vitae auctor risus metus vel nisi. Aenean dapibus bibendum mauris ut iaculis.
                  </p>
                </div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">4. Content and Behavior Rules</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                  Quisque et bibendum urna, eget consequat sapien. Integer sed condimentum nibh. Integer id neque tristique, lobortis massa ac, dapibus nibh. Donec nulla odio, porttitor ac rutrum eget, volutpat a velit. Curabitur et enim quis diam congue dictum et vitae dui. Nulla tortor orci, luctus a pretium vel, ultrices porta nisl.
                  </p>
                </div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">5. Cursus’s Rights to Content You Post</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                  Morbi lectus nunc, lacinia ut consequat a, semper vel erat. Duis ut lacus nec dui sodales mattis. Mauris tellus dolor, pulvinar sit amet pretium a, malesuada sed tellus. Aliquam ultrices elit neque, quis lacinia ex porttitor non. Donec ac iaculis turpis. Nulla lacinia enim quis orci aliquam, non cursus urna pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna sed elit interdum consequat. Vestibulum eu tortor vel ante feugiat faucibus quis et urna. Quisque interdum ac enim eu tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor sapien convallis metus, vitae auctor risus metus vel nisi. Aenean dapibus bibendum mauris ut iaculis.
                  </p>
                </div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">6. Cursus’s Rights</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                  Quisque et bibendum urna, eget consequat sapien. Integer sed condimentum nibh. Integer id neque tristique, lobortis massa ac, dapibus nibh. Donec nulla odio, porttitor ac rutrum eget, volutpat a velit. Curabitur et enim quis diam congue dictum et vitae dui. Nulla tortor orci, luctus a pretium vel, ultrices porta nisl.
                  </p>
                </div>
                <div class="vew120 mt-35 mb-30">
                  <h4 className=" text-[18px] font-medium text-[#333] mb-[13px] leading-[26px]">7. How to Contact Us</h4>
                  <p className=" text-[14px] leading-[26px] font-[400]  my-[4px] text-[#686f7a]">
                  Morbi lectus nunc, lacinia ut consequat a, semper vel erat. Duis ut lacus nec dui sodales mattis. Mauris tellus dolor, pulvinar sit amet pretium a, malesuada sed tellus. Aliquam ultrices elit neque, quis lacinia ex porttitor non. Donec ac iaculis turpis. Nulla lacinia enim quis orci aliquam, non cursus urna pellentesque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus in mi a nisi auctor interdum. Vivamus faucibus magna sed elit interdum consequat. Vestibulum eu tortor vel ante feugiat faucibus quis et urna. Quisque interdum ac enim eu tempus. Fusce viverra, lectus egestas tincidunt cursus, tortor sapien convallis metus, vitae auctor risus metus vel nisi. Aenean dapibus bibendum mauris ut iaculis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};

export default Terms;
