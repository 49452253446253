import React from 'react';

export const TextInput = ({name, placeholder, value, notwhite}) => {
    return (
        <>
        <input
            type="text"
            name={name}
            placeholder={placeholder}
            value={value}
            className={`px-4 py-2 w-full border ${!notwhite ? "border-white focus:border-white":"bg-stone-100"} focus:outline-none`} />
        </>
        )
};

export const ToggleOnOff = ({isToggle, toggler}) => {
    return (
        <>
        <div
        onClick={toggler}
        className={`flex flex-col hover:cursor-pointer transition-all duration-500 transform rounded-xl justify-center ${isToggle ? "bg-red-500 items-end pr-1":"bg-stone-400 items-start pl-1"} w-12 h-6`}>
          <div
          className={`rounded-full hover:cursor-pointer transition-all duration-500 transform w-4 h-4 bg-white`} />
        </div>
        </>
    )
}

export const SliderWithText = ({index, label, desc, tog}) => {
    return(
        <div className="flex gap-2">
            <ToggleOnOff
            toggler={() => tog[1](index)}
            isToggle={tog[0][index]}
             />
            <div>
                <label className="font-medium">{label}</label>
                <p className="text-stone-500 text-sm">{desc}</p>
            </div>
        </div> 
    )
}
export const SliderWithTextSolo = ({ label, desc, tog}) => {
    return(
        <div className="flex gap-2">
            <ToggleOnOff
            toggler={tog[1]}
            isToggle={tog[0]}
             />
            <div>
                <label className="font-medium">{label}</label>
                <p className="text-stone-500 text-sm">{desc}</p>
            </div>
        </div> 
    )
}

export const RadioWithParentBorder = ({change, index, state, label}) => {
    return(
        
        <div
        onClick={change}
        className='gap-2 w-full hover:cursor-pointer hover:shadow-md transition-all duration-1000 transform flex justify-center items-center bg-white py-3'>
            <div className={`flex justify-center items-center w-4 h-4 border rounded-full ${index === state ? "border-blue-500":"border-slate-500"}`}>
                <div className={`${index === state && "bg-red-500"} w-[8px] h-[8px] rounded-full`}></div>
            </div>
                <label className="cursor-pointer">{label}</label>
        </div>
    )
}

export const FileUpload = ({name, icon, fileFormat}) => {
    return(
        <div className="flex flex-col relative  justify-center items-center 
            text-center bg-white">
            <label
              htmlFor="uploadvideo"
              className=" flex gap-1 border items-center border-red-500 text-red-600 py-2 px-3 rounded-md bg-white cursor-pointer"
            >
            {icon && <span>{icon}</span>}
            <span>{name}</span>
            </label>
            <input
              type="file"
              id="uploadvideo"
              accept="video/*"
              className="opacity-0 absolute inset-0 z-[-1]"
            />
            <span className="text-stone-500 pt-2 text-sm">{fileFormat && `File Format: ${fileFormat}`}</span>
        </div>
    )
}