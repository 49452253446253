import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import sign_logo from '../../assets/sign_logo.png';

function SignUpSteps() {

        const [Student, setStudent] = useState(false)
        const [instructor, setinstructor] = useState(true)
        const Handletoogle=  () =>{
            setStudent(true)
            setinstructor(false) 
        }
        const Handletoogle1=  () =>{
            setStudent(false)
            setinstructor(true)
        }

  return (
    <div className="bg-stars  w-full backdrop-blur  bg-cover flex items-center justify-center ">
         <div className="container w-5/6 bg-opacity-0 ">
      <div className="flex items-center justify-center flex-col">
        <div className="flex justify-center  h-9" id="logo">
          
          <img className="text-center  items-center justify-center  w-[135px]" src={logo} alt="" />
      </div>
        
      <div className='border  flex justify-center items-center w-[630px] '>
            <div className='bg-white rounded-md border border-gray-300 p-4 md:p-8  flex-1 w-full text-center'>
           
              <ul className=" flex items-center justify-center w-full cursor-pointer" id="myTab" role="tablist">
                <li className={`nav-item border ${ instructor ? " border-b-" : "border-b-red-500  text-red-600" }  bg-[#F7F7F7]  py-4 px-4` }>
                  <div onClick={Handletoogle} className=' px-8'>
                    Instructor Sign Up
                  </div>
                </li>
                <li className=
              {`nav-item border ${ instructor && "border-b-red-500 text-red-600" } bg-[#F7F7F7]  py-4 px-4`}>
                 <div onClick={Handletoogle1} className=' px-8' >
                    Student Sign Up
                    </div>
                </li>
              </ul>
            
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="instructor-signup-tab"
                role="tabpanel"
                aria-labelledby="instructor-tab"
              >
                
                {Student &&
                
                  <div>
                    <p className='text-base md:text-lg  text-center leading-6 md:leading-7 text-gray-600 md:py-4 '>Sign Up and Create Course!</p>
                  <form className=' mx-3 items-center bg-white '>
                    <select className="border flex-1  w-full ui hj145 dropdown cntry152 focus:outline-none  focus:border-black  ">
                      <option value="">Select Category</option>
                      
                      <option value={1}>Development</option>
                      <option value={2}>Business</option>
                      <option value={3}>Finance &amp; Accounting</option>
                      <option value={4}>IT &amp; Software</option>
                      <option value={5}>Office Productivity</option>
                      <option value={6}>Personal Development</option>
                      <option value={7}>Design</option>
                      <option value={8}>Marketing</option>
                      <option value={9}>Lifestyle</option>
                      <option value={10}>Photography</option>
                      <option value={11}>Health &amp; Fitness</option>
                      <option value={12}>Music</option>
                      <option value={13}>Teaching &amp; Academics</option>
                    </select>
                    <div className="mt-15 focus:outline-none  focus:border-black">
                      <div className=" focus:outline-none focus:border-black">
                        <div className="border w-full bg-white  border-gray-300 outline-none text-black rounded-md shadow-sm focus:outline-none  focus:border-black">
                          <textarea
                          className='focus:outline-none  focus:border-black'
                            rows={3} 
                            name="description"
                            id="id_about"
                            placeholder="Write a little description about you..."
                            defaultValue={""}
                            style={{ minWidth: '500px', height: '130px' }}

                          />
                        </div>
                      </div>
                      <div className=" rounded-md font-serif  text-gray-500 text-left">
                        Your biography should have at least 12000 characters.
                      </div>
                    </div>
                    <button className="text-sm mt-32 font-medium font-serif text-white rounded-md bg-red-600 w-full text-center h-7 md:h-12" type="submit ">
                      Instructor Sign Up Now
                    </button>
                    <p className="mb-0 mt-30 hvsng145 ">
                Already have an account? <Link className='text-blue-700' to= '/signin'>Log in</Link>
              </p>
                  </form>
                  </div>
                }
              </div>
              <div
                className="tab-pane fade"
                id="student-signup-tab"
                role="tabpanel"
                aria-labelledby="student-tab"
              >
                
                {instructor &&
                 <div>
                  <p className='text-base md:text-lg  text-center leading-6 md:leading-7 text-gray-600 md:py-4 '>Sign Up and Start Learning!</p>
                 <form>
                   <div className="mt-15">
                     <div className="">
                       <div className="w-full bg-white border border-gray-300 outline-none text-black rounded-md shadow">
                         <textarea
                           rows={3}
                           name="description"
                           id="id_about1"
                           placeholder="Write a little description about you..."
                           defaultValue={""}
                           style={{ minWidth: '500px', height: '130px' }}

 
                         />
                       </div>
                     </div>
                     <div className="rounded-md font-serif  text-gray-500 text-left">
                       Your biography should have at least 12000 characters.
                     </div>
                   </div>
                   <button className="text-sm mt-32 font-medium font-serif text-white rounded-md bg-red-600 w-full text-center h-7 md:h-12" type="submit">
                     Student Sign Up Now
                   </button>
                 </form>
                 </div>

                }
              </div>
              </div>     
    </div>
    </div>
    
    <div className="text-base md:text-lg bg-transparent font-normal text-center font-roboto text-gray-700 py-8 md:py-12 block w-full">
            <img className="pl-5 md:pl-8 inline" src={sign_logo} alt="Sign Logo" /> © 2023 <strong className="font-semibold">Cursus</strong>.
            All Rights Reserved.
          </div>
    </div>
    </div>
    </div>
  )
  }


export default SignUpSteps