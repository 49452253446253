import React, { useState } from "react"
import CommonClickables from "./commonClickables";
import { Source } from "./mediaSource";
import { Thumbnail } from "./Thumbnail";
export default function Media() {
    const [media, setMedia] = useState(0);
    const itemsCourse = [
        "HTML5(mp4)","External URL",
        "YouTube","Vimeo","embedded"
    ]

    const changeMedia = (index) => {
        setMedia(index)
    }

    return(
        <>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                    <hr />
                    <h3 className="pl-4">Media</h3>
                    <hr />
                </div>
                <div>
                    <span>Intro Course overview provider type. (.mp4, YouTube, Vimeo etc.)</span>
                </div>
                    <div className="flex flex-wrap gap-3 pl-6">
                        <CommonClickables
                        lists={itemsCourse}
                        state={media}
                        stateChange={changeMedia}
                        white={true} />
                    </div>
                <div className="w-2/5">
                    <Source id={media}/>
                    <label>Course thumbnail*</label>
                    <Thumbnail />
                </div>
            </div>
        </>
    )
}