import React, { useState, useEffect } from 'react'
import CirtificateHeader from "./cirtificateHeader"
import Footer from '../Footer'
import { BiChevronsLeft } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { FormatTime } from '../../Model/helper'
import { QuestionList } from '../../tempFiles/tempFiles'
import Questions from './Questions'
export default function CertificateTest() {
    const [remainingTime, setRemainingTime] = useState(60 * 60);

    useEffect(() => {
        const timer = setInterval(() => {
          setRemainingTime((prevTime) => prevTime - 1);
        }, 1000);
    
        // Clear the interval when the component is unmounted or the timer reaches zero
        return () => clearInterval(timer);
      }, []);

    return(
        <>
        <CirtificateHeader />
            <div className='px-4 lg:px-10 py-4'>
                <div className="flex flex-col sm:flex-row gap-4 sm:gap-1 justify-between">
                    <div className="flex gap-1">
                        <Link to='/'><p>Home</p></Link>
                        <Link to='/certification_center'><p>/ Certificate Center</p></Link>
                        <p className='text-stone-500'>/ Certification Fill Form</p>
                    </div>
                    <Link to="/certification_center">
                        <div className='flex items-center'><BiChevronsLeft />Back to Certificate Center</div>
                    </Link>
                </div>
                    <h2 className='text-2xl font-medium'>WordPress Test View</h2>
            </div>
            <div className='flex flex-col md:flex-row py-10 gap-6 px-14 bg-stone-100'>
                <div className='md:sticky flex w-full lg:w-1/3 h-64 bg-white top-20'>
                    <div className='flex flex-col py-10 items-center border w-full md:w-1/2 justify-center itmes-center'>
                        <h4 className='h-24 w-24 font-semibold text-2xl flex border-2 border-slate-800 rounded-full justify-center items-center text-center'>{QuestionList.length}</h4>
                        <span className='text-sm pt-2'>Questions</span>
                    </div>
                    <div className='flex flex-col py-10 items-center w-full md:w-1/2  border justify-center itmes-center'>
                        <h4 className='w-24 h-24 flex font-semibold text-2xl border-2 border-slate-800 rounded-full justify-center items-center text-center'>{FormatTime(remainingTime)}</h4>
                        <span className='text-sm pt-2'>Minutes</span>
                    </div>
                </div>
                <div className="flex flex-col pb-10">
                    <Questions questions={QuestionList} />
                </div>
            </div>
        <Footer />
        </>
    )
}