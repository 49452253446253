import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { IndividualNotificationMessage } from "./IndividualNotMess";
import AppContext from '../Context/AppContext';
import { Links } from './links';
import { notifications } from '../tempFiles/tempFiles';
import { ProfileDropDown } from './DropDown';
import { MessageData } from '../tempFiles/tempFiles';
import { BsArrowRight } from 'react-icons/bs';
export default function NavbarLinks() {
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const { isBrightMode, setIsBrightMode } = useContext(AppContext);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsMenuOpen(null);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const toggleMenu = (index) => {
    if (index === isMenuOpen) {
      setIsMenuOpen(null);
    } else {
      setIsMenuOpen(index);
    }
  };

  const handleNightMode = () => {
    setIsBrightMode(!isBrightMode);
  };

  return (
    <div
    ref={dropdownRef}
    className="flex items-center justify-end md:gap-3 md:mr-4">
      {Links.map((link, index) => (
        /* check if it has dropdown value */
        !link.dropVal ? (
          <li key={index} className='md:ml-6 md:text-xl md:my-0'>
            <Link to={link.link} className='text-gray-800 hover:text-gray-400 duration-500'>{link.name}</Link>
          </li>
        ) : (
          <div key={index} className="relative">
            <li key={link.name} className='md:text-xl md:my-0'>
              <button onClick={() => toggleMenu(index)} className="text-gray-800 hover:text-gray-400 duration-500">{link.name}</button>
            </li>
            {isMenuOpen === index && (
            <div>
                {/* when dropdown is profile */}
                {index === 3 &&
                  <div className={`absolute flex flex-col gap-3 right-0 mt-2 text-sm ${isBrightMode ? "bg-white" : "bg-stone-700 text-white"}  rounded-lg shadow-lg w-56`}>
                    <ProfileDropDown isBrightMode={isBrightMode} handleNightMode={handleNightMode} />
                    <div>
                      {link.dropVal.map((val, i) => (
                        <Link key={i} to={`/${val[1]}`} className={`block px-4 py-2 ${isBrightMode ? "text-gray-800 hover:bg-red-100" : "text-white hover:bg-stone-600"}`}>{val[0]}</Link>
                      ))}
                      </div>
                      </div>
                    }
                <div className={`absolute flex flex-col right-0 mt-2 text-sm translate-x-7 md:translate-x-0 translate-y-1 ${isBrightMode ? "bg-white" : "bg-stone-700 text-white"}  rounded-lg shadow-lg w-72`}>
                  {/* when drop down is message */}
                {(index === 1) && (
                  <div>
                    {MessageData.map((message, i) => (
                      <div key={i} className={`flex gap-2 ${isBrightMode ? "bg-white px-2 hover:bg-slate-100" : "text-white"} hover:cursor-pointer ${i > 0 && "border-t"} ${isBrightMode ? "border-t-stone-200":"border-t-black"} pt-2 pb-2 pl-3 translate-y-1 z-[1]`}>
                        <IndividualNotificationMessage items={message} />
                      </div>
                    ))}
                  </div>
                )}
                {/* when drop down is notifications */}
                {(index === 2) && (
                  <div>
                    {notifications.map((notification, i) => (
                      <div key={i} className={`flex gap-2 ${isBrightMode ? "bg-white px-2 hover:bg-slate-100" : "text-white"} hover:cursor-pointer ${i > 0 && "border-t"} ${isBrightMode ? "border-t-stone-200":"border-t-black"} pt-2 pb-2 pl-3 translate-y-1 z-[1]`}>
                        <IndividualNotificationMessage items={notification} />
                      </div>
                    ))}
                    
                  </div>
                )}
                {/* common for messages and notifications */}
                {(index === 1 || index === 2) &&
                <div className="">
                      {link.dropVal.map((val, i) => (
                        <Link key={i} to={`/${val[1]}`} className={`flex items-center justify-center h-14 gap-1 ${isBrightMode ? "bg-red-100":"bg-red-500"} hover:bg-stone-700 hover:text-white font-medium ${i > 0 && "border-t"} ${isBrightMode ? "border-t-stone-200":"border-t-black"} ${isBrightMode ? "text-gray-800 hover:bg-red-100" : "text-white hover:bg-stone-600"}`}>
                          <span>{val[0]}</span>
                          <BsArrowRight size={13}/>
                        </Link>
                      ))}
                    </div>}
              </div>
              </div>
            )}
            
          </div>
        )
      ))}
    </div>
  );
}
