import {notifications} from '../tempFiles/tempFiles';
import { MessageData } from '../tempFiles/tempFiles';
const profile = require("../assets/avatar.png")
let Links = [
    {name: <div className='hidden sm:inline relative'>
             <ion-icon name="cart-outline" ></ion-icon>
             <span className="badge ">7</span>
          </div>
      ,link:"shopping_cart", dropVal:null},
    
    {name: <div className='relative'>
              <ion-icon name="mail-outline"></ion-icon>
              <span className="badge ">{MessageData.length}</span>
          </div>
      ,link:"/", dropVal:[
        ["View All", "instructor_messages"]]},
    
    {name:<div className='relative'>
             <ion-icon name="notifications-outline"></ion-icon>
              <span className="badge ">{notifications.length}</span>
          </div>
      ,link:"/", dropVal:[
        ["View All", "instructor_notifications"]]},
    
    {name:
    <img src={profile}  className="w-9 inline pb-3 "alt="" />
    ,link:"/", dropVal:[
    ["Cursus dashboard", "instructor_dashboard"],
    ["Paid Memberships","membership"],
    ["Setting", "setting"],
    ["Help", "help"],
    ["Send Feedback", "feedback"],
    ["Sign Out", "sign_in"],
  
  ]},
  ];
  export {Links}