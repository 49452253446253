import { React, useState} from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { GrAchievement } from "react-icons/gr";
import { BiBook } from "react-icons/bi";
import CoursePerformance from "./CoursePerformance";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";

const Dashbord = () => {
  const [isMenuOpen, setMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className=" bg-[#F7F7F7] w-full desktop-l:h-screen ">
    <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

<DashSidebar isMenuOpen={isMenuOpen} /> 
    <div
      className={`relative ml-5 mr-10  ${
        isMenuOpen ? "lg:ml-64" : "lg:ml-5"
      }`}
    >
      <div className=" p-4 flex gap-3 items-center text-[20px] font-[500] text-[#333333] pt-[80px]">
        <AiOutlineAppstore size={29} />
        <h2> Instructor Dashbaord</h2>
      </div>
      <div className=" grid grid-cols-1 md:grid-cols-4  bg-[#F7F7F7] desktop:grid-cols-4 gap-5 ml-3 mr-3 w-full mb-8">
        <div className=" bg-white w-full  flex justify-between p-3 ">
          <div className=" mx-3 flex flex-col  gap-3  ">
            <h5
              className=" text-[16px] font-medium text-[#333333]
             |"
            >
              Top Sales
            </h5>
            <h2 className=" text-2xl font-medium"> $350</h2>
            <span className="bg-[#FFC136] px-[1px] py-[2px] rounded  text-center text-xs font-medium text-white">
              New $50
            </span>
          </div>
          <div className=" mt-[20%] mx-2 text-[#FFC136]">
            <GrAchievement size={30} />
          </div>
        </div>
        <div className=" bg-white w-full  flex justify-between p-3 ">
          <div className=" mx-3 flex flex-col  gap-3  ">
            <h5
              className=" text-[16px] font-medium text-[#333333]
             |"
            >
              Top Sales
            </h5>
            <h2 className=" text-2xl font-medium"> $350</h2>
            <span className="bg-[#FFC136] px-[1px] py-[2px] rounded  text-center text-xs font-medium text-white">
              New $50
            </span>
          </div>
          <div className=" mt-[20%] mx-2 text-[#FFC136]">
            <GrAchievement size={30} />
          </div>
        </div>
        <div className=" bg-white w-full  flex justify-between p-3 ">
          <div className=" mx-3 flex flex-col  gap-3  ">
            <h5
              className=" text-[16px] font-medium text-[#333333]
             |"
            >
              Top Sales
            </h5>
            <h2 className=" text-2xl font-medium"> $350</h2>
            <span className="bg-[#FFC136] px-[1px] py-[2px] rounded  text-center text-xs font-medium text-white">
              New $50
            </span>
          </div>
          <div className=" mt-[20%] mx-2 text-[#FFC136]">
            <GrAchievement size={30} />
          </div>
        </div>
        <div className=" bg-white w-full  flex justify-between p-3 ">
          <div className=" mx-3 flex flex-col  gap-3  ">
            <h5
              className=" text-[16px] font-medium text-[#333333]
             |"
            >
              Top Sales
            </h5>
            <h2 className=" text-2xl font-medium"> $350</h2>
            <span className="bg-[#FFC136] px-[1px] py-[2px] rounded  text-center text-xs font-medium text-white">
              New $50
            </span>
          </div>
          <div className=" mt-[20%] mx-2 text-[#FFC136]">
            <GrAchievement size={30} />
          </div>
        </div>
      </div>
      <div className=" w-full h-20 ml-3  bg-white flex justify-between mb-8 items-center p-5">
        <div className=" text-2xl font-[400] text-[#333333] flex gap-2 items-center">
          <BiBook size={30} />
          Jump to Course Creation
        </div>
        <div className=" bg-red-500 px-3 py-2 text-center text-white rounded text-[14px] font-medium ">
          {" "}
          Create your Courses
        </div>
      </div>
      <div className=" w-full grid grid-cols-1 md:grid-cols-3 desktop:grid-cols-3 h-500px gap-8 ">
        <div className=" bg-[#F7F7F7] w-full  ml-3">
          <CoursePerformance />
        </div>
        <div className=" bg-[#F7F7F7] w-full  ml-3">
          <CoursePerformance />
        </div>
        <div className=" bg-[#F7F7F7] w-full  ml-3 flex flex-col gap-6 ">
          <div>
            <div className="text-[18px] font-medium px-8  mt-0">
              Profile Analytics
            </div>
            <div className=" w-full shadow-sm mb-6 ">
              <div className="w-full h-full  ">
                <div className="flex item-center w-full h-full bg-white flex-col   mr-5 border-[1px] border-solid border-[#efefef]  ">
                  <span className="    text-gray-500 hover:text-gray-400 mb-3 duration-500 flex justify-between  text-[18px] ml-8 mt-6">
                    Current subscribers
                  </span>
                  <span className="text-3xl  font-[500] ml-8 block  mb-3 ">
                    856
                  </span>
                  <div className=" border-t-[1px] border-solid border-[#efefef] ml-3 mr-3">
                    <ul className=" flex flex-col justify-between">
                      <li className="mb-3 text-[14px] flex justify-between pl-1 pb-1 pr-1 mt-3">
                        view <span>2k</span>
                      </li>
                      <li className="mb-3 text-[14px]  flex justify-between p-1">
                        purchased <span>150</span>
                      </li>
                      <li className="mb-3 text-[14px]  flex justify-between p-1">
                        total like <span>1k</span>
                      </li>
                    </ul>
                  </div>
                  <div className="border-t-[1px] border-solid border-[#efefef] flex flex-col w-full mb-3 ml-3">
                    <p className="text-[14px] font-medium mb-2 mt-3  ">
                      Go To Course Analytics{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className=" w-full  mb-6 ">
              <div className="w-full h-full">
                <div className=" text-[18px] font-medium text-[#333] mb-[24px]">
                  {" "}
                  Submit Course
                </div>
                <div className="flex item-center w-full h-full flex-col shadow-sm bg-white p-[10px] gap-2     ">
                  <span className=" text-[16px]  font-[500] text-[#333333]  leading-6   ">
                    The Complete Javascript Course 2023: Build Real Projects!
                    <span className=" bg-red-500 p-[2px] align-middle ml-2 text-left pt-0 text-xs font-medium text-white rounded-sm">
                      pending
                    </span>
                  </span>
                  <div className="  ">
                    <p className=" text-[#686f7a] font-[400] text-[14px] italic">
                      Submitted 1 day ago
                    </p>
                  </div>
                  <div className=" ">
                    <p className=" text-[#333] font-[400] text-[14px] ">
                      Delete
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full mb-6">
            <div className="w-full   ">
              <div className="text-[18px] font-medium text-[#333] mb-6">
                What's new in Edututs+
              </div>
              <div className="flex item-center w-full h-full bg-white flex-col gap-3 p-[10px]  shadow-sm rounded-sm    mr-5 border-[1px] border-solid border-[#efefef]  ">
                <div className="border-b-[1px] border-dashed  pb-3 border-[#efefef]  w-full text-[#333] font-[400] text-[14px]  ">
                  <p className=" hover:underline cursor-pointer ">
                    {" "}
                    Improved Performance on Studio Dashboard
                  </p>
                </div>
                <div className="border-b-[1px] border-dashed pb-3 border-[#efefef] text-[#333] font-[400] text-[14px] w-full ">
                  <p className=" hover:underline cursor-pointer ">
                    {" "}
                    See more Dashboar Updates
                  </p>
                </div>
                <div className="border-b-[1px] border-dashed   border-[#efefef] text-[#333] font-[400] text-[14px] w-full ">
                  <p className=" hover:underline cursor-pointer ">
                    {" "}
                    See isues-at-glance for live
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Dashbord;
