import React, { useState } from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { BiBook } from "react-icons/bi";

import Discount from "./Discount";
import { FaEdit, FaTrash } from "react-icons/fa";

import Tabel from "./Tabel";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";

const Courses = () => {
    const [isMenuOpen, setMenuOpen] = useState(true);

    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  const data2 = [
    ["John Doe", 30, "Engineer", "Male", "USA", "Single", "English"],
    ["Jane Smith", 28, "Designer", "Female", "Canada", "Married", "French"],
    ["Bob Johnson", 35, "Developer", "Male", "Australia", "Single", "English"],
    ["Bob Johnson", 35, "Developer", "Male", "Australia", "Single", "English"],
    ["Bob Johnson", 35, "Developer", "Male", "Australia", "Single", "English"],
    // Add more rows here...
  ];

  const header2 = [
    "Name",
    "Age",
    "Occupation",
    "Gender",
    "Country",
    "Marital Status",
    "Language",
  ];
  const actionIcons = [
    {
      icon: <FaEdit />,
      onClick: (rowIndex) => {
        // Handle edit action for the row at the given index
        console.log("Edit row:", rowIndex);
      },
    },
    {
      icon: <FaTrash />,
      onClick: (rowIndex) => {
        // Handle delete action for the row at the given index
        console.log("Delete row:", rowIndex);
      },
    },
  ];

  const Source = ({ id }) => (
    <>
      {id === 1 && (
        <Tabel data={data2} header={header2} actionIcons={actionIcons} />
      )}
      {id === 2 && (
        <Tabel data={data2} header={header2} actionIcons={actionIcons} />
      )}
      {id === 3 && (
        <Tabel data={data2} header={header2} actionIcons={actionIcons} />
      )}
      {id === 4 && (
        <div>
          <Discount />

          
        </div>
      )}
      {id === 0 && (
        <div>
          <Tabel data={data2} header={header2} actionIcons={actionIcons} />
        </div>
      )}
    </>
  );
  const [media, setMedia] = useState(0);
  const itemsCourse = [
    "HTML5(mp4)",
    "External URL",
    "YouTube",
    "Vimeo",
    "embedded",
  ];

  const changeMedia = (index) => {
    setMedia(index);
  };
  return (
    <div className=" bg-[#F7F7F7] w-full min-h-[700px]">
   <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

<DashSidebar isMenuOpen={isMenuOpen} /> 
      <div
        className={`relative ml-5 mr-10  ${
          isMenuOpen ? "lg:ml-64" : "lg:ml-5"
        }`}
      >
        <div className=" p-4 flex gap-3 items-center text-[20px] font-[500] text-[#333333 pt-[80px]  ]">
          <AiOutlineAppstore size={29} />
          <h2> Instructor Dashbaord</h2>
        </div>
        <div className=" w-full h-20 ml-3  bg-white flex justify-between mb-8 items-center p-5">
          <div className=" text-2xl font-[400] text-[#333333] flex gap-2 items-center">
            <BiBook size={30} />
            Jump to Course Creation
          </div>
          <div className=" bg-red-500 px-3 py-2 text-center text-white rounded text-[14px] font-medium ">
            {" "}
            Create your Courses
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-6 w-full">
            <div className="grid grid-cols-1  sm:grid-cols-2 sm:gap-2 lg:grid-cols-5  ml-3   w-full ">
              {itemsCourse.map((item, index) => (
                <div
                  onClick={() => {
                    changeMedia(index);
                  }}
                  className={`flex hover:cursor-pointer hover:bg-red-500 hover:text-white  items-center
                   h-12 w-full text-1xl rounded-md justify-center
                  ${
                    media === index
                      ? "bg-red-500 text-white"
                      : "bg-white text-black"
                  }
                  `}
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>

            <Source id={media} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
