import { React, useState,useEffect } from "react"
import BasicInformation from "./basicinfo";
import Curriculum from "./curriculum";
import Media from "./media";
import Price from "./price";
import Publish from "./publish";
import { MdOutlineShowChart } from "react-icons/md";
import CommonFormat from "./commonFormat";


export default function CreateCourse() {
    const [activeStep, setActiveStep] = useState(1);
    const [contentComponent, setContentComponent] = useState(<BasicInformation />);
    useEffect(() =>{
        // Update the content component based on the step
        switch (activeStep) {
            case 1:
                setContentComponent(<BasicInformation />);
                break;
            case 2:
                setContentComponent(<Curriculum />);
                break;
            case 3:
                setContentComponent(<Media />);
                break
            case 4:
                setContentComponent(<Price />);
                break
            case 5:
                setContentComponent(<Publish />);
                break
            default:
                break;
        }
    },[activeStep])
    const handleStepClick = (step) => {
        setActiveStep(step);
      };
      
      const CircleProgress = ({ step }) => (
        
        <div
        className={`w-10 h-10 flex justify-center  items-center text-center bg-opacity-80 rounded-full bg-gray-200 mr-4 cursor-pointer ${
            step <= activeStep ? "bg-red-300" : ""
          } z-[10]`}
          onClick={() => handleStepClick(step)}
          >
            <div
              className={`w-6 h-6 rounded-full z-[4]  cursor-pointer ${
                step <= activeStep ? "bg-red-500" : "bg-gray-300"
              }`}
              
            ></div>
        </div>
      );
      const ProgressLine =({check}) => (
        <div
          className={`flex h-2 w-1/5  ml-2 ${
            activeStep > check ? "bg-red-500" : "bg-gray-300"
          } absolute z-[2]`}
        ></div>
      );

    return(
        <div className="bg-stone-100">
        <CommonFormat>
                    <div className="flex gap-1">
                        <div className="pt-1">
                            <MdOutlineShowChart size={18}/>
                        </div>
                        <p className="font-semibold text-xl">Create New Course</p>
                    </div>
                    <div className="flex pt-8 w-full justify-between">
                        <div
                         className="flex w-8/12 sm:w-10/12 justify-between md:pl-14 space-x-4">
                          <div className="flex items-center">
                                <CircleProgress step={1} />
                                <ProgressLine check={1} />
                          </div>
                          <div className="flex items-center">
                                <CircleProgress step={2} />
                                <ProgressLine check={2} />
                          </div>
                          <div className="flex items-center">
                                <CircleProgress step={3} />
                                <ProgressLine check={3} />
                          </div>
                          <div className="flex items-center">
                                <CircleProgress step={4} />
                                <ProgressLine check={4} />
                          </div>
                                  <div className="flex items-center">
                                      <CircleProgress step={5} />
                                  </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-between">
                        <div
                         className="flex w-8/12 md:w-10/12 justify-between md:pl-14 space-x-4">
                            <p className={`${activeStep >= 1 ? "text-black":"text-stone-400"}`}>Basic</p>
                            <p className={`${activeStep >= 2 ? "text-black":"text-stone-400"}`}>Curriculum</p>
                            <p className={`${activeStep >= 3 ? "text-black":"text-stone-400"} pr-4`}>Media</p>
                            <p className={`${activeStep >= 4 ? "text-black":"text-stone-400"} `}>Price</p>
                            <p className={`${activeStep >= 5 ? "text-black":"text-stone-400"}`}>Publish</p>
                            </div>
                        </div>
                    <div className="pl-1 pt-10 md:pr-6 w-full">
                        {contentComponent}
                        <div className={`flex ${activeStep > 1 && "justify-between"}`}>
                            <div
                            onClick={() =>{setActiveStep(activeStep - 1)}}
                            className="pt-16 pb-16">
                                <button className={`${activeStep === 1 && 'hidden'} transition-all
                                duration-400 font-semibold
                                text-stone-100 text-center transition-500 bg-redbutton
                                rounded-sm w-20 h-9 hover:cursor-pointer hover:bg-slate-700`}>Previous</button>
                            </div>
                            {activeStep === 5 ?
                            (
                                <div
                            onClick={() =>{alert('wizard completed')}}
                            className="pt-16 pb-16">
                                <button className={` transition-all
                                duration-400 font-semibold
                                text-stone-100 text-center transition-500 bg-redbutton
                                rounded-sm px-2 h-9 hover:cursor-pointer hover:bg-slate-700`}>Submit For Review</button>
                            </div>
                            ):(
                                <div
                            onClick={() =>{setActiveStep(activeStep + 1)}}
                            className="pt-16 pb-16">
                                <button className={` transition-all
                                duration-400 font-semibold
                                text-stone-100 text-center transition-500 bg-redbutton
                                rounded-sm w-16 h-9 hover:cursor-pointer hover:bg-slate-700`}>Next</button>
                            </div>) }
                            
                        </div>
                    </div>
                
                            
            </CommonFormat>
        </div>
    )
}
