import { HiOutlineInformationCircle } from "react-icons/hi"
import { Link } from "react-router-dom"
export default function ApiClients() {
    return (
        <>
            <div className="flex flex-col gap-5">
                <h4 className="text-xl font-medium">Affiliate API</h4>
                <p className="text-stone-500">The Cursus Affiliate API exposes functionalities of Cursus to help developers build client applications and integrations with Cursus.</p>
                <p className="text-stone-500">To see more details, please visit <Link className="text-blue-600">Cursus Affiliate API</Link></p>
                <div><button class="bg-white px-2 py-1 rounded-sm hover:bg-stone-600 hover:text-white border">Request Affiliate API Client</button></div>
                <div class="flex gap-1 items-center">
                    <HiOutlineInformationCircle color="red"/>
                    <p>You don't have any API clients yet.</p>
                </div>
            </div>
        </>
    )
}