import React from "react";
import 'tailwindcss/tailwind.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaRegCheckCircle } from "react-icons/fa";

function ProfileComponent({key, tog, user}) {
    return(
      <div key={key} className="flex flex-col items-center justify-center gap-2">  
        <img
        src={user.image}
        alt={user.name}
        class="rounded-full w-20 flex radius-100"
      />
      <p class="text-x font-semibold flex gap-1">
        {user.name}
        <FaRegCheckCircle className="text-blue-500 pt-1" size={22}/>
      </p>
      <p>{user.profidentity}</p>
      <div class="p-4 flex gap-2 items-center">
        <a
        class="hover:size-35"
        href={user.socials.facebook}>
          <FacebookIcon
          className="icon text-blue-800 rounded-sm hover:scale-110 transition duration-700"
          style={{ fontSize: 40}}
          />
        </a>
        <a href={user.socials.twitter}>
          <TwitterIcon
          className="icon bg-[rgb(26,198,228)] rounded-sm text-blue-100 hover:scale-110 transition duration-700"
          style={{ fontSize: 30 }}
          />
        </a>
        <a href={user.socials.linkedin}>
          <LinkedInIcon
          className="icon text-purple-600 hover:scale-110 transition duration-700"
          style={{ fontSize: 40, transition: 2}}
          />
        </a>
        <a href={user.socials.youtube}>
          <YouTubeIcon
          className="icon bg-red-600 rounded-sm text-blue-100 hover:scale-110 transition duration-700"
          style={{ fontSize: 30 }}
          />
        </a>
      </div>
      <div class="flex gap-2 font-thin text-sm">
        <p>{user.enrolled} Students</p>
        <p>{user.course} Courses</p>
      </div>
      </div>
    )
}

export default ProfileComponent;