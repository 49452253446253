import React, { useRef, useState, useEffect } from "react";

export default function Slider({ children, classProp }) {
  const sliderRef = useRef(null);
  const cardRef = useRef(null);
  const [scrollDistance, setScrollDistance] = useState(0);

  useEffect(() => {
    // Find the width of a single card (assuming all cards have the same width)
    const cardWidth = cardRef.current?.children[0]?.offsetWidth || 0;
    setScrollDistance(cardWidth);
  }, []);

  const sliderLeft = () => {
    const slider = sliderRef.current;
    slider.scrollTo({
      left: slider.scrollLeft - scrollDistance,
      behavior: "smooth",
    });
  };

  const sliderRight = () => {
    const slider = sliderRef.current;
    slider.scrollTo({
      left: slider.scrollLeft + scrollDistance,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="relative flex items-center w-full transition-all duration-600">
        <span
          onClick={sliderLeft}
          className={`opacity-50 cursor-pointer hover:opacity-100 py-2 px-2 ${classProp[0]} bg-white flex items-center  hover:bg-danger hover:text-white`}
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </span>
        <div
          ref={sliderRef}
          className="w-full overflow-x-hidden whitespace-nowrap"
          style={{ scrollSnapType: "x mandatory" }}
        >
          <div
            className={`flex gap-4 w-max scroll-smooth scroll-snap scroll-snap-type-y scroll-snap-points-x-[0, 50, 100]`}
            ref={cardRef}
          >
            {children}
          </div>
        </div>
        <span
          onClick={sliderRight}
          className={`hover:bg-danger hover:text-white bg-dark opacity-50 bg-white py-2 px-2 flex items-center ${classProp[1]} shadow-lg cursor-pointer hover:opacity-100`}
        >
          <ion-icon
            name="chevron-forward-outline"
            className="opacity-50 cursor-pointer hover:opacity-100"
          ></ion-icon>
        </span>
      </div>
    </>
  );
}
