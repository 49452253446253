import React from 'react';
import CollapsiblePanel from './collapsed';

const Second_accordion = () => {
    return (
        <div className="bg-white w-full float-left p-4 md:p-10 rounded-md border border-gray-300 shadow-sm transition-all duration-200 ease-in-out mt-12 mb-7">
            <div className=" float-right w-full mt-4 accordion" id="accordion2">
                <CollapsiblePanel
                    id="One2"
                    title="Is it easy to change plans?"
                    content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS."
                />
                <CollapsiblePanel
                    id="Two2"
                    title="Can I cancel at any time?"
                    content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
                />
                <CollapsiblePanel
                    id="Three"
                    title="Can I cancel at any time?"
                    content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
                /><CollapsiblePanel
                    id="four"
                    title="Can I cancel at any time?"
                    content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
                /><CollapsiblePanel
                    id="five"
                    title="Can I cancel at any time?"
                    content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
                /><CollapsiblePanel
                    id="six"
                    title="Can I cancel at any time?"
                    content="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid."
                />
            </div>
        </div>
    );
};

export default Second_accordion;