import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import CommonFormat from "./commonFormat";
import authimage from "../../assets/verification.png"
import measure from "../../assets/measure.png"
import DashNavbar from "../Dashbord/DashNavbar";
import DashSidebar from "../Dashbord/DashSidebar";
export default function Verification() {
    return(
        <>
            <CommonFormat>
                <div className="flex flex-col w-full pb-10">
                    <div className="flex gap-1">
                        <FaRegCheckCircle size={22} className="pt-1"/>
                        <h2 className="text-xl font-medium">Verification</h2>
                    </div>
                    <div className="flex flex-col items-center text-center gap-5">
                        <img src={authimage} alt="person with verification" className="h-34 w-34"/>
                        <h4 className="text-3xl font-medium">Verification with Edututs+</h4>
                        <p className="w-1/2">Praesent sed sapien gravida, tempus nunc nec, euismod turpis. Mauris quis scelerisque arcu. Quisque et aliquet nisl, id placerat est. Morbi quis imperdiet nulla.</p>
                        <div className="flex">
                            <div className="flex pl-3 w-64 h-30 items-center bg-white border gap-2 text-start">
                                <img src={measure} alt="show it is a mesurement" className="h-8 w-8"/>
                                <div className="flex flex-col">
                                    <strong>14 Subscribers</strong>
                                    <span>500 required</span>
                                </div>
                            </div>
                            <div className="flex pl-3 w-64 pt-4 pb-4 items-center bg-white border gap-2 text-start">
                                <img src={measure} alt="show it is a mesurement" className="h-8 w-8"/>
                                <div className="flex flex-col">
                                    <strong>10 public watch hours</strong>
                                    <span>1,500 required</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-1">
                            <FaRegCheckCircle className="pt-1" size={20}/>
                            We'll send you an email when you're eligible to apply
                        </div>
                    
                            <div className="flex w-1/2 flex-col py-10 items-center gap-10 border bg-white">
                                <h4 className="text-xl font-medium">Verify Your ID</h4>
                                <div className="flex flex-col w-4/5 items-start gap-5">
                                    <label for="coursetitle" className="flex w-full flex-col items-start pl-3">
                                        Course Title*
                                        <input type="text" name="coursetitle" placeholder="Full Name"
                                        className="pl-4 h-8 border w-full border-stone-300 focus:outline-none"/>
                                    </label>
                                    <label for="docupload" className="">Upload Document*</label>
                                    <div className="flex relative justify-center items-center text-center w-full bg-white">
                                        <label
                                          htmlFor="docupload"
                                          className="border flex justify-between items-center h-10 border-red-500 text-red-600 py-2 w-full rounded-md bg-white"
                                        >
                                          <span className="w-9/12 flex justify-start pl-2">No Choose</span>
                                          <span className="w-3/12 bg-red-500 flex items-center justify-center text-white h-10">Browse</span>
                                        </label>
                                        <input
                                          type="file"
                                          id="docupload"
                                          accept="image/jpg, image/jpeg, image/png"
                                          className="opacity-0 w-full absolute inset-0 z-[-1]"
                                        />
                                    </div>
                                    <button className="flex items-center justify-center w-full text-white bg-red-500 pl-3 pr-3 h-10">Submit Now</button>
                                </div>
                            </div>

                        
                    </div>
                </div>
            </CommonFormat>
        </>
    )
}