import React from "react";
import {LiaLessThanSolid , LiaGreaterThanSolid} from 'react-icons/lia'

import Couse from '../../assets/couse.png';

const CoursePerformance = () => {
  let Courses = [
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
    {
      courseOwner: "John Doe",
      name: "Complete Python BotCamp : Gor frin zero to hero in Python 3",
      action: "109k views  .  15 days ago",
      development: "web development| python",
      price: "$5",
    },
  ];
  const sliderLeft = () => {
    var slider = document.getElementById("newCourseSlider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const sliderRight = () => {
    var slider = document.getElementById("newCourseSlider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };
  return (
    <>
      <div className="text-[18px] font-medium ml-1 w-full   mt-0 flex justify-between items-center">
      <div className="text-[#333333]">Latest course performance</div>
       <div className=" flex gap-1 items-center"><span
          onClick={sliderRight}
          className=" hover:bg-red-500   cursor-pointer bg-white rounded p-1"
        >
          
          <LiaLessThanSolid/>
        </span>
        
        <span
          onClick={sliderLeft}
          className=" cursor-pointer  hover:bg-red-500 bg-white rounded p-1"
        >
          <LiaGreaterThanSolid/>
        </span></div>
      </div>

      <div className="relative flex items-center ">
       
        <div
          id="newCourseSlider"
          className="w-full h-full overflow-x-hidden scroll whitespace-nowrap scroll-smooth scrollbar-hide"
        >
          {Courses.map((course) => (
            <div className=" m-3 p-2 shadow-sm w-full  inline-block cursor-pointer hover:scale-200 ease-in-out duration-300 bg-white">
              <div className=" w-full">
              <span className="text-center items-center flex justify-center w-full">
                <img
                  src={Couse}
                  width={280}
                  height={100}
                  className=" float-center "
                  alt=""
                />
              </span>
              <span className="text-gray-500 hover:text-gray-400 duration-500 flex  text-sm mb-3 w-full">
                {course.action}
              </span>
              
              <span className="text-sm block overflow-x-hidden mb-0 w-full">&nbsp;{course.name} </span>
                
              <div className="mt-0">
                <ul className=" flex flex-col justify-between">
                  <li className="mb-3 text-[14px] flex justify-between pl-1 pb-1 pr-1">
                    view <span>2k</span>
                  </li>
                  <li className="mb-3 text-[14px]  flex justify-between p-1">
                    purchased <span>150</span>
                  </li>
                  <li className="mb-3 text-[14px]  flex justify-between p-1">
                    total like <span>1k</span>
                  </li>
                </ul>
              </div>
              <div className="border-t-[1px] border-solid border-[#efefef] flex flex-col w-full mb-3">
                   <p className="text-[14px] font-medium mb-2 mt-3">Go To Course Analytics </p>
                   <p className="text-[14px] font-medium mb-2">See Commets (549)</p>
                   <p className="text-[14px] font-medium mb-2">See Commets (549)</p>
                   
               </div>
              </div>
            </div>
          ))}
        </div>
        
      </div>
    </>
  );
};

export default CoursePerformance;