import React from "react";
import { TextArea } from "../CreateCourse/TextArea";
import { TextInput } from "./inputfield";
import Button from "../Button";
export default function Account() {
    return (
        <div className="flex flex-col gap-3 px-2 py-3 pb-8">
            <h4 className="text-2xl font-medium">Your Cursus Account</h4>
            <p className="text-sm text-stone-600">This is your public presence on Cursus. You need a account to upload your paid courses, comment on courses, purchased by students, or earning.</p>
            <h4 className="text-xl font-medium">Basic Profile</h4>
            <p className="text-sm text-stone-600">Add information about yourself</p>
            <div className="flex flex-col lg:flex-row gap-6 lg:gap-1 lg:w-2/3 pb-3 lg:pb-0">
                <TextInput
                name={"firstName"}
                value={'user.first.name'}
                placeholder={""} />
                <TextInput
                name={'lastName'}
                value={'user.last.name'}
                placeholder={""} />
            </div>
           <div className="flex lg:w-2/3 border border-solid justify-between">
                <input type="text" name="skill" id="skill"
                placeholder="user.skill" className="w-11/12 pl-2 border py-2 border-white focus:border-white focus:outline-none text-sm"></input>
                <div className="flex border w-1/12 justify-center items-center text-center text-white font-semibold bg-stone-500">36</div>
            </div>
            <div className="text-sm text-stone-600">Add a professional headline like, "Engineer at Cursus" or "Architect."</div>
            <TextArea
                        label={""}
                        name={"accountdesc"}
                        placeholder={"Write a little description about you..."} />
            <div className="text-sm text-stone-600">Links and coupon codes are not permitted in this section.</div>            
            <hr />
            <h4 className="font-medium">Profile Links</h4>
            <div className="flex flex-col gap-3">
                <div className="flex border h-10 w-full">
                    <div className="bg-stone-200 px-2 h-full flex items-center cursor-default">https://</div>
                    <TextInput
                    name={"weblink"}
                    placeholder={""}
                    value={"gambolthemes.net"}
                    />
                </div>

                <div className="flex border h-10 w-full">
                    <div className="bg-stone-200 px-2 h-full flex items-center cursor-default">http://facebook.com/</div>
                    <TextInput
                    name={"facebookProfile"}
                    placeholder={"Facebook Profile"}
                    value={""}
                    />
                </div>
                    <div>Add your Facebook username (e.g. johndoe).</div>
                <div className="flex border h-10 w-full">
                    <div className="bg-stone-200 px-2 h-full flex items-center cursor-default">http://twitter.com/</div>
                    <TextInput
                    name={"twitterProfile"}
                    placeholder={"Twitter Profile"}
                    value={""}
                    />
                </div>
                    <div>Add your Twitter username (e.g. johndoe).</div>
                <div className="flex border h-10 w-full">
                    <div className="bg-stone-200 px-2 h-full flex items-center cursor-default">http://www.linkedin.com/</div>
                    <TextInput
                    name={"linkedinProfile"}
                    placeholder={"Linkedin Profile"}
                    value={""}
                    />
                </div>
                    <div>Input your LinkedIn resource id (e.g. in/johndoe).</div>
                <div className="flex border h-10 w-full">
                    <div className="bg-stone-200 px-2 h-full flex items-center cursor-default">http://www.youtube.com/</div>
                    <TextInput
                    name={"youtubeProfile"}
                    placeholder={"Youtube Profile"}
                    value={""}
                    />
                </div>
                    <div>Input your Youtube username (e.g. johndoe).</div>
            </div>
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
                <Button>Save Changes</Button>
            </div>
        </div>
    )
}