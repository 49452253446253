import { TextInput } from "./inputfield"
export default function CloseAccount() {
    return (
        <>
            <div className="flex flex-col gap-6 pb-6">
                <h4 className="text-xl font-medium">Close account</h4>
                <div className="text-stone-800">Warning: <p className="text-stone-500">If you close your account, you will be unsubscribed from all your 5 courses, and will lose access forever.</p></div>
                <div className="w-72">
                    <TextInput
                    name={"password"}
                    placeholder={"Enter Your Password"}
                    value={""} />
                <p className="text-sm text-stone-500">Are you sure you want to close your account?</p>
                </div>
            </div>
            <button className="px-2 py-1 rounded-sm flex items-center bg-redbutton text-white hover:bg-stone-600 font-semibold">Close Account</button>
        </>
    )
}