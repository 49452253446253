import React, { useState } from "react";
import RepeatedComponent from "./RepeatedComponent";
import RepeatedHeader from "./RepeatedHeader";
import { useEffect } from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import Footer from "../../components/Footer";
const Careers = () => {
  const [indexNum, setIndexNum] = useState(0);
  const [presentItem, setPresentItem] = useState([]);
  console.log(presentItem);
  const data = [
    {
      image: "https://lms.dalloltech.com/images/about/career-1.jpg",
      heading: "We are learners",
      discreption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget enim in turpis consequat tempor sed id neque. Nam at felis et elit auctor accumsan. Nunc at tortor tellus. Cras dignissim velit velit, ac sollicitudin mi bibendum in. In vel nibh sodales, venenatis eros a, vulputate ligula. ",
    },
    {
      image: "https://lms.dalloltech.com/images/about/career-1.jpg",
      heading: "We are Navigator",
      discreption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget enim in turpis consequat tempor sed id neque. Nam at felis et elit auctor accumsan. Nunc at tortor tellus. Cras dignissim velit velit, ac sollicitudin mi bibendum in. In vel nibh sodales, venenatis eros a, vulputate ligula. ",
    },
    {
      image: "https://lms.dalloltech.com/images/about/career-1.jpg",
      heading: "We are Global",
      discreption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget enim in turpis consequat tempor sed id neque. Nam at felis et elit auctor accumsan. Nunc at tortor tellus. Cras dignissim velit velit, ac sollicitudin mi bibendum in. In vel nibh sodales, venenatis eros a, vulputate ligula. ",
    },
    {
      image: "https://lms.dalloltech.com/images/about/career-1.jpg",
      heading: "We make an impact",
      discreption:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget enim in turpis consequat tempor sed id neque. Nam at felis et elit auctor accumsan. Nunc at tortor tellus. Cras dignissim velit velit, ac sollicitudin mi bibendum in. In vel nibh sodales, venenatis eros a, vulputate ligula. ",
    },
  ];
  useEffect(() => {
    setPresentItem([data[indexNum]]);
  }, [indexNum]);
  return (
    <>
      <div>
        <RepeatedHeader />
        <RepeatedComponent />
        <div className="py-10">
          <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10">
            <div class="grid grid-cols-1 md:grid-cols-2 ">
              <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10">
                <iframe
                  width="100%"
                  height="300px"
                  src="https://www.youtube.com/embed/TKnufs85hXk"
                ></iframe>
              </div>
              <div>
                <div>
                  <h2 className="text-xl font-semibold font-roboto mb-1 text-left text-gray-700 leading-26">
                    Worker at cursus
                  </h2>
                </div>
                <div>
                  <img
                    className="  pt-5 mb-6"
                    src="https://lms.dalloltech.com/images/line.svg"
                    alt="line-svg"
                  />
                </div>
                <p className="text-base font-normal font-roboto text-left text-gray-600 leading-6 pt-27">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  consectetur vel dolor id ultrices. Proin a magna at mi pretium
                  pulvinar in eu enim. Nulla vel lacus lectus. Donec at
                  venenatis augue. Nam vitae purus placerat, hendrerit nisl id,
                  finibus magna. Etiam pharetra gravida ornare. Donec sagittis,
                  ipsum in egestas egestas, dui lorem sollicitudin justo, ut
                </p>
                <button className=" mt-10 inline-block text-base font-medium font-roboto text-white bg-red-500 py-2 px-4 rounded-md">
                  Cheak Out Our Open role
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10  bg-gray-100">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10 relative">
          <div className="w-full">
            {presentItem.map((item, index) => (
              <div className="w-full">
                <div className="w-full flex justify-center">
                  <img src={item.image} alt="" />
                </div>
                <h2 className="text-center text-gray-700 font-medium text-lg mt-7 mb-17 font-roboto">
                  {item.heading}
                </h2>
                <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10  ">
                  <p className=" text-base font-normal font-roboto text-center text-gray-600 leading-6 pt-27">
                    {item.discreption}
                  </p>
                </div>
                <div className=" w-full h-full absolute top-0 left-0 flex justify-between items-center ">
                  <FaChevronCircleLeft
                    onClick={() =>
                      setIndexNum(indexNum ? indexNum - 1 : indexNum)
                    }
                  />
                  <FaChevronCircleRight
                    onClick={() =>
                      setIndexNum(
                        data.length === indexNum + 1 ? indexNum : indexNum + 1
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="py-10">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10">
          <div className=" w-full flex justify-center">
            <div>
              <h2 className="text-2xl font-semibold font-roboto mb-5 text-center text-gray-800 leading-6">
                Benefits
              </h2>
              <div className=" max-w-xl">
                <p className=" text-ellipsis font-normal font-roboto mb-0 block   text-center text-gray-600 leading-6 px-300">
                  Cursus culture is something special, and to complement and
                  support that culture we have some amazing benefits
                </p>
                <div className="w-full flex justify-center">
                  <img
                    className="  pt-5 mb-6"
                    src="https://lms.dalloltech.com/images/line.svg"
                    alt="line-svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4">
              <div className=" max-w-sm text-center mt-10 px-5">
                <i class="uil uil-mobile-android-alt"></i>
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Work from anywhere
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan.
                </p>
              </div>
              <div className=" max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Work and Travel
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-ellipsis leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan.
                </p>
              </div>
              <div className="max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Flexible Hours
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan.
                </p>
              </div>
              <div className="max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Purchasing Leave
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan
                </p>
              </div>

              <div className="max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Social Events
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan
                </p>
              </div>
              <div className="max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Wellness Program
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan
                </p>
              </div>
              <div className="max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Mentoring Program
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan
                </p>
              </div>
              <div className="max-w-md text-center mt-10 px-5">
                <h4 className=" text-lg font-medium font-roboto mb-3 mt-6 text-center text-gray-700">
                  Fundraising
                </h4>
                <p className=" text-sm font-normal font-roboto mb-0 text-gray-500 mt-0 text-center leading-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget enim in turpis consequat tempor sed id neque. Nam
                  at felis et elit auctor accumsan
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 bg-gray-100">
        <div className="w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto px-10">
          <div className=" w-full flex justify-center">
            <div>
              <h2 className="text-2xl font-semibold font-roboto mb-5 text-center text-gray-800 leading-6">
                Benefits
              </h2>
              <div className=" max-w-xl">
                <p className=" text-ellipsis font-normal font-roboto mb-0 block   text-center text-gray-600 leading-6 px-300">
                  Cursus culture is something special, and to complement and
                  support that culture we have some amazing benefits
                </p>
                <div className="w-full flex justify-center">
                  <img
                    className="  pt-5 mb-6"
                    src="https://lms.dalloltech.com/images/line.svg"
                    alt="line-svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
            <div className="w-full bg-white p-6 rounded-md h-48 flex flex-col items-center justify-center">
              <h4 className="text-center font-medium text-base text-gray-700 font-roboto leading-6 mb-4">
                Chief Technology Officer
              </h4>
              <p class="text-center font-normal text-sm text-gray-600 font-roboto mb-0">
                India
              </p>
              <button class="bg-red-500 text-white font-medium text-sm font-roboto py-2 px-4 rounded-md mt-5 inline-block">
                Your button text here
              </button>
            </div>

            <div className="w-full bg-white p-6 rounded-md h-48 flex flex-col items-center justify-center">
              <h4 className="text-center font-medium text-base text-gray-700 font-roboto leading-6 mb-4">
                Chief Technology Officer
              </h4>
              <p class="text-center font-normal text-sm text-gray-600 font-roboto mb-0">
                India
              </p>
              <button class="bg-red-500 text-white font-medium text-sm font-roboto py-2 px-4 rounded-md mt-5 inline-block">
                Your button text here
              </button>
            </div>

            <div className="w-full bg-white p-6 rounded-md h-48 flex flex-col items-center justify-center">
              <h4 className="text-center font-medium text-base text-gray-700 font-roboto leading-6 mb-4">
                Chief Technology Officer
              </h4>
              <p class="text-center font-normal text-sm text-gray-600 font-roboto mb-0">
                India
              </p>
              <button class="bg-red-500 text-white font-medium text-sm font-roboto py-2 px-4 rounded-md mt-5 inline-block">
                Your button text here
              </button>
            </div>
            <div className="w-full bg-white p-6 rounded-md h-48 flex flex-col items-center justify-center">
              <h4 className="text-center font-medium text-base text-gray-700 font-roboto leading-6 mb-4">
                Chief Technology Officer
              </h4>
              <p class="text-center font-normal text-sm text-gray-600 font-roboto mb-0">
                India
              </p>
              <button class="bg-red-500 text-white font-medium text-sm font-roboto py-2 px-4 rounded-md mt-5 inline-block">
                Your button text here
              </button>
            </div>
            <div className="w-full bg-white p-6 rounded-md h-48 flex flex-col items-center justify-center">
              <h4 className="text-center font-medium text-base text-gray-700 font-roboto leading-6 mb-4">
                Chief Technology Officer
              </h4>
              <p class="text-center font-normal text-sm text-gray-600 font-roboto mb-0">
                India
              </p>
              <button class="bg-red-500 text-white font-medium text-sm font-roboto py-2 px-4 rounded-md mt-5 inline-block">
                Your button text here
              </button>
            </div>
            <div className="w-full bg-white p-6 rounded-md h-48 flex flex-col items-center justify-center">
              <h4 className="text-center font-medium text-base text-gray-700 font-roboto leading-6 mb-4">
                Chief Technology Officer
              </h4>
              <p class="text-center font-normal text-sm text-gray-600 font-roboto mb-0">
                India
              </p>
              <button class="bg-red-500 text-white font-medium text-sm font-roboto py-2 px-4 rounded-md mt-5 inline-block">
                Your button text here
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};

export default Careers;
