 export const IndividualNotificationMessage = ({items}) => {
 
    return (
    <div className="w-full flex gap-2">
        
            <img
            className="w-11 h-11 rounded-full"
            src={items.profile} alt="profile of the commenter" />
            <div className="flex flex-col gap-2">
                <h3 className="font-medium">{items.name}</h3>
                {items.comment &&
                <>
                    <p>{items.comment}</p>
                    <p className="text-sm text-stone-400">{items.time} min ago</p>
                </>
                }
                {items.messages &&
                <>
                    <p>{items.messages[items.messages.length - 1][1]}</p>
                    <p className="text-sm text-stone-400">{items.time} min ago</p>
                </>}
            </div>
         
    </div> 
)}