import React from "react";

const Discussion = () => {
  return (
    <>
      <div className="w-full px-4 mx-auto max-h-full ">
        <div className="mt-4 mb-6 w-full">
          <div className=" max-w-5xl">
            <div className="">
              <h3 className="text-lg font-medium font-roboto text-left mb-4 text-gray-700 ">
                Discussion
              </h3>
            </div>
            <div className="w-full bg-white p-4 ">
              <div className="flex items-center ">
                <div className=" w-10">
                  <img
                    className=" w-full"
                    src="https://lms.dalloltech.com/images/hd_dp.jpg"
                    alt="pic"
                  />
                </div>
                <textarea
                  className=" ml-6 border border-gray-300 w-full overflow-hidden break-words resize-horizontal h-10 text-sm font-roboto font-normal text-gray-700 py-3 px-4 rounded-md bg-gray-100"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className=" flex justify-end">
                <button className=" mt-4 px-8 bg-red-500">Comment</button>
              </div>
            </div>
          </div>
          <div className="p-4 w-full bg-white  mt-8 max-w-5xl">
            <div className="flex ">
              <div className=" w-10 mr-2">
                <img
                  className=" w-full"
                  src="https://lms.dalloltech.com/images/left-imgs/img-1.jpg"
                  alt=""
                />
              </div>
              <div className=" ml-3">
                <h4 className="text-xl font-medium font-roboto text-gray-700 text-left mb-0">
                  John Doe
                </h4>
                <span className="text-sm font-normal font-roboto text-gray-600 block mt-2">
                  2 hours ago
                </span>
                <p className="text-sm font-normal font-roboto mt-4 leading-6 text-gray-600 text-left">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Explicabo nostrum natus dicta, dolor quaerat et reprehenderit
                  cum id fuga cumque iste repellat, tempora velit voluptate,
                  quod labore quia dignissimos doloremque!
                </p>
              </div>
            </div>
            <div className=" pl-12">
              <div className=" p-5">
                <div className=" flex">
                  <div className=" w-10">
                    <img
                      className=" w-full"
                      src="https://lms.dalloltech.com/images/left-imgs/img-3.jpg"
                      alt="pic"
                    />
                  </div>
                  <div className="">
                    <h4 className="text-xl font-medium font-roboto text-gray-700 text-left mb-0">
                      John Doe
                    </h4>
                    <span className="text-sm font-normal font-roboto text-gray-600 block mt-2">
                      1 hour ago
                    </span>
                    <p className="text-sm font-normal font-roboto mt-4 leading-6 text-gray-600 text-left">
                      Fusce lacinia, nunc sit amet tincidunt venenatis
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discussion;
