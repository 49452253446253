import React from "react";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaInstagram,
  FaFacebookF,
  FaPinterestP,
  FaMailBulk,
} from "react-icons/fa";

const currentYear = new Date().getFullYear();

export default function CourseFooter({isMenuOpen}) {
  const Iconmap = ({ items }) =>
    items.map((item, index) => (
      <div className="opacity-80 transition-opacity hover:opacity-100" key={index}>
        {item({ size: 20, color: "white" })}
      </div>
    ));

  return (
    <footer className={`w-full bg-zinc-800 mt-auto`}>
      <div className="mx-auto max-w-7xl px-8 py-6">
        <div className="flex gap-5 text-white">
          <div>Copyright Policy</div>
          <div>Terms</div>
          <div>Privacy Policy</div>
        </div>
      </div>
      <div className="w-full bg-zinc-800 border-t border-blue-gray-50 py-4">
        <div className="flex items-center justify-between">
          <div className="mb-4 pl-6 text-center font-normal text-white md:mb-0">
            &copy; {currentYear}{" "}
            <a href="https://dalloltech.com/" className="text-blue-500 font-bold">
              Dallol Tech
            </a>
            . All Rights Reserved.
          </div>
          <div className="flex gap-4 pr-6 text-blue-gray-900 sm:justify-center">
            <Iconmap
              items={[
                BsTwitter,
                FaFacebookF,
                FaInstagram,
                FaLinkedinIn,
                FaPinterestP,
                BsYoutube,
                FaMailBulk,
              ]}
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
