import React, { useState } from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { BiBook } from "react-icons/bi";

import {  FaTrash } from "react-icons/fa";

import Tabel from "./Tabel";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";

const MyCsertificate = () => {
 
  const data2 = [
    ["John Doe", 30, "Engineer", "Male", "USA", "Single"],
    ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
    ["Jane Smith", 28, "Designer", "Female", "Canada", "Married"],
  ];

  const header2 = [
    "Name",
    "Age",
    "Occupation",
    "Gender",
    "Country",
    "Marital Status",
  ];
  const actionIcons = [
    {
      icon: <FaTrash />,
      onClick: (rowIndex) => {
        // Handle delete action for the row at the given index
        console.log("Delete row:", rowIndex);
      },
    },
  ];
  const [isMenuOpen, setMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className=" bg-[#F7F7F7] w-full min-h-[700px]">
    <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

<DashSidebar isMenuOpen={isMenuOpen} /> 
      <div
        className={`relative ml-5 mr-10  ${
          isMenuOpen ? "lg:ml-64" : "lg:ml-5"
        }`}
      >
       <div className=" flex flex-col gap-8">
       <div className=" p-4 flex gap-3 items-center text-[20px] font-[500] text-[#333333 pt-[80px]]">
          <AiOutlineAppstore size={29} />
          <h2> Instructor Dashbaord</h2>
        </div>
        <div className=" w-full h-20 ml-3  bg-white flex justify-between  items-center p-5">
          <div className=" text-2xl font-[400] text-[#333333] flex gap-2 items-center">
            <BiBook size={30} />
            Jump to Course Creation
          </div>
          <div className=" bg-red-500 px-3 py-2 text-center text-white rounded text-[14px] font-medium ">
            {" "}
            Create your Courses
          </div>
        </div>
        <div className="w-full">
       </div>
          <div className=" w-full">
            <Tabel data={data2} header={header2} actionIcons={actionIcons} />
          </div>
        </div> 
      </div>
    </div>
  );
};

export default MyCsertificate;
