import React, { useState, useContext } from "react";
import { BiHomeAlt, BiCategoryAlt } from "react-icons/bi";
import { CiStreamOn } from "react-icons/ci";
import { FiSearch, FiSettings, FiHelpCircle } from "react-icons/fi";
import { MdOutlineFeedback } from "react-icons/md";
import {
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineHeart,
  AiOutlineFlag,
} from "react-icons/ai";
import { TfiWrite } from "react-icons/tfi";
import { CgWebsite } from "react-icons/cg";
import { BsPersonCircle, BsPlusCircle, BsDot } from "react-icons/bs";
import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AppContext from "../Context/AppContext";

export default function LeftSidebar() {
  const [menu, setMenu] = useState([false, false, false]);
  const { tog } = useContext(AppContext);
  const setMenuValue = (props) => {
    let newArr = [...menu];
    newArr[props] = !newArr[props];
    setMenu(newArr);
  };
  const SidebarButton = ({ icon, label, menuIndex }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const destinationPath = label[1];
    const isActive = currentPath === destinationPath;
    return (
      <button
        onClick={() => {
          if (
            label === "Categories" ||
            label === "Tests" ||
            label === "Pages"
          ) {
            setMenuValue(menuIndex);
          }
        }}
        className={`flex items-center w-full pt-2 pb-2 space-x-4 focus:outline-none
      ${
        isActive
          ? `bg-red-100 text-red-500`
          : "transition-all duration-1000 hover:text-red-500 hover:bg-red-100 bg-white text-black"
      }`}
      >
        {icon({ size: 30, className: "pl-3" })}
        {label === "Categories" || label === "Tests" || label === "Pages" ? (
          <p className="text-base leading-4 pr-28 w-36">{label}</p>
        ) : (
          <Link to={`${label[1]}`}>
            <p className="text-base leading-4 ">{label[0]}</p>
          </Link>
        )}

        {label === "Categories" || label === "Tests" || label === "Pages" ? (
          menu[menuIndex] ? (
            <AiOutlineUp />
          ) : (
            <AiOutlineDown />
          )
        ) : (
          ""
        )}
      </button>
    );
  };
  const SidebarButton2 = ({ icon1, items, icon2 }) =>
    items.map((item, index) => (
      <button className="flex relative pr-3 justify-between items-center space-x-6 w-full pt-2 pb-2 focus:outline-none  text-black hover:text-red-700  hover:bg-red-100">
        {icon1({ size: 35, className: "pl-3" })}  
          <p className="text-base leading-4">{item}</p>
        {icon2({ color: "red"})}
      </button>
    ));

  const SidebarBottomLink = ({ items }) =>
    items.map((item, index) => (
      <Link to="/about_us">
        {" "}
        <p className="  hover:text-red-700 text-black p-1 cursor-pointer">
          {item}
        </p>
      </Link>
    ));

  const SidebarMenu = ({ items, visible }) => (
    <div
      className={`${
        visible ? "flex" : "hidden"
      } justify-start bg-red-100 flex-col w-full md:w-auto items-start pb-1 pl-10`}
    >
      {items.map((item, index) => (
        <button
          key={index}
          className="flex justify-start items-center space-x-6 text-black hover:text-red-700 hover:bg-red-100 rounded px-3 py-2 w-full md:w-52"
        >
          <Link to={`/${item[1]}`} className="font-normal leading-4">
            {item[0]}
          </Link>
        </button>
      ))}
    </div>
  );
  return (
    <div
      className={`fixed w-[240px] min-h-screen z-[9] overflow-auto bg-white transition-all duration-500 transform ${
        tog ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div
        id="Main"
        className="flex justify-start items-start font-medium h-screen overflow-auto overflow-y-hidden hover:overflow-y-visible     w-full bg-white flex-col shadow-2xl"
      >
        <div className="flex flex-col justify-start items-center w-full border-gray-300 border-b pb-4">
          <SidebarButton icon={BiHomeAlt} label={["Home", "/"]} />
          <SidebarButton
            icon={CiStreamOn}
            label={["Live Streams", "/live_streams"]}
          />
          <SidebarButton icon={FiSearch} label={["Explore", "/explore"]} />
          <SidebarButton
            icon={BiCategoryAlt}
            label="Categories"
            menuIndex={0}
          />
          <SidebarMenu
            items={[
              ["Development"],
              ["Business"],
              ["Finance & Accounting"],
              ["It & Software"],
              ["Office Productivity"],
              ["Personal Development"],
              ["Design"],
              ["Marketing"],
              ["Lifestyle"],
              ["Photography"],
              ["Health & Fitness"],
              ["Music"],
              ["Teaching & Acedemics"],
            ]}
            visible={menu[0]}
          />
          <SidebarButton icon={TfiWrite} label="Tests" menuIndex={1} />
          <SidebarMenu
            items={[
              ["Certificate Center", "certification_center"],
              ["Certificate Fill Form", "certification_start_form"],
              ["Test View", "certification_test_view"],
              ["Test Result", "certification_test_result"],
              ["My Certification", "My_Certification"],
            ]}
            visible={menu[1]}
          />
          <SidebarButton
            icon={AiOutlineHeart}
            label={["Saved Courses", "/saved_courses"]}
          />
          <SidebarButton icon={CgWebsite} label="Pages" menuIndex={2} />
          <SidebarMenu
            items={[
              ["About", "about_us"],
              ["Sign In", "signin", "forgotpassword"],
              ["Sign Up", "signUp"],
              ["Sign UP Steps", "signupsteps"],
              ["Paid Membership", "PaidMember"],
              ["Course Detail View", "courseDetail"],
              ["Checkout", "Checkout"],
              ["Invoice", "Invoice"],
              ["Career"],
              ["Job Apply"],
              ["Our Blog"],
              ["Blog Detail View"],
              ["Company Details"],
              ["Press"],
              ["Live Steam View", "live"],
              ["Add Live Stream", "Addlive"],
              ["Search Result"],
              ["Thank You"],
              ["Comming Soon"],
              ["Error 404"],
            ]}
            visible={menu[2]}
          />
        </div>
        <div className="flex flex-col justify-start items-center w-full border-gray-300 border-b pb-4">
          <p className="focus:outline-none pl-3 text-gray-400 flex justify-between items-center w-full py-3 space-x-14  text-sm leading-3  uppercase">
            Subscriptions
          </p>
          <SidebarButton2
            icon1={BsPersonCircle}
            items={["Melaku Abebe", "Leul Mesekir"]}
            icon2={BsDot}
          />
          <SidebarButton
            icon={BsPlusCircle}
            label={["Browse Instructors", "/all_instructor"]}
          />
        </div>
        <div className="flex flex-col justify-start items-center w-full border-gray-300 border-b pb-4">
          <SidebarButton icon={FiSettings} label={["Setting", "/setting"]} />
          <SidebarButton icon={FiHelpCircle} label={["Help", ""]} />
          <SidebarButton icon={AiOutlineFlag} label={["Report History", ""]} />
          <SidebarButton
            icon={MdOutlineFeedback}
            label={["Send Feedback", "feedback"]}
          />
        </div>
        <div
          className="flex flex-wrap justify-between  h-full pb-6  px-6  w-full  mt-4 
          text-sm leading-4 "
        >
          <SidebarBottomLink
            items={[
              "About",
              "Press",
              "Contact Us",
              "Adverties",
              "Developers",
              "Copyright",
              "Prlive_streamsivacy Policy",
              "Terms",
            ]}
          />
          <p className="text-sm leading-4 text-gray-300 mr-1">
            © 2023{" "}
            <a href="/" className=" hover:text-red-700 text-black ">
              Dallol Tech
            </a>
            . All Rights Reserved.
          </p>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
