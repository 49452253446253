import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { isMobileView } from "../../Model/helper";
const logo = require('../../assets/logo.png');
const profile = require('../../assets/profilepic.jpg');

export default function CertificateHeader() {
  const [isMobile, setIsMobile] = useState(isMobileView());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileView());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="sticky z-10 bg-white top-0 p-2 flex justify-around shadow-sm">
        <Link to="/">
          <div className="flex justify-center border-solid border w-20 md:w-36 h-8 text-center items-center border-slate-300 align-middle rounded-full hover:bg-stone-800 hover:text-white hover:cursor-pointer">
            {isMobile ? "Back" : "Back To Cursus"}
          </div>
        </Link>
        <div className="rounded-sm">
          <Link to='/'>
            <img className="img-responsive w-36" src={logo} alt="Logo" />
          </Link>
        </div>
        <img src={profile} className="rounded-full w-9 flex" alt="Personal Profile" />
      </div>
    </>
  );
}
