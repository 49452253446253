import image from '../assets/profilepic.jpg';
export const notifications = [
  {"name":"Abel", "profile":`${image}`,'comment':"Like Your comment On Video How to create sidebar menu.","time":2},
{"name":"Chaltu", "profile":`${image}`,'comment':"Added New Review In Video Full Stack PHP Developer.","time":24}]
export const user = {
    'name': 'Anbes Teferi',
    'image': image,
    'profidentity': 'Maths and Physics',
    'socials':{
        'facebook': '#',
        'twitter': '#',
        'linkedin': '#',
        'youtube': '#',
    },
    'enrolled': '80k',
    'course': '24',
}

export const testimonial = [
  {
    'name':'Chala',
    'image': image,
    'testimonial': "Testimonial 1"
  },
  {
    'name':'Abel',
    'image': image,
    'testimonial': "Testimonial 2"
  },
  {
    'name':'Ruhama',
    'image': image,
    'testimonial': "testimonial 3"
  },
  {
    'name':'Dawit',
    'image': image,
    'testimonial': "Testimonial 4"
  },
  {
    'name':'Seble',
    'image': image,
    'testimonial': "testimonial 5"
  },
  {
    'name':'Abel',
    'image': image,
    'testimonial': "testimonial 6"
  },
  {
    'name':'Leulseged',
    'image': image,
    'testimonial': "testimonial 7"
  }
]

export let Users =[
  {name:'Angel Abebe' ,action:"live",},
  {name:'Mesekir getch',action:"live",color:"text-primary text-1xl"},
  {name:'Leulseged',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Dallol tech',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Alemayehu',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Chala',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'John',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Amanuel',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Absalat' ,action:"off",color:"text-primary text-lg-200  text-1xl"},
  {name:'Angel Abebe' ,action:"live",},
  {name:'Mesekir getch',action:"live",color:"text-primary text-1xl"},
  {name:'Leulseged',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Dallol tech',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Alemayehu',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Chala',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'John',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Amanuel',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Absalat' ,action:"off",color:"text-primary text-lg-200  text-1xl"},
  {name:'Angel Abebe' ,action:"live",},
  {name:'Mesekir getch',action:"live",color:"text-primary text-1xl"},
  {name:'Leulseged',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Dallol tech',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Alemayehu',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Chala',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'John',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Amanuel',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Absalat' ,action:"off",color:"text-primary text-lg-200  text-1xl"},
  {name:'Angel Abebe' ,action:"live",},
  {name:'Mesekir getch',action:"live",color:"text-primary text-1xl"},
  {name:'Leulseged',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Dallol tech',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Alemayehu',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Chala',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'John',action:"offline",color:"text-danger text-lg-200  text-1xl"},
  {name:'Amanuel',action:"live",color:"text-primary text-lg-200  text-1xl"},
  {name:'Absalat' ,action:"off",color:"text-primary text-lg-200  text-1xl"}]
export const instructors = [
  {
    name: 'Anbes Teferi',
    image: image,
    profidentity: 'Maths and Physics',
    socials: {
      facebook: '#',
      twitter: '#',
      linkedin: '#',
      youtube: '#',
    },
    enrolled: '80k',
    course: '24',
  },
  {
      name: 'Leul',
      image: image,
      profidentity: 'Project Management',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
    {
      name: 'Misikr',
      image: image,
      profidentity: 'Web Dev',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
    {
      name: 'Melak',
      image: image,
      profidentity: 'Web Dev',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
    {
      name: 'Leulseged',
      image: image,
      profidentity: 'Web Dev',
      socials: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
      enrolled: '80k',
      course: '24',
    },
  ];

  export const QuestionList = [
    {
      type: 'choice',
      questionText: 'What is the name of the first page you encounter after logging into your web page?',
      answer: ['Dashboard', 'Security question page', 'WP upgrade option', 'WPAdmin'],
    },
    {
      type: 'short_answer',
      questionText: 'What is your favorite color?',
    },
    {
      type: 'choice',
      questionText: 'How can you get involved with WordPress?',
      answer: ['Attend Word Camp', 'Edit the Codex(documentation','Help in the Forums','All of these'],
    },
    {
      type: 'choice',
      questionText: 'What ways to use WordPress?',
      answer: ['Arcade','Bolg', 'Content Mangagement System(CMS)','All of the above'],
    },
    {
      type: 'short_answer',
      questionText: 'Describe Your self in three words?',
    },
  ];

  export const Courses =[
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
]

export const MessageData = [
  {
    name: 'Alemayehu',
    profile:`${image}`,
    messages: [
      ['received', 'Hi'],
      ['sent', 'Hello Alemayehu'],
      ['received', 'I wanted to let you know I will be absent today'],
      ['sent', 'It is alright'],
      ['sent', 'What was wrong though'],
      ['received', 'I am not feeling good, i will be available next session'],
      ['sent', 'Get Well soon']
    ],
    time: 5
  },
  {
    name: 'Melat',
    profile:`${image}`,
    messages: [
      ['received', 'Hello sir, I paid for the video tutorial but I can\'t seem to find it']
    ],
    time: 2
  },
  {
    name: 'Melat',
    profile:`${image}`,
    messages: [
      ['received', 'Hello sir, I paid for the video tutorial but I can\'t seem to find it']
    ],
    time: 2
  },
  {
    name: 'Melat',
    profile:`${image}`,
    messages: [
      ['received', 'Hello sir, I paid for the video tutorial but I can\'t seem to find it']
    ],
    time: 2
  },
  {name: 'Zeyneb',
  profile:`${image}`,
  messages: [
    ['received', 'Hello, I was wondoring when i could expect my results'],
    ['sent', 'Hello, Which cohort are you'],
    ['received', 'I\'m cohort 3'],
    ['received', 'And i\'m talking about the quiz sir'],
    ['sent', 'It is ready you will be notified when i upload it'],
    ['received', 'Ok, Thank you'],
  ],
  time: 5
},
];