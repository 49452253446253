import React from 'react'
import '../App.css'
import Avatar from '../assets/couse.png';
import Slider from '../Model/Slider';
import CoursesCard from './CoursesCard';

const NewestCourseCard = () => {
    let Courses =[
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
          {courseOwner:'John Doe',name:'Complete Python BotCamp : Gor frin zero to hero in Python 3',action:"109k views  .  15 days ago",development:"web development| python",price:"$5"},
    ]
  return (
   <>
   <div className=' px-6 flex justify-between' >
          <p className='text-1xl'>Newest  Courses</p>
          <p className='text-stone-300 text-base hover:text-stone-600 hover:cursor-pointer'>See all</p>
        </div>
    <Slider id="newCourseSlider" classProp={["translate-x-6", "-translate-x-5"]}>
    <div id="newCourseSlider" className='w-full h-full flex scroll whitespace-nowrap scroll-smooth scrollbar-hide'>

     {
          Courses.map((course, index)=>(
            <CoursesCard key={index} course={course} customClassName="w-[300px] md:w-[345px]" />
          
          ))
        }

    </div>
    </Slider>
   </>
  )
}



export default NewestCourseCard
