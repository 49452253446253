import React from "react";

const Button = (props) => {
  return (
    <button
      className=" text-white bg-danger rounded w-full h-full hover:bg-black 
    duration-500"
    >
      {props.children}
      <div className=""></div>
    </button>
  );
};

export default Button;
