import React from "react";
import NavSideBarComb from "../../components/navSidebarComb";
import UserCard from "../../components/UserCard";
import { FiEye } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
const LiveStreamView = ({ tog }) => {
  return (
    <>
      <NavSideBarComb>
        <div
          className={`transition-all duration-500 transform flex flex-col flex-wrap ${
            tog ? "w-11/12" : "w-11/12 ml-3"
          } mt-12 pt-16 ml-3`}
        >
          <div className="w-full flex ">
            <div className=" w-2/3 mr-10 mb-5">
              <iframe
                src="https://www.youtube.com/embed/EEIk7gwjgIM?autoplay=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
                className=" w-full h-96 rounded-3"
              ></iframe>
              <div className=" w-full mt-10 flex justify-between">
                <div className="flex w-full">
                  <div className=" w-14 h-14 inline-block rounded-full mr-5 border-2 border-white shadow-md">
                    <img
                      className=" w-full"
                      src="https://lms.dalloltech.com/images/left-imgs/img-4.jpg"
                      alt="avator"
                    />
                  </div>
                  <div>
                    <h4 className=" text-xl font-medium font-roboto text-gray-700 mt-0 mb-10px">
                      Jessica william
                    </h4>
                    <button className="border-0 bg-red-600 px-5 h-7 mt-2  text-white font-medium font-roboto text-sm">
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="flex ">
                  <div className=" w-16 mr-2">
                    <a
                      href="#"
                      className="block bg-white h-16 text-center py-2 px-4 font-medium font-roboto text-gray-600 border border-gray-200 rounded-md"
                    >
                      <div className=" w-full flex justify-center">
                        <FiEye />
                      </div>
                      <span>1452</span>
                    </a>
                  </div>
                  <div className=" w-16 mr-2">
                    <a
                      href="#"
                      className="block bg-white h-16 text-center py-2 px-4 font-medium font-roboto text-gray-600 border border-gray-200 rounded-md"
                    >
                      <div className=" w-full flex justify-center">
                        <AiFillHeart />
                      </div>
                      <span>100</span>
                    </a>
                  </div>
                  <div className=" w-16 mr-2">
                    <a
                      href="#"
                      className="block bg-white h-16 text-center py-2 px-4 font-medium font-roboto text-gray-600 border border-gray-200 rounded-md"
                    >
                      <div className=" w-full flex justify-center">
                        <AiOutlineDislike />
                      </div>
                      <span>20</span>
                    </a>
                  </div>
                  <div className=" w-16 mr-2">
                    <a
                      href="#"
                      className="block bg-white h-16 text-center py-2 px-4 font-medium font-roboto text-gray-600 border border-gray-200 rounded-md"
                    >
                      <div className=" w-full flex justify-center">
                        <AiOutlineDislike />
                      </div>
                      <span>9</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white w-1/3 float-left p-0 rounded-md mb-30 border border-gray-300 transition duration-200 ease-in-out ">
              <div className=" w-full px-4 py-5 border-b border-gray-300">
                <h4 className="font-medium mr-2 text-gray-700">Live Chat</h4>
              </div>
              <div className=" w-full h-96 overflow-y-auto px-4 py-5">
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    John Doe
                  </a>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  aliquet molestie leo ac pellentesque
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Poonam
                  </a>
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Jass
                  </a>
                  Nulla sollicitudin nec nisi at pellentesque. Cras fringilla
                  est et sem tempor
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Albert smith
                  </a>
                  Pellentesque ultricies risus sit amet congue euismod
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Jassica william
                  </a>
                  Nullam efficitur tristique consequat
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Jassica william
                  </a>
                  Nullam efficitur tristique consequat
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Zoena Singh
                  </a>
                  Aliquam dignissim elementum sem id rutrum
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Singh
                  </a>
                  Fusce a elit at libero sollicitudin tincidunt
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Johnson
                  </a>
                  Ut laoreet lobortis ornare
                </p>
                <p className="font-roboto leading-24px text-14px text-gray-600 mb-3 text-left">
                  <a className="font-medium mr-2 text-gray-700" href="#">
                    Jashan
                  </a>
                  Sed pretium erat eu turpis condimentum
                </p>
              </div>
              <div className="p-0  w-full relative  border-t border-gray-300">
                <input
                  className="w-full h-12 py-3 px-4 md:px-16 border-0 text-gray-700  rounded-br-3 font-roboto"
                  placeholder="Say something"
                />
                <div className="absolute top-2 right-3 w-10 h-10 rounded-md border-0 bg-red-600 text-white text-xl flex items-center justify-center">
                  <div>
                    <button className=" w-full h-full">hy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-8 max-h-px ">
            <UserCard />
          </div>
        </div>
      </NavSideBarComb>
    </>
  );
};

export default LiveStreamView;
