import { FaFileUpload } from "react-icons/fa"
import { BiEdit } from "react-icons/bi";

export default function Publish() {
    return(
        <>
            <hr />
            <div className="flex gap-3 items-center h-20">
                <FaFileUpload />
                <h3>Submit</h3>
            </div>
            <hr />
            <div className="flex flex-col gap-6 justify-center items-center text-center bg-white py-8 mt-8">
                <BiEdit />
                <p>Your course is in a draft state. Students cannot view, purchase or enroll in this course. For students that are already enrolled, this course will not appear on their student Dashboard.</p>
            </div>
        </>
    )
}