export const ContainerShowHover = ({icons, text, show, showHandle, hideHandle}) => {
    return(
        <div className="flex gap-3 pl-3 h-14 items-center cursor-move bg-slate-100" onMouseEnter={showHandle} onMouseLeave={hideHandle}>
                            {icons[0]}
                           <div className="flex w-full justify-between">
                          <div className="flex gap-3">
                            <span>{text}</span>
                            <div className={`pt-1 gap-2 ${show ? 'flex' : 'hidden'}`}>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  {icons[1]}
                              </div>
                              <div className="cursor-pointer text-purple-400 hover:text-purple-800 ">
                                  {icons[2]}
                              </div>
                            </div>
                          </div>
                          <div className={`pr-4 text-purple-400 hover:text-purple-800 cursor-pointer ${show ? 'flex' : 'hidden'}`}>
                            {icons[3]}
                          </div>
                      </div>
                    </div>
    )
}