import React, { useState, useCallback } from "react";
import { TextInput } from "./inputfield";
import Select from "react-select";
import Button from "../Button";

// import { countries } from "countries-list";
import { RadioWithParentBorder } from "./inputfield";
export default function BillingPayout() {
  const [method, setMethod] = useState(null);
  /* const countryNames = Object.values(countries.countries).map((country) => ({
        label: country.name, // Use the country name as the value
        value: country.name, // Use the country name as the label
      })); */
  const countryNames = [
    { valeu: "ETH", label: "Ethiopia" },
    { value: "US", label: "United States" },
    { value: "CA", label: "Canada" },
    { value: "UK", label: "United Kingdom" },
    { value: "DE", label: "Germany" },
  ];

  const BillingRelation = ({ items }) => (
    <div className="flex flex-col items-center gap-1">
      <span>{items[0]}</span>
      <div className="bg-stone-400 w-0.5 h-3"></div>
      <span>{items[1]}</span>
    </div>
  );
  const exclusiveSale = [
    ["$0", "50%"],
    ["$2,000", "53%"],
    ["$8,000", "55%"],
    ["$18,000", "58%"],
    ["$40,000", "62%"],
    ["$75,000", "70%"],
    ["$100,000", "80%"],
  ];
  const nonExclusiveSale = [
    ["$0", "30%"],
    ["$2,000", "30%"],
    ["$8,000", "30%"],
    ["$18,000", "30%"],
    ["$40,000", "30%"],
    ["$75,000", "30%"],
    ["$100,000", "30%"],
  ];

  const handleRadio = useCallback((index) => {
    setMethod(index);
  }, []);

  const PayOutMethod = ({theMethod}) => {
    return(
      <div className="flex w-full text-sm flex-col h-10 lg:flex-row gap-3">
        <TextInput name={theMethod} placeholder={`${theMethod} Account Number`} />
        <TextInput name={`${theMethod}_name`} placeholder={`${theMethod} Account Holder's Name`} />
      </div>
    )
  }
  return (
    <>
      <div>
        <h4 className="text-xl font-medium">Billing and Payouts</h4>
        <p className="text-sm text-stone-500">
          Want to charge for a course? Provide your payment info and opt in for
          our promotional programs
        </p>
        <h4 className="font-medium">Billing Address</h4>
        <div className="flex flex-col gap-6 lg:w-2/3 bg-stone-100">
          <div className="flex flex-col lg:flex-row gap-6 lg:gap-2">
            <TextInput
              name={"firstName"}
              placeholder={""}
              value={`${"first.name"}`}
            />
            <TextInput
              name={"lastName"}
              placeholder={""}
              value={`${"last.name"}`}
            />
          </div>
          <div>
            <TextInput name="" placeholder={""} value={`${"Gambolthemes"}`} />
            <p className="text-sm text-stone-500">
              If you want your invoices addressed to a academy. Leave blank to
              use your full name.
            </p>
          </div>
          <div className="flex flex-col w-full">
            <Select
              options={countryNames}
              className="w-full h-10 cursor-pointer"
              classNamePrefix="react-select"
              placeholder="Select Country"
            />
          </div>
          <TextInput
            name={"address"}
            placeholder={""}
            value={`${"#1234, Sks Nagar, Near MBD Mall, 141001 Ludhiana, Punjab, India"}`}
          />
          <TextInput
            name={"address2"}
            placeholder={"Address Line 2"}
            value={""}
          />
          <TextInput name={""} placeholder={""} value={"Lundhiana"} />
          <TextInput name={""} placeholder={""} value={"Punjab"} />
          <TextInput name={""} placeholder={""} value={"141001"} />
          <TextInput name={"phone"} placeholder={""} value={"+2519477674"} />
        </div>
        <div className="flex flex-col gap-6 pt-10">
          <hr />
          <h4 className="font-medium">Exclusive Sales</h4>
          <p className="text-stone-500">
            Sell more of your exclusive products of this type (equal to the
            amount on the left) to get % cut from further exclusive sales.
          </p>
          <div className="flex text-sm lg:w-7/12 justify-around">
            {exclusiveSale.map((val, index) => (
              <div className={`${index === 0 && "text-green-400"}`} key={index}>
                <BillingRelation items={val} />
              </div>
            ))}
          </div>
          <h4 className="font-medium">Non-Exclusive Sales</h4>
          <p className="text-stone-500">
            Sell more of your non-exclusive products of this type (equal to the
            amount on the left) to get 70% cut from every non-exclusive sales.
          </p>
          <div className="flex text-sm lg:w-7/12 justify-around">
            {nonExclusiveSale.map((val, index) => (
              <div key={index} className={`${index === 0 && "text-green-400"}`}>
                <BillingRelation items={val} />
              </div>
            ))}
          </div>
          <hr />
        </div>
        <div className="flex flex-col gap-4 py-10">
          <h4 className="font-medium">Withdrawal Method</h4>
          <div className="flex justify-between gap-4 px-3">
            <RadioWithParentBorder
              change={() => handleRadio(0)}
              index={0}
              state={method}
              label={"CBE"}
            />
            <RadioWithParentBorder
              change={() => handleRadio(1)}
              index={1}
              state={method}
              label={"Dashen"}
            />
            <RadioWithParentBorder
              change={() => handleRadio(2)}
              index={2}
              state={method}
              label={"TeleBirr"}
            />
          </div>
          {method === 0 && 
          <div className="flex flex-col gap-3">
            <PayOutMethod theMethod="CBE" />
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
            <Button>Set PayOut Account</Button>
          </div>
          </div>}
          {method === 1 &&
          <div className="flex flex-col gap-3">
            <PayOutMethod theMethod="Dashen" />  
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
            <Button>Set PayOut Account</Button>
          </div>
          </div>}
          {method === 2 &&
          <div className="flex flex-col gap-3">
            <PayOutMethod theMethod="TeleBirr" />
            <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
            <Button>Set PayOut Account</Button>
          </div>
          </div>}
          
          <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
            <Button>Save Changes</Button>
          </div>
        </div>
      </div>
    </>
  );
}
