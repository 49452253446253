import React from "react";
import CourseFooter from "./courseFooter";
import { useState } from "react";
import DashNavbar from "../Dashbord/DashNavbar";
import DashSidebar from "../Dashbord/DashSidebar";
export default function CommonFormat({children}) {
    const [isMenuOpen, setMenuOpen] = useState(true);

    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
    return(
        <div className="bg-stone-100 flex flex-col w-full">
            <DashNavbar isMenuOpen={isMenuOpen} onMenuToggle={handleMenuToggle} />

            <DashSidebar isMenuOpen={isMenuOpen} />
                <div className={`relative mx-2 lg:mx-0 lg:ml-5 pt-24 z-[7] lg:mr-10  ${
        isMenuOpen ? "lg:ml-64" : "lg:ml-5"
      }`}>
        {children}
      </div>
          <div className={`${isMenuOpen ? "lg:ml-60" : "lg:ml-0"}`}>
            <CourseFooter />
          </div>
      
        </div>
    )
}