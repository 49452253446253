import React from "react";
import { MdOutlineNotifications } from "react-icons/md";
import {IndividualNotificationMessage} from "../../Model/IndividualNotMess";
import CommonFormat from "./commonFormat";
const profile = require("../../assets/avatar.png")

export default function Notfications() {
    const notifications = [{"name":"Abel", "profile":`${profile}`,'comment':"Like Your comment On Video How to create sidebar menu.","time":2},
    {"name":"Chaltu", "profile":`${profile}`,'comment':"Added New Review In Video Full Stack PHP Developer.","time":24}]
    return (
        <>
            <CommonFormat>
                <div className="flex gap-2">
                    <MdOutlineNotifications size={24} />
                    <h2 className="text-xl">Notifications</h2>
                </div>
                <button
                className={`transition-all
                            duration-400 font-semibold
                            text-stone-100 text-center transition-500 bg-redbutton
                            rounded-sm w-44 h-9 hover:cursor-pointer hover:bg-slate-700`}>Notification Setting</button>
                    <div className="pt-3 pr-3">
                        {notifications.map((notification, index) =>
                        <div className="flex items-center h-34 gap-2 bg-white border pt-2 pb-2 pl-3">
                            <IndividualNotificationMessage items={notification} />
                        </div>
                        )}
                    </div>
            </CommonFormat>
        </>
    )
}