import React from "react";
import avator from "../../assets/avatar.png";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
const RepeatedHeader = () => {
  return (
    <div>
      <header className="fixed z-50 w-full h-16 bg-white flex items-center shadow">
        <div className="w-full px-4 mx-auto">
          <div className=" flex items-center justify-between  p-9">
            <div>
              <Link
                to="/"
                className=" hover:bg-black hover:text-white inline-block text-gray-700 float-left h-10 bg-white border border-gray-300 rounded-full px-4 py-2 text-sm font-medium font-sans "
              >
                Back to cursus
              </Link>
            </div>
            <div>
              <img src={logo} alt="logo" className=" w-36" />
            </div>
            <div>
              <img src={avator} alt="avator" className=" w-12" />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default RepeatedHeader;
