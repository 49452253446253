const TextArea = ({label, name, placeholder}) => (
    <div className="flex flex-col w-full">
        <label className="pt-4 font-medium">{label}</label>
        <div className="w-full">
            <textarea name={`${name}`} id={`${name}`} placeholder={`${placeholder}`}
            rows={5}
            className="flex pl-6 pt-2 w-full border border-solid focus:outline-none text-sm" />
        </div>
    </div>
  )
  export {TextArea}