import CirtificateHeader from './cirtificateHeader'
import Footer from '../Footer'
import { Link } from 'react-router-dom'
import { BiChevronsLeft } from 'react-icons/bi'
import { TextInput } from '../Settings/inputfield'
import { useState, useCallback } from 'react'
const Category = ({ state, lists, category})=>{
    const [ dropRadio, setDropRadio ] = useState(null);

    const changeRadio = useCallback((index) => {
        setDropRadio(index)
    },[])
    return(
    lists.map((list, index) => (
        <div
        onClick={() => changeRadio(index)}
        className='flex gap-2 items-center'>
            <div className={`flex justify-center items-center w-4 h-4 border rounded-full ${index === dropRadio ? "border-red-500":"border-slate-500"}`}>
                <div className={`${index === dropRadio && "bg-red-500"} w-2 h-2 rounded-full`}></div>
            </div>
            <label>{list}</label>
        </div>
        ))
    )
}
export default function CertificateForm() {
    let CategoryList = {
        "Development": ["WordPress", "HTML CSS", "Full Time"],
        "Finanace & Accounting":["Accouting", "Finance Fundamentals"],
        "Design": ["Adobe Photoshop", "Adobe Illustrator", "Adobe After Effects", "Adobe InDesign"],
        "Marketing": ["Google Ads (Adwords)", "Google Ads (AdWords) Certification", "Social Marketing"],
        "Teaching & Academics": ["Math", "Humanities", "Engineering", "Science"]
};
const [ dropDown, setDropDown ] = useState(null)
    const handleCategoryClick = (cat) => {
        setDropDown(cat === dropDown ? null : cat)
    }
    console.log(dropDown);
CategoryList = Object.entries(CategoryList);
    return(
        <>
        <CirtificateHeader/>
        <div className='px-4 sm:px-10 py-4'>
            <div className="flex flex-col sm:flex-row gap-4 sm:gap-1 justify-between">
                <div className="flex flex-wrap gap-1">
                    <Link to='/'><p>Home</p></Link>
                    <Link to='/certification_center'><p>/ Certificate Center</p></Link>
                    <p className='text-stone-500'>/ Certification Fill Form</p>
                </div>
                <Link to="/certification_center">
                    <div className='flex items-center'><BiChevronsLeft />Back to Certificate Center</div>
                </Link>
            </div>
                <h2 className='text-2xl font-medium'>Certification Fill Form</h2>
        </div>
        <div className='flex flex-col justify-center items-center text-center bg-stone-100 py-6'>
            <div className='py-10 px-6 flex flex-col gap-3 lg:w-2/6 bg-white'>
                <h2>Fill in before you start:</h2>
                <TextInput
                name={'firstName'}
                placeholder={"Full Name"} notwhite={true}
                value={""} />
                <TextInput name={"email"} placeholder={"Email Address"} notwhite={true} value={""} />
                <TextInput name={"phone"} placeholder={"Phone Number"} notwhite={true} value={""} />
                <div className="flex w-full flex-col self-start gap-4">
                    <h4 className='flex text-xl font-medium'>Select Category</h4>
                    <hr />
                    <div className='flex flex-col gap-4'>
                                {CategoryList.map(([category, values], index) => (
                                    <>
                                    <div
                                    key={category}
                                    onClick={() => handleCategoryClick(category)}
                                    className='flex justify-between font-medium hover:cursor-pointer'>
                                        <p>{category}</p>
                                        <p className='font-medium text-lg'>{category === dropDown ? "-":"+"}</p>
                                    </div>
                                    {category === dropDown && (
                                    <div
                                    className='text-sm'>
                                         <Category state={dropDown} lists={values} />
                                    </div>)}
                                    <hr />
                                    </>
                                ))}
                        <p className='text-sm text-stone-600'>By signing up, you agree to our <Link className="text-red-500" to='/certification_start_form'>Privacy Policy</Link> and <Link className='text-red-500' to="/certification_start_form">Terms and Conditions</Link>.</p>
                        <button className="text-white bg-redbutton hover:bg-stone-700 hover:text-white py-1">Lets Go</button>
                        <p className='text-sm text-stone-600'>Please be ready to answer <span className='text-red-500'>20 questions</span> within <span className='text-red-500'>1 hours</span>.</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}