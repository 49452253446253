import React, { useState, useEffect } from 'react'
import Button from './Button';
import  '../App.css';
import Logo from '../assets/logo.png'
import { Link } from "react-router-dom";
import NavbarLinks from '../Model/navbarLinks';
import {BiMenu, BiSearch} from 'react-icons/bi';
import { isLargeScreen } from '../Model/helper';

const CategoriesData = ["Development", "Business", "Finance & Accounting", "It & Software", "Office Productivity", 
"Personal Development", "Design", "Marketing", "Lifestyle", "Photography", 'Health & Fitness', 'Music', 'Teaching & Academics']
const Navbar = ({handleToggle}) => {
    let [tog, setTog] = useState(false);
    const [isLarge, setIsLarge] = useState(false);
    const [ isHoverCategory, setIsHoverCategory] = useState(false);

    const handleIconToTrue = () => {
        setIsHoverCategory(true);
    }
    const handleIconToFalse = () => {
        setIsHoverCategory(false);
    }

    const toggle = () => {
      setTog(!tog);
      handleToggle(tog);
    };
    useEffect(() => {
        const handleResize = () => {
          setIsLarge(isLargeScreen());
        };
    
        handleResize(); // Call it initially to set the correct value
        window.addEventListener("resize", handleResize);
    
        // Clean up the event listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
      }, [isLarge]);
    return(
        <>
        <div className="flex h-[60px] justify-between bg-white w-full shadow-md fixed top-0 left-0 z-10">
            <div className='flex md:gap-4 h-full items-center'>
                <button onClick={toggle} className='h-full flex items-center justify-center lg:bg-red-500 lg:hover:bg-red-600 md:w-[60px]'>
                    <BiMenu size={30} color={`${isLarge ? "white" : "black"}`}/>
                </button>
                <Link to='/'><img src={Logo} alt="logo" className="w-[80px] lg:w-[130px] lg:h-10"/></Link>
                <span
                onMouseEnter={handleIconToTrue}
                onMouseLeave={handleIconToFalse}
                className='hidden h-full px-3 md:flex items-center md:text-2xl text-stone-500 hover:text-stone-800 cursor-pointer'
                >
                <ion-icon name="grid-outline"></ion-icon>
                
                </span>
                <div className="hidden xl:flex px-3 w-[360px] h-full items-center bg-white">
        <div className="flex relative items-center px-3 rounded-sm gap-2 bg-stone-100 text-gray-500 selection:text-gray-800 text-sm font-light font-sans shadow-sm w-full h-8">
            <BiSearch />
            <input
                type="text"
                className="w-full h-full hover:border-white outline-none bg-stone-100"
                placeholder="Search for Tuts Videos, Tutors, Tests and more..."
            />
        </div>
    </div>
            </div>
            
            <div className='flex items-center gap-1'>
                        <Link to={'/create_new_course'} className='hidden sm:flex'>
                          <div className='w-40 h-9 text-sm font-medium bg-red-600 rounded-md'>
                              <Button>
                              Create New Couser
                              </Button>
                          </div>
                        </Link>
                <ul className={``}>
                    <NavbarLinks />
                </ul>
            </div>
        </div>
        <div
                onMouseEnter={handleIconToTrue}
                onMouseLeave={handleIconToFalse}
                className={`fixed text-sm cursor-pointer translate-y-14   translate-x-56 border border-stone-100  bg-white z-40 ${isHoverCategory ? 'block':"hidden"}`}>
                    {CategoriesData.map((category, index) =>(
                    <p
                    key={index}
                    className='py-2 px-4 w-full hover:bg-stone-200'>
                        {category}
                        </p>
                    ))}
                    
                </div>
        </>
    )
}

export default Navbar;